import { Component, Input, EventEmitter, Output, OnInit, OnDestroy } from '@angular/core';
import { HereApiResult } from 'src/app/interfaces/responses/here_api_results';
import { GeoService } from 'src/app/services/geo.service';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { IFSedeBlock } from 'src/app/interfaces/if-sede-block';
import { IFBaseClass } from 'src/app/base-classes/if-base-class';
import { ClientsService } from 'src/app/services/clients.service';
import { FlashService } from 'src/app/services/notifications.service';
import { IFProfile, IFCedentePrestatore } from 'src/app/interfaces/if_profile';
import { Sede } from 'src/app/interfaces/fatturazione_elettronica/fattura_semplificata';
import { forbiddenChar } from 'src/app/validators/forbidden-char';
import { LookupModels } from 'src/app/interfaces/lookup-models';
import { IFNation } from 'src/app/interfaces/if_nation';

@Component({
  selector: 'app-if-sede-block',
  templateUrl: './if-sede-block.component.html',
  styleUrls: ['./if-sede-block.component.scss']
})
export class IFSedeBlockComponent extends IFBaseClass implements OnInit, OnDestroy {

  sedeForm: FormGroup;
  stabileForm: FormGroup;

  sede: Sede;

  NUMBERS_ONLY = new RegExp(/^[0-9]+$/);

  nations: IFNation[];

  constructor(
    public geo: GeoService,
    cs: ClientsService,
    fs: FlashService,
    private fb: FormBuilder
  ) {
    super(cs, fs, null);

    const baseObj = {
      CAP: [null, [Validators.required, Validators.maxLength(5), Validators.minLength(5), forbiddenChar(this.NUMBERS_ONLY)]],
      Indirizzo: [null, Validators.required],
      NumeroCivico: [null],
      Comune: [null, Validators.required],
      Provincia: [null, Validators.required],
      Nazione: [null, Validators.required]
    };

    this.sedeForm = this.fb.group(baseObj);
    this.stabileForm = this.fb.group(baseObj);
  }

  ngOnInit(): void {

    this.cs.getLookupResource(LookupModels.Nazioni)
    .subscribe((nations) => {
      console.log('nations', nations);
      this.nations = nations.value;
    });

    this.meSub = this.cs.meObservable$
    .subscribe((profile: IFProfile) => {
      this.loading = false;
      this.error = false;
      this.me = profile;
      this.patchSede(this.me.CedentePrestatore);
    });
    this.cs.loadAndSetMe();

    this.sedeForm.get('CAP')
    .valueChanges
    .subscribe((newCap) => {
      if (this.sedeForm.get('CAP').valid) {
        this.automaticallyPopulateGeoFields(newCap, this.sedeForm);
      }
    });

    this.stabileForm.get('CAP')
    .valueChanges
    .subscribe((newCap) => {
      if (this.stabileForm.get('CAP').valid) {
        this.automaticallyPopulateGeoFields(newCap, this.stabileForm);
      }
    });

  }

  ngOnDestroy(): void {
    this.meSub.unsubscribe();
  }

  /**
   * Questo metodo popola automaticamente i campi deducibili dal CAP.
   * @param {string} newCap
   */
  automaticallyPopulateGeoFields(newCap: string, formGroup: FormGroup): void {
      const geocoder = this.geo.buildGeoCoder();
      const nationValue = formGroup.get('Nazione').value;
      geocoder.search(
        {searchText: `${nationValue} ${newCap}`},
        (data: HereApiResult) => {
          const city: string = data.Response.View[0].Result[0].Location.Address.City;
          const province = data.Response.View[0].Result[0].Location.Address.County;
          const nation = data.Response.View[0].Result[0].Location.Address.Country;
          const twoDigitsNations = `${nation[0]}${nation[1]}`;
          console.log('HereAPI ', data);
          formGroup.patchValue({
            Comune: city,
            Provincia: province,
            Nazione: twoDigitsNations
          });
          this.sedeForm.updateValueAndValidity();
        },
        (ko) => {console.log('ko', ko); }
        );
  }

  patchSede(previousSede: IFCedentePrestatore): void {
    this.sedeForm.patchValue(previousSede.Sede);
    this.stabileForm.patchValue(previousSede.StabileOrganizzazione);
  }

  patchStabileAndSave(): void {
    this.loading = true;
    Object.assign(
      this.me.CedentePrestatore.StabileOrganizzazione,
      this.stabileForm.value
    );
    this.saveProfile(this.me);
  }

  patchSedeAndSave(): void {
    this.loading = true;
    Object.assign(
      this.me.CedentePrestatore.Sede,
      this.sedeForm.value
    );
    this.saveProfile(this.me);
  }

}
