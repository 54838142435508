export enum IFFlashType {
  check_circle_outline = 'check_circle_outline',
  info = 'info',
  warning = 'warning',
  error = 'error'
}
export interface IFFlash {
  text?: string;
  type?: IFFlashType;
  id?: string;
  time?: number | null;
  goingOut?: boolean;
}
