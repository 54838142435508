import { PipeTransform, Pipe } from '@angular/core';

/**
 * Questo pipe
 */
@Pipe({
  name: 'errorMsgs'
})
export class ErrorMsgsPipe implements PipeTransform {
  transform(
    errorCode: 'unhandledInvoice'
  ): string {
    switch (errorCode) {
      case 'unhandledInvoice':
        return 'Tipo di fattura non gestita';
        break;
    }
  }
}
