<div
  [ngStyle]="{
    width: restricted ? '2.5rem' : '14rem'
  }"
  [ngClass]="{
    restricted: restricted
  }"
  class="sidebar"
>
  <ul>
    <li [ngClass]="{ active: router.url === '/dashboard' }" class="active">
      <a  [routerLink]="'./'">
        <i class="ico-panoramicasmart"></i>
        <span
        @entering
        *ngIf="showLetters"> Panoramica </span>
      </a>
    </li>
    <!-- <li [ngClass]="{ active: currentUrl === paths.search }">
      <a [routerLink]="'./' + keywords.search">
        <i class="icocerca"></i>
        Cerca</a
      >
    </li> -->
    <li [ngClass]="{ active: currentUrl === paths.clients }">
      <a
        [routerLinkActiveOptions]="{ exact: false }"
        [routerLinkActive]="'active'"
        [routerLink]="'./' + keywords.clients"
      >
        <i class="icoclienti"></i>
        <span
        @entering
        *ngIf="showLetters">Clienti</span>
      </a>
    </li>
    <li [ngClass]="{ active: currentUrl === paths.credits }">
      <a [routerLinkActive]="'active'" [routerLink]="'./' + keywords.credits">
        <i class="ico-creditismart"></i>
        <span
        @entering
        *ngIf="showLetters">Crediti</span></a
      >
    </li>

    <li [ngClass]="{ active: currentUrl === paths.notifications }">
      <a [routerLinkActive]="'active'" [routerLink]="'./notifiche'">
        <i class="ico-notificasmart"></i>
        <span
        @entering
        *ngIf="showLetters">Notifiche</span>
        </a
      >
    </li>
    <!--
    <li><a href="#">
      <i class="icostatistiche"></i>
      Statisiche</a></li>
      -->
    <!-- <li [ngClass]="{ active: currentUrl === paths.marketplace }">
      <a [routerLink]="'./marketplace'">
        <i class="ico checkout"></i>
        Marketplace</a
      >
    </li> -->

    <li [ngClass]="{ active: currentUrl === paths.profile }">
      <a [routerLinkActive]="'active'" routerLink="profilo/anagrafiche">
        <i class="ico-profilosmart"></i>
        <span
        @entering
        *ngIf="showLetters">Profilo</span>
        </a
      >
    </li>

    <li [ngClass]="{ active: currentUrl === paths.contracts }">
      <a [routerLinkActive]="'active'" [routerLink]="'./contratti'">
        <i class="ico-docsmart"></i>
        <span
        @entering
        *ngIf="showLetters">Modulistica</span>
        </a
      >
    </li>
    <!--
    <li><a [routerLink]="'./scadenze'">
        <i class="icocalendario"></i>
        Scadenze</a></li>
        -->

    <li (click)="logout()">
      <div>
        <i class="ico-escismart"></i>
        <span
        @entering
        *ngIf="showLetters">
          Esci
        </span>
      </div>
    </li>
  </ul>
</div>
