<div class="edit">
  <div class="text">
    <div class="text-wrapper">
      <ng-container [ngSwitch]="type">
        <ng-container *ngSwitchCase="'text'">
          {{label}}
        </ng-container>
        <ng-container *ngSwitchCase="'checkbox'">
          {{label}}
        </ng-container>
        <ng-container *ngSwitchCase="'icon'">
          <i [class]="label"></i>
        </ng-container>
      </ng-container>
    </div>
    <div
    class="input-wrapper">
    <div  class="v" (click)="emit()">
      <ng-container *ngIf="isBool">
        <mat-icon>{{value ? 'check' : 'close'}}</mat-icon>
      </ng-container>
      <ng-container *ngIf="!isBool">
        {{value}}
      </ng-container>
    </div>
      <div *ngIf="editing" class="delete-wrapper">
        <div class="delete-btn" (click)="delete(value)">
          <i class="ico-cestinasmart"></i>
        </div>
      </div>
    </div>
  </div>
</div>
