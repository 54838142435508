/**
 * @license
 * Copyright SMART SRL ®. Tutti i diritti riservati
 *
 * @author
 * Leonardo Maria Miliacca
 *
 * L'uso di questo codice è soggetto a copyright
 */
import { Component, OnInit } from '@angular/core';
import { ClientsService } from './services/clients.service';
import { LoginResponse } from './interfaces/responses';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  IFAuthToken: string;
  tokenExpires: Date;

  constructor(
    private clientsService: ClientsService
  ) {
    if (localStorage.getItem('expires')) {
      this.clientsService.expires = new Date(localStorage.getItem('expires'));
      this.clientsService.token = localStorage.getItem('token');
    }
  }

  ngOnInit(): void {
    this.clientsService.newIFAuth$
    .subscribe((newToken: LoginResponse) => {
      // effettuo una sottoscrizione per recuperare il token e salvarlo
      this.IFAuthToken = newToken.IFAuth;
      this.tokenExpires = new Date(Date.now() + newToken.ttl);
    });
  }
}
