/**
 * @license
 * Copyright SMART SRL ®. Tutti i diritti riservati
 *
 * @author
 * Leonardo Maria Miliacca
 *
 * L'uso di questo codice è soggetto a copyright
 */

import { NgModule } from '@angular/core';
import { IFSelectComponent } from './if-select/if-select.component';
import { IFCheckboxComponent } from './if-checkbox/if-checkbox.component';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    IFSelectComponent,
    IFCheckboxComponent
  ],
  exports: [
    IFSelectComponent,
    IFCheckboxComponent
  ],
  providers: [
  ],
})
export class IFComponentsModule { }
