import { StatoPagamento } from './stato_pagamento';

export enum IFColumnType {
  status = 'status',
  text = 'text',
  number = 'number',
  currency = 'currency',
  icon = 'icon',
  button = 'button',
  rating = 'rating',
  date = 'date',
  boolean = 'boolean',
  activeBoolean = 'activeBoolean',
  select = 'select',
  contacts = 'contacts',
  pipe = 'pipe'
}

export interface IFTableColumn {
  type: IFColumnType;
  name: string;
  label: string;
  staysOnClick?: boolean;
  sortable?: boolean;
  selected?: boolean;
  width?: number;
  options?: StatoPagamento[];
  linked?: boolean;
  filterable?: boolean;
  pipeResolver?: any;
}
