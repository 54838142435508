/**
 * @license
 * Copyright SMART SRL ®. Tutti i diritti riservati
 *
 * @author
 * Leonardo Maria Miliacca
 *
 * L'uso di questo codice è soggetto a copyright
 */
import { IFTableColumn, IFColumnType } from '../../interfaces/if-table-column';
import { IFTable } from '../../interfaces/if-table';
import { Component, Input, EventEmitter, Output, OnInit, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { MessagesService } from 'src/app/services/messages.service';
import { TipoNotifica } from 'src/app/interfaces/tipo_notifica';
import { IFContactsPlatforms } from 'src/app/interfaces/client_contact';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { debounceTime, tap } from 'rxjs/operators';
import { WorkersService } from 'src/app/services/workers.service';
import {IFFilterCriteria, IFFilterType, IFTextFilterCriteria, IFNumberFilterCriteria} from '../../interfaces/if_filter';
@Component({
  selector: 'app-if-table',
  templateUrl: './if-table.component.html',
  styleUrls: ['./if-table.component.scss']
})
export class IFTableComponent implements OnInit, OnChanges {
  @Input() ifTable: IFTable;
  @Input() ifDocument: any[];
  @Input() totalElements: number;
  @Input() currentPage: number;
  @Input() querying: boolean;
  @Output() castDetail: EventEmitter<any> = new EventEmitter();
  @Output() castAction: EventEmitter<any> = new EventEmitter();
  @Output() navigateTo: EventEmitter<any> = new EventEmitter();
  @Output() eraseObj: EventEmitter<any> = new EventEmitter();
  @Output() changePageEvent = new EventEmitter<number>();
  @Output() picketSortCriteria = new EventEmitter<{attribute: string, order: 'ASC'|'DESC'}>();
  @Output() filterByEvt = new EventEmitter<any>();

  pages: number[];
  notificationsTypes: TipoNotifica[];

  RATING: number[] = [1, 2 , 3, 4, 5];
  IF_COL_TYPES = IFColumnType;

  IFContactsPlatforms = IFContactsPlatforms;

  ascendingOrder = true;

  filterForm: FormGroup;
  textSearchableFields: string[];
  numberFilterableFields: string[];
  boolFilterableFields: string[];


  filterPool: IFFilterCriteria[] = [];
  filteredResults: number;

  constructor(
    private ms: MessagesService,
    private fb: FormBuilder,
    private ws: WorkersService
  ) {
    if (this.ms.notificationsTypes) {
      this.notificationsTypes = this.ms.notificationsTypes;
    } else {
      this.ms.getNotificationsTypes().subscribe((nts: TipoNotifica[]) => {
        this.notificationsTypes = nts;
      });
    }
    this.filterForm = this.fb.group({});
  }

  public sortBy(column: IFTableColumn): void {
    this.ifTable.columns
    .map((c) => {
      c.selected = false;
    });
    column.selected = true;
    this.ascendingOrder = !this.ascendingOrder;
    this.picketSortCriteria.emit({
      attribute: column.name,
      order: this.ascendingOrder ? 'ASC' : 'DESC'
    });

  }

  public resetSort(): void {
    for (const c of this.ifTable.columns) {
      c.selected = false;
    }
  }

  sortArray(array: any[], property: string, order: boolean) {
    const direction: number = order ? 1 : -1;
    array.sort(function compare(a, b) {
        let comparison = 0;
        if (a[property] > b[property]) {
            comparison = 1 * direction;
        } else if (a[property] < b[property]) {
            comparison = -1 * direction;
        }
        return comparison;
    });
    return array; // Chainable
  }

  showDetailOf(entry: any): void {

    this.castDetail.emit(entry);
  }

  chunkArray(size: number): number[] {
    console.log('totalSize', size);
    const tempArray = [];
    let totalPages = Math.floor(size / 10);
    console.log('totalPages', totalPages);
    const rest = size % 10 > 0;
    if (rest) {
      totalPages++;
    }
    for (let i = 0; i < totalPages; i++) {
      tempArray.push(i);
    }
    return tempArray;
}

  ngOnInit(): void {
    this.pages = this.chunkArray(this.totalElements);
    this.determineFilters(this.ifTable.columns);
    this.filterForm
      .valueChanges.pipe(
        debounceTime(400),
      )
      .subscribe((value: any) => {
        const loopbackFilterObject = {};
        Object.keys(value).map(k => {
            switch (typeof value[k]) {
              case 'string':
                loopbackFilterObject[k] = {like: `${value[k]}`, options: 'i'};
              break;
              case 'number':
                loopbackFilterObject[k] = {gte: value[k]};
              break;
              case 'boolean':
                loopbackFilterObject[k] = value[k];
                break;
            }

        });
        this.filterByEvt.emit(loopbackFilterObject);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('CHANGES', changes);
    if (changes.ifDocument) {
      this.ifDocument = changes.ifDocument.currentValue;
    }
    this.pages = this.chunkArray(this.totalElements);
    console.log('PAGES', this.pages);
  }

  determineFilters(columns: IFTableColumn[]): void {
    this.textSearchableFields = columns
    .filter((crCol: IFTableColumn) => {
      return (
        crCol.type === this.IF_COL_TYPES.text
        );
    }).map((crc: IFTableColumn) => {
      this.setCtrl(crc);
      return crc.name;
    });

    this.boolFilterableFields = columns
    .filter((crCol: IFTableColumn) => {
      return crCol.type === this.IF_COL_TYPES.boolean;
    })
    .map((crc: IFTableColumn) => {
      this.setCtrl(crc);
      return crc.name;
    });

    this.numberFilterableFields = columns
    .filter((crCol: IFTableColumn) => {
      return (
        (crCol.type === this.IF_COL_TYPES.number ||
        crCol.type === this.IF_COL_TYPES.currency) && (
          crCol.filterable
        )
      );
    })
    .map((crc: IFTableColumn) => {
      this.setCtrl(crc);
      return crc.name;
    });
  }

  private setCtrl(crc: IFTableColumn): void {
    const colName = crc.name;
      this.filterForm.addControl(
        colName, new FormControl()
      );
  }

  show(entry: any, staysOnClick?: boolean): void {
    if (!staysOnClick) {
      this.navigateTo.emit({
        entry: entry
      });
    }
  }

  castErase(event: any): void {
    const userConfirmed = confirm('Sei sicuro di voler cancellare questo credito?');
    if (userConfirmed) {
      this.eraseObj.emit({
        entry: event
      });
    }
  }

  /**
   * Questo metodo serve per gestire l'evento di modifica valore di una `select`
   * poiché infatti se c'è una `select` prevista, deve esserci una
   * gestione della stessa.
   * @param $event
   */
  selectTriggered($event: Event, entry: any, columnName: string): void {

    this.castAction.emit({
      entry: entry,
      columnName: 'statopagamentoId',
      newValue: $event.target['value']
    });
  }

  changePage(currentIndex: number, qty: number, increase: boolean): void {
    console.log('changePage invoked', );
    const partialValue: number = increase ? currentIndex + qty : currentIndex - qty;
    let newValue: number;
    if (increase) {
      newValue = partialValue > (this.pages.length - 1) ? this.pages.length - 1 : partialValue;
    } else {
      newValue = partialValue < 0 ? 0 : partialValue;
    }
    this.changePageEvent.emit(newValue);
  }

  changePageForFiltered(newIndex: number): void {
    this.changePageEvent.emit(newIndex);
  }

}
