import { NgModule } from '@angular/core';
import { IFRechargeButtonComponent } from './if-recharge-btn.component';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    IFRechargeButtonComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    IFRechargeButtonComponent
  ]
})
export class IFRechargeBtnModule {}
