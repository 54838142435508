import { Component, ViewChild, ElementRef, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { MAIN_DOMAIN, STRIPE_KEY } from 'src/app/constants/server';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PublicCredit } from 'src/app/interfaces/public_credit';
import { HttpErrorResponse } from '@angular/common/http';
import { CreditsService } from 'src/app/services/credits.service';
import { PublicCreditResponse } from 'src/app/interfaces/responses/public_credit_response';
import { Credit } from 'src/app/interfaces/credit';
import { IFPublicNegativeFeedback, PublicAction, IFPublicPositiveFeedback } from 'src/app/interfaces/requests/public_payment';
import { MessagesService } from 'src/app/services/messages.service';
import { IFPagaBoxContent } from 'src/app/interfaces/if-paga-box-content';
import { IF_PAGA_FACILE_EDIT_PLACEHOLDERS } from 'src/app/constants/if_paga_facile_edit_placeholders';
import { IFFeedback } from 'src/app/interfaces/if_feedback';
import { MatSelect } from '@angular/material/select';

declare const Stripe;

const DEFAULT_POSITIVE_FEEDBACKS: IFFeedback[] = [{
  label: 'Bollettino',
  value: 'bollettino'
}, {
  label: 'Bonifico',
  value: 'bonifico'
}, {
  label: 'Carta',
  value: 'carta'
}, {
  label: 'Assegno',
  value: 'assegno'
}, {
  label: 'Contanti',
  value: 'contanti'
}, {
  label: 'RID',
  value: 'rid'
}, {
  label: 'PayPal',
  value: 'paypal'
}, {
  label: 'PagaFacile',
  value: 'paga-facile'
}];

const DEFAULT_NEGATIVE_FEEDBACKS: IFFeedback[] = [{
  label: 'Non ho ricevuto il bollettino',
  value: 'non-ho-ricevuto-il-bollettino'
}, {
  label: 'Il bollettino ricevuto non è per me',
  value: 'il-bollettino-ricevuto-non-e-per-me'
}];

@Component({
  selector: 'app-paga-box',
  templateUrl: './paga-box.component.html',
  styleUrls: ['./paga-box.component.scss']
})
export class PagaBoxComponent implements OnInit {
  @ViewChild('stripeForm', { read: true, static: false }) stripeForm: ElementRef;
  @Input() notificationId: string;
  @Input() token: string;
  @Input() pagaBoxContent: IFPagaBoxContent;
  @Input() feedbacks: {
    positivi: IFFeedback[],
    negativi: IFFeedback[]
  } = {
    positivi: [],
    negativi: []
  };
  @Output() correctlyPaidEvent = new EventEmitter<boolean>();
  @Output() errorOccurred = new EventEmitter<HttpErrorResponse>();
  @Output() payErrorOccurred = new EventEmitter<HttpErrorResponse>();
  @Output() born = new EventEmitter<boolean>();

  @ViewChild('positiveSelect', { read: true, static: false }) positiveSelect: MatSelect;
  @ViewChild('negativeSelect', { read: true, static: false }) negativeSelect: MatSelect;

  loading = false;

  card: any;
  stripe: any;
  elements: any;

  correctlyPaid: boolean;
  DOMAIN = MAIN_DOMAIN;
  showFeedback = false;
  showOther = false;

  months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];

  years: number[] = [];

  paymentForm: FormGroup;
  feedbackForm: FormGroup;
  otherForm: FormGroup;

  credit: PublicCredit;
  currentCredit: PublicCredit;

  DEFAULT_POSITIVE_FEEDBACKS = DEFAULT_POSITIVE_FEEDBACKS;
  DEFAULT_NEGATIVE_FEEDBACKS = DEFAULT_NEGATIVE_FEEDBACKS;
  IF_PAGA_FACILE_EDIT_PLACEHOLDERS = IF_PAGA_FACILE_EDIT_PLACEHOLDERS;

  positiveFeedbacks: IFFeedback[];
  negativeFeedbacks: IFFeedback[];

  constructor(
    private cr: CreditsService,
    private fb: FormBuilder,
    private msgss: MessagesService
  ) {
    this.stripe = new Stripe(STRIPE_KEY);
    this.elements = this.stripe.elements();

    const currentYear = new Date().getFullYear();

    for (let i = currentYear; i < currentYear + 10; i++ ) {
      this.years.push(i);
    }


    this.buildPaymentForm();
    this.buildFeedbackForm();
    this.buildOtherForm();
  }

  buildOtherForm(): void {
    this.otherForm = this.fb.group({
      why: [null, Validators.required]
    });
  }

  buildFeedbackForm(): void {
    this.feedbackForm = this.fb.group({
      when: [null, Validators.required],
      how: [null, Validators.required]
    });
  }

  buildPaymentForm(): void {
    this.paymentForm = this.fb.group({
      cardNumber: [null, Validators.required],
      owner: [null, Validators.required],
      expirationMonth: [null, Validators.required],
      expirationYear: [null, Validators.required],
      cvc: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(3)]]
    });

    this.paymentForm.valueChanges.subscribe((newValues) => {

    });
  }

  ngOnInit(): void {
    console.group('PAGABOX');
    console.log('this.token', this.token);
    console.log('this.notificationId', this.notificationId);
    console.groupEnd();

    this.msgss.newFeedbackAdded$.subscribe((newFeedback: {feedbacks: IFFeedback[], arePositive: boolean}) => {
      console.log('received!', newFeedback);
      if (newFeedback.arePositive) {
        this.positiveFeedbacks = newFeedback.feedbacks;
        if (this.positiveSelect) {
          this.positiveSelect.open();
        }
      } else {
        this.negativeFeedbacks = newFeedback.feedbacks;
        if (this.negativeSelect) {
          this.negativeSelect.open();
        }
      }
    });

    this.msgss.triggerMainText$.subscribe((value) => {
      this.showFeedback = false;
      this.showOther = false;
    });

    this.cr.getPublicCreditInfo(this.token, this.notificationId).subscribe((c: PublicCreditResponse) => {

      this.credit = c.data;
      this.credit.data_invio = new Date(this.credit.data_invio).toISOString();

      if (this.pagaBoxContent) {
        // significa che sto in edit mode
        this.pagaBoxContent.parsedMessage = c.data.messaggio;
      }
      if (c.data.negativi) {
        this.negativeFeedbacks = c.data.negativi;
      } else {
        this.negativeFeedbacks = DEFAULT_NEGATIVE_FEEDBACKS;
      }

      if (c.data.positivi) {
        this.positiveFeedbacks = c.data.positivi;
      } else {
        this.positiveFeedbacks = DEFAULT_POSITIVE_FEEDBACKS;
      }

      setTimeout(() => {
        this.mountStripeElements();
        this.born.emit(true);
      }, 500);
    }, (err: HttpErrorResponse) => {
      this.errorOccurred.emit(err);
    });

    this.msgss.triggerFeedbackWindow$.subscribe((v: boolean) => {
        this.showOther = !v;
        this.showFeedback = v;
    });

    this.msgss.pagaBoxValue$.subscribe((newValue: IFPagaBoxContent) => {
      if (this.credit) {
        this.pagaBoxContent = newValue;
        let newMsg = newValue.messaggio;
        IF_PAGA_FACILE_EDIT_PLACEHOLDERS.map((plch: string) => {
          console.log('checking', plch);
          if (newMsg.match(new RegExp(`{${plch}}`, 'ig'))) {
            newMsg = newMsg.replace(new RegExp(`{${plch}}`, 'ig'), this.detectFieldAndFormatData(plch, this.credit));
            console.log('newMsg', newMsg);
          }

          console.log('newParseMsg', newMsg);
          this.pagaBoxContent.parsedMessage = newMsg;
        });
      }

    });
  }

  detectFieldAndFormatData(plch: string, credit: PublicCredit): string {
    console.log('credit', credit);
    switch (plch) {
      case 'nome':
        return credit.denominazione;
      case 'importo':
      case 'riferimento':
        return credit[plch] ? credit[plch].toString() : '';
      case 'data_invio':
      case 'data_scadenza':
        const date = new Date(credit[plch]);
        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    }
  }

  openFeedback(): void {
    this.showFeedback = !this.showFeedback;

    if (this.showOther) {
      this.showOther = false;
    }
  }

  openOtherFeedback(): void {
    this.showOther = !this.showOther;
    if (this.showFeedback) {
      this.showFeedback = false;
    }
  }

  mountStripeElements(): void {
    const styles = {
      base: {
        border: '1px solid #ccc',
        color: '#555',
        fontWeight: 500,
        fontFamily: 'Verdana',
        fontSize: '16px',
        fontSmoothing: 'antialiased',

        '::placeholder': {
          color: '#aaa',
        },
        ':-webkit-autofill': {
          color: '#e39f48',
        },
      },
      invalid: {
        color: '#a51000',

        '::placeholder': {
          color: '#FFCCA5',
        },
      },
    };
    this.card = this.elements.create('card', {
      style: styles
    });
    this.card.mount('#card');

    this.card.addEventListener('change', function(event) {
      const displayError = document.getElementById('card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });

    const form = document.getElementById('payment-form');
    form.addEventListener('submit', (event) => {
      console.log('submit', event);
      this.loading = true;
      event.preventDefault();

      this.stripe.createToken(this.card).then((result) => {
        console.log('result', result);
        if (result.error) {
          // Inform the user if there was an error.
          const errorElement = document.getElementById('card-errors');
          errorElement.textContent = result.error.message;
          this.loading = false;
        } else {
          // Send the token to your server.
          this.stripeTokenHandler(result.token);
        }
      });
    });
  }

  stripeTokenHandler(token: string): void {
    this.cr.payPublicCreditInfo(this.token, this.notificationId, token)
    .subscribe((response: Credit) => {
      this.correctlyPaid = true;
      this.correctlyPaidEvent.emit(true);
      this.loading = false;
    }, (err: HttpErrorResponse) => {
      // TODO handle
      this.payErrorOccurred.emit(err);
      this.loading = false;
    });
  }

  sendNegativeForm(): void {
    const payload: IFPublicNegativeFeedback = {
      action: PublicAction.unpaid,
      data: {
        reason: this.otherForm.value.why
      }
    };
    this.executeFeedbackCall(this.token, this.notificationId, payload);
  }

  sendPositiveForm(): void {
    const formValues = this.feedbackForm.value;
    const payload: IFPublicPositiveFeedback = {
      action: PublicAction.unpaid,
      data: {
        date: formValues.when,
        payment: formValues.how
      }
    };
    this.executeFeedbackCall(this.token, this.notificationId, payload);
  }

  private executeFeedbackCall(
    token: string,
    notificationId: string,
    payload: IFPublicNegativeFeedback|IFPublicPositiveFeedback): void {


    this.cr.givePublicFeedback(token, notificationId, payload)
    .subscribe((response: Credit) => {
      alert('Grazie per il feedback!');
    });
  }

}
