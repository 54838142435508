import { IFOrder, IFItem } from './../../interfaces/if_order';
import { IFFlash, IFFlashType } from './../../interfaces/if-notification';
import { Catalog } from './../../interfaces/catalog';
import { ClientsService } from './../../services/clients.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, OnDestroy,
  ChangeDetectorRef, Output, EventEmitter, Input } from '@angular/core';
import { FlashService } from '../../services/notifications.service';
import { STRIPE_KEY } from '../../constants/server';
import { IFPurchaseResponse } from '../../interfaces/purchase_response';

declare const Stripe;

@Component({
  selector: 'app-if-buy-items',
  templateUrl: './app-if-buy-sms.component.html',
  styleUrls: ['./app-if-buy-sms.component.scss']
})
export class AppIfBuySmsComponent implements OnInit, AfterViewInit, OnDestroy {
  /**
   * Questo è il div in cui verrà costruito il form in modo automatico da Stripe.
   */
  @ViewChild('cardInfo', {static: true}) cardInfo: ElementRef;
  /**
   * Questo attributo fa capire se si stanno acquistando SMS o EMAIL
   */
  @Input() itemType: 'emails'|'sms';
  /**
   * Una volta completato l'acquisto emetto questo evento per chiudere la modale.
   */
  @Output() transationOk = new EventEmitter<IFPurchaseResponse>();

  /**
   * Questo è il form dove scelgo il pacchetto da acquistare, e flaggo l'accettazione.
   */
  buyItemForm: FormGroup;

  /**
   * Un riferimento all'oggetto globale Stripe.
   */
  stripe: any;
  /**
   * Un riferimento all'oggetto glogale Elements
   */
  elements: any;

  /**
   * Il catalogo che scarico da server e mostro nella select.
   */
  catalogs: Catalog[];

  /**
   * I dati della carta, in un formato compatibile con Stripe.
   */
  card: any;

  /**
   * L'handler della carta.
   */
  cardHandler = this.onChange.bind(this);

  /**
   * Se c'è un errore, è tramite questa stringa che apparirà.
   */
  error = '';
  loading = false;

  /**
   * La parola chiave per acquistare.
   */
  purchaseWord = 'ACQUISTA';

  constructor(
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    public cs: ClientsService,
    private fs: FlashService
  ) {
    this.buyItemForm = this.fb.group({
      offer: [null, Validators.required],
      accettazione: [false, Validators.requiredTrue]
    });

  }

  get catalogSelected(): boolean {
    return this.buyItemForm.value.offer ? true : false;
  }

  get selectedCatalogMoreVAT(): string {
    const price: number = this.catalogs.find((c: Catalog) => {
      return c.token === this.buyItemForm.value.offer;
    }).prezzo;
    const catalogPrice: string =  ((price + (price * 0.22)) / 100).toFixed(2);
    return `${catalogPrice}`;
  }

  get currSelectedCatalog(): number {
    return this.catalogs.find((c: Catalog) => {
      return c.token === this.buyItemForm.value.offer;
    }).ricarica;
  }

  get currentlySelectedCatalog(): Catalog {
    return this.catalogs.find((c: Catalog) => {
      return c.token === this.buyItemForm.value.offer;
    });
  }

  ngOnInit() {
    this.cs.getCatalogs().subscribe((cts: Catalog[]) => {
      this.catalogs = cts.filter((ct: Catalog) => {
        return ct.categoriaId === this.itemType;
      });
      const firstCatalog: Catalog = this.catalogs[0];
      this.buyItemForm.patchValue({offer: firstCatalog.token});
    }, err => {
      // TODO catch error
    });
    if (Stripe) {
      this.stripe = Stripe(STRIPE_KEY); // use your test publishable key
      this.elements = this.stripe.elements({locale: 'it'});
    }
  }

  ngAfterViewInit() {
    this.card = this.elements.create('card');
    this.card.mount(this.cardInfo.nativeElement);

    this.card.addEventListener('change', this.cardHandler);
  }

  ngOnDestroy() {
    if (this.card) {
      this.card.removeEventListener('change', this.cardHandler);
      this.card.destroy();
    }
  }

  onChange({ error }) {
    if (error) {
      this.error = error.message;
    } else {
      this.error = null;
    }
    this.cd.detectChanges();
  }

  /**
   * Quando l'utente clicca su aquista è questo il metodo che si va ad attivare.
   * Triggera a `true` la variabile `loading`, dopodiché crea un token Stripe, infine
   * in caso di creazione positiva del token stripe chiama il metodo `sendToServer`, altrimenti
   * avvisa l'utente che la creazione non è andata a buon fine.
   */
  purchaseFlow(): void {
    this.loading = true;
    this.stripe.createToken(this.card).then((result) => {
      if (result.error) {
        // Inform the user if there was an error.
        this.error = result.error.message;
        const n: IFFlash = {
          text: 'Errore nel pagamento',
          type: IFFlashType.error,
          id: 'pay-err',
          time: 3000
        };
        this.fs.emitNotification(n);
        this.loading = false;
      } else {
        // Send the token to your server.
        this.sendToServer(result.token);
      }
    });
  }

  /**
   * Questo metodo si occupa di inviare il token stripe al server, in caso di conferma
   * dal server l'`Output` `transationOk` si attiverà e farà in modo di chiudere il modal.
   * @param {any} stripeToken non posso tipizzarlo poiché non ho trovato i typings.
   */
  private sendToServer(stripeToken: any): void {
    const chosenToken: string = this.buyItemForm.value.offer;
    const accettazione: boolean = this.buyItemForm.value.accettazione;
    const item: IFItem = {token: chosenToken, qta: 1};
    const order: IFOrder = {
      carrello: [item],
      pagato: false,
      tokenCC: stripeToken.id,
      evaso: false,
      accettazione: accettazione
    };
    this.cs.purchaseSms(order)
    .subscribe((response: IFPurchaseResponse) => {
      // TODO SUCCESS
      const n: IFFlash = {
        text: 'Acquisto confermato',
        type: IFFlashType.check_circle_outline,
        id: 'pay-ok-serv',
        time: 3000
      };
      this.fs.emitNotification(n);
      this.loading = false;
      this.transationOk.emit(response);
    }, err => {
      const n: IFFlash = {
        text: 'Errore tecnico server',
          type: IFFlashType.error,
          id: 'pay-err-serv',
          time: 3000
      };
      this.fs.emitNotification(n);
      this.loading = false;
    });
  }

}
