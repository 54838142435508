<h1 mat-dialog-title>Problema nell'upload</h1>
<div mat-dialog-content>
  <p>{{error.error.error.info}}</p>
</div>
<div mat-dialog-actions>
  <button
  mat-button
  type="button" class="if-btn btn-danger" (click)="onNoClick()">
    OK
  </button>

</div>
