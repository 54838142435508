import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CLIENTS_KEYWORD_URL, CONTRACTS_KEYWORD_URL, CREDITS_KEYWORD_URL, DASHBOARD_KEYWORD, KEYWORDS_URL, MARKETPLACE_KEYWORD_URL, SEARCH_KEYWORD_URL } from 'src/app/constants/keywords';
import { ClientsService } from 'src/app/services/clients.service';
import { LogoutDialogComponent } from '../logout-dialog/logout-dialog';
import { MatDialog } from '@angular/material/dialog';
import { animate, style, transition, trigger } from '@angular/animations';
@Component({
  selector: 'if-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: [trigger('entering', [
    transition(':enter', [
      style({ opacity: 0 }),
      animate('300ms', style({ opacity: 1 })),
    ])
  ])]
})
export class SidebarComponent implements OnInit, OnDestroy, OnChanges {

  @Input() restricted: boolean;

  currentUrl: string;
  urlChange: Subscription;
  logoutSub: Subscription;
  keywords = KEYWORDS_URL;

  showLetters = true;

  paths = {
    dashboard: `/${DASHBOARD_KEYWORD}`,
    clients: `/${DASHBOARD_KEYWORD}/${CLIENTS_KEYWORD_URL}`,
    credits: `/${DASHBOARD_KEYWORD}/${CREDITS_KEYWORD_URL}`,
    profile: `/${DASHBOARD_KEYWORD}/profilo`,
    notifications: `/${DASHBOARD_KEYWORD}/notifiche`,
    messages: `/${DASHBOARD_KEYWORD}/messaggi`,
    contracts: `${DASHBOARD_KEYWORD}/${CONTRACTS_KEYWORD_URL}`,
    marketplace: `/${DASHBOARD_KEYWORD}/${MARKETPLACE_KEYWORD_URL}`,
    search: `/${DASHBOARD_KEYWORD}/${SEARCH_KEYWORD_URL}`
  };


  constructor(
    public router: Router,
    private cs: ClientsService,
    private d: MatDialog
  ) { }

  ngOnInit(): void {
    this.urlChange = this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
      }
    });
  }

  ngOnDestroy(): void {
    this.urlChange?.unsubscribe();
    this.logoutSub?.unsubscribe();
  }

  ngOnChanges(): void {
    if (this.restricted) {
      setTimeout(() => {
        this.showLetters = false;
      }, 0);
    } else {
      setTimeout(() => {
        this.showLetters = true;
      }, 100);
    }
  }

  logout(): void {

    const dialogRef = this.d.open(LogoutDialogComponent, {
      width: '250px'
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {

      if (result) {
        this.logoutSub = this.cs.logout(localStorage.getItem('ifauth'))
        .subscribe((res) => {
          localStorage.removeItem('ifauth');
          this.router.navigate(['/']);
        }, err => {
          localStorage.removeItem('ifauth');
          this.router.navigate(['/']);
        });
      }
    });
}

}
