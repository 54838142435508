<section *ngIf="!notificationForEdit">
  <div class="no-credits">

    <div class="message">
        Per modificare queste impostazioni devi
          avere almeno una notifica
    </div>

  </div>
</section>

<section class="container" *ngIf="notificationForEdit">
  <div class="paga-box">
    <mat-card>
      <mat-card-header>
        <mat-card-title>
          <h3>MODIFICA</h3>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <form [formGroup]="editForm">
          <mat-form-field>
            <input formControlName="stripeId" type="text" matInput placeholder="Codice Stripe*" />
          </mat-form-field>
          <mat-form-field>
            <textarea
            rows="5" (focus)="triggerMainText()" #messageTextArea formControlName="messaggio" matInput
              placeholder="Messaggio...*">
            </textarea>
          </mat-form-field>
          <blockquote>
            <strong>Nota bene:</strong>: puoi utilizzare le seguenti variabili,
            cliccane una per vedere l'anteprima in tempo reale, nel riquadro a
            destra<br />
            <ng-container *ngFor="let plch of IF_PAGA_FACILE_EDIT_PLACEHOLDERS">
              <div (click)="addPlaceholder(plch)" class="placeholder">
                <em>{{ plch }}</em>
              </div>
            </ng-container>
          </blockquote>
          <hr />
          <h2>Gestione Feedback</h2>
          <div

           class="feedbacks" [formGroup]="feedbacksForm">
            <div
            (focus)="triggerFeedBackWindow(true)"
            class="feedbacks-container">
              <h3>Positivi</h3>
              <p class="explain">Nel caso <em>positivo</em> il cliente ha già pagato, fatti spiegare come.</p>

              <div class="existing-container" *ngIf="cls.me?.paga_facile.positivi">
                <div class="existing" *ngFor="let feedback of cls.me.paga_facile.positivi; let i = index">
                  <div class="index">
                    <div class="circle colored">
                      {{i + 1}}
                    </div>
                  </div>
                  <div class="text">
                    {{feedback.label}}
                  </div>
                  <div class="button">
                    <div (click)="remove(true, feedback)" class="circle erased">
                      —
                    </div>
                  </div>
                </div>
              </div>

              <mat-form-field [formGroupName]="POSITIVE_KEYWORD">
                <input matInput  (input)="populateValue(true)"
                  formControlName="label" placeholder="Scrivi qui il feedback*">
              </mat-form-field>
              <button [disabled]="feedbacksForm.get(POSITIVE_KEYWORD).invalid" class="if-btn btn-light plus"
                (click)="addFeedback(true)">
                Aggiungi
              </button>
            </div>
            <div
            (focus)="triggerFeedBackWindow(false)"
            class="feedbacks-container">
              <h3>Negativi</h3>
              <p class="explain">Nel caso <em>negativo</em> non ha pagato per qualche ragione. Scegli cosa possono
                risponderti.</p>

              <div class="existing-container" *ngIf="cls.me?.paga_facile.negativi">
                <div class="existing" *ngFor="let feedback of cls.me.paga_facile.negativi; let i = index">
                  <div class="index">
                    <div class="circle colored">
                      {{i + 1}}
                    </div>
                  </div>
                  <div class="text">
                    {{feedback.label}}
                  </div>
                  <div class="button">
                    <div (click)="remove(false, feedback)" class="circle erased">
                      —
                    </div>
                  </div>
                </div>
              </div>

              <mat-form-field [formGroupName]="NEGATIVE_KEYWORD">
                <input matInput (focus)="triggerFeedBackWindow(false)" (input)="populateValue(false)"
                  formControlName="label" placeholder="Scrivi qui il feedback*">
              </mat-form-field>
              <button [disabled]="feedbacksForm.get(NEGATIVE_KEYWORD).invalid" class="if-btn btn-light plus"
                (click)="addFeedback(false)">
                Aggiungi
              </button>
            </div>
          </div>

        </form>
        <br />
        <button
        type="button"
        [disabled]="editForm.invalid" (click)="savePFData()" class="if-btn btn-dark">
          Salva
        </button>
      </mat-card-content>
    </mat-card>
  </div>

  <div class="paga-box">
    <h3>ANTEPRIMA</h3>
    <div class="testing">
      <app-paga-box
      [feedbacks]="{
        positivi: cls.me.paga_facile.positivi,
        negativi: cls.me.paga_facile.negativi
      }"
      [notificationId]="notificationForEdit.id"
      [token]="token"
      *ngIf="notificationForEdit && token && cls.me"
        (born)="pagaBoxIsBorn($event)"></app-paga-box>
    </div>

    <h5>
      <a [href]="notificationForEdit.url" target="_blank">
          Visualizza il risultato finale
      </a>
    </h5>
    <h5>
      Short Url
      <a [href]="notificationForEdit.shorturl" target="_blank">
        {{notificationForEdit.shorturl}}
      </a>
    </h5>
  </div>
</section>
