import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { XMLErrorDialogComponent } from './xml-error-dialog/xml-error-dialog.component';
import { NgModule } from '@angular/core';
import { IFXMLImportComponent } from './if-xml-import.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { HttpClientModule } from '@angular/common/http';
import { ErrorMsgsPipe } from './error-msgs.pipe';

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MatFormFieldModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    HttpClientModule
  ],
  declarations: [
    IFXMLImportComponent,
    ErrorMsgsPipe,
    XMLErrorDialogComponent
  ],
  exports: [
    IFXMLImportComponent
  ]
})
export class IFXMLImportModule {}
