<h2>Acquista {{itemType}}</h2>
<form [formGroup]="buyItemForm">
  <mat-form-field>
    <mat-select placeholder="Seleziona pacchetto*" formControlName="offer">
      <mat-option *ngFor="let catalog of catalogs" [value]="catalog.token">
        {{catalog.nome}}
      </mat-option>

    </mat-select>
  </mat-form-field>

  <ng-container *ngIf="catalogSelected">
    <p>Stai acquistando <strong>{{currSelectedCatalog}} {{itemType}}</strong> per
      <strong>{{(currentlySelectedCatalog.lordo / 100).toFixed(2)}} &euro;</strong> (IVA Inclusa)</p>
  </ng-container>

  <mat-card>

    <div class="purchase">
      <div>
        <h5>Paga in modo sicuro con Stripe©</h5>
      </div>
      <div id="card-info" #cardInfo></div>
      <div class="card-errors" role="alert">{{ error }}</div>
      <div class="terms-conditions">
        <mat-checkbox
        formControlName="accettazione"
        >
        </mat-checkbox>
        Facendo click su {{purchaseWord}}, dichiaro di aver letto,
        compreso ed accettato le
        <a href="https://www.incassofacile.com/termini-e-condizioni/" target="_blank">
          Termini e Condizioni
        </a>
      </div>
      <div class="btn-holder">
        <ng-container *ngIf="!loading">
          <button
          (click)="purchaseFlow()"
          [disabled]="buyItemForm.invalid"
          type="button"
          class="if-btn btn-dark">
            {{purchaseWord}}
          </button>
        </ng-container>
        <ng-container *ngIf="loading">
          <mat-spinner [strokeWidth]="1"></mat-spinner>
        </ng-container>
      </div>
    </div>
  </mat-card>
</form>
