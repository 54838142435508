<nav mat-tab-nav-bar>
  <a mat-tab-link
    [routerLink]="['../anagrafiche']"
    routerLinkActive="selected"
      #rla="routerLinkActive"
     [active]="rla.isActive">
    Profilo
  </a>
  <a
  #pf="routerLinkActive"
  [active]="pf.isActive"
  mat-tab-link
  routerLinkActive="selected" [routerLink]="['../paga-facile']">
    Paga Facile</a
  >
  <a
  #pec="routerLinkActive"
  [active]="pec.isActive"
  mat-tab-link
  routerLinkActive="selected" [routerLink]="['../pec']">
    PEC
  </a>
  <a
  #sede="routerLinkActive"
  [active]="sede.isActive"
  mat-tab-link
  [routerLink]="['../sede']" routerLinkActive="selected">
    Sede
  </a>
  <a #c="routerLinkActive"
  [active]="c.isActive"
  mat-tab-link

  [routerLink]="['../contatti']" routerLinkActive="selected">
    Contatti
  </a>
  <a
  #cc="routerLinkActive"
  [active]="cc.isActive"
  mat-tab-link

  [routerLink]="['../conto']" routerLinkActive="selected">
    Conto
  </a>
  <a
  #csf="routerLinkActive"
  [active]="csf.isActive"
  mat-tab-link

  [routerLink]="['../cassetto-fiscale']" routerLinkActive="selected">
    Cassetto Fiscale
  </a>
</nav>

<section class="container" #container>
  <form>
    <div class="areas">
      <div class="macro-area-container">
        <div class="macro-area form-container">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </form>
</section>
