import { IFFlash } from '../../interfaces/if-notification';

import { Component } from '@angular/core';
import { FlashService } from '../../services/notifications.service';
@Component({
  selector: 'app-if-notifications',
  templateUrl: './if-notifications.component.html',
  styleUrls: ['./if-notifications.component.scss']
})
export class IFNotificationsComponent {
  currentNotifications: IFFlash[] = [];

  constructor(
    private ns: FlashService
  ) {
    this.ns.notificationsObservable$
    .subscribe((nss: IFFlash[]) => {
      this.currentNotifications = nss;
    });
  }

  popNotification(notification: IFFlash): void {
    notification.goingOut = true;
    setTimeout(() => {
      this.ns.pop(notification.id);
    }, 2000);
  }
}
