export enum PublicAction {
  payNow = 'payNow',
  unpaid = 'unpaid',
  paid = 'paid'
}

interface IFPublicRequest {
  action: PublicAction;
}

export interface IFPublicPayment extends IFPublicRequest {
  data: {
    tokenCC: string;
  };
}
export interface IFPublicPositiveFeedback extends IFPublicRequest {
  data: {
    date: string;
    payment: string;
  };
}
export interface IFPublicNegativeFeedback extends IFPublicRequest {
  data: {
    reason: string;
  };
}
