export const PROFILES_KEYWORD = 'Profili';
export const PROFILES_KEYWORD_URL = 'profilo';
export const CLIENTS_KEYWORD = 'Clienti';
export const CLIENTS_KEYWORD_URL = 'clienti';
export const DASHBOARD_KEYWORD = 'dashboard';
export const CREDITS_KEYWORD_URL = 'crediti';
export const CREDITS_KEYWORD = 'Crediti';
export const EXPIRATIONS_KEYWORD = 'Scadenze';
export const EXPIRATIONS_KEYWORD_URL = 'scadenze';
export const NOTIFICATIONS_KEYWORD_URL = 'Notifiche';
export const NOTIFICATIONS_KEYWORD = 'notifiche';
export const NOTIFICATIONS_TYPES_KEYWORD = 'TipiNotifica';
export const NOTIFICATIONS_TYPES_KEYWORD_URL = '';
export const MESSAGES_TYPES_KEYWORD_URL = 'Modelli';
export const IMPORT_CREDITS_KEYWORD_URL = 'importCredits';
export const IMPORT_CLIENTS_KEYWORD_URL = 'importClients';
export const IMPORT_INVOICES_KEYWORD_URL = 'importInvoices';
export const PRIVACY_KEYWORD_URL = 'consensi';
export const CONTRACTS_KEYWORD = 'Modulistica';
export const CONTRACTS_KEYWORD_URL = 'contratti';
export const SEND_KEYWORD = 'invia';
export const MODEL_KEYWORD = 'model';
export const SEARCH_KEYWORD_URL = 'cerca';
export const MARKETPLACE_KEYWORD_URL = 'marketplace';

export const KEYWORDS_URL = {
  clients: CLIENTS_KEYWORD_URL,
  credits: CREDITS_KEYWORD_URL,
  profile: PROFILES_KEYWORD_URL,
  dashboard: DASHBOARD_KEYWORD,
  expirations: EXPIRATIONS_KEYWORD_URL,
  notifications: NOTIFICATIONS_KEYWORD,
  model: MODEL_KEYWORD,
  importCredits: IMPORT_CREDITS_KEYWORD_URL,
  importClients: IMPORT_CLIENTS_KEYWORD_URL,
  importInvoices: IMPORT_INVOICES_KEYWORD_URL,
  contracts: CONTRACTS_KEYWORD_URL,
  send: SEND_KEYWORD,
  buySms: 'sms',
  buyEmails: 'email',
  editInvoice: 'modifica-fattura',
  privacyPath: PRIVACY_KEYWORD_URL,
  marketplace: MARKETPLACE_KEYWORD_URL,
  search: SEARCH_KEYWORD_URL
};
