import { Observable } from 'rxjs';
/**
 * @license
 * Copyright SMART SRL ®. Tutti i diritti riservati
 *
 * @author
 * Leonardo Maria Miliacca
 *
 * L'uso di questo codice è soggetto a copyright
 */

import { Credit } from '../../interfaces/credit';
import { ClientsService } from '../../services/clients.service';
import { CreditsService } from '../../services/credits.service';
import { FormBuilder, Validators, FormArray, FormGroup, FormControl } from '@angular/forms';

import { Component, EventEmitter, Output, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Client } from '../../interfaces/client';
import { debounceTime, map } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { FlashService } from '../../services/notifications.service';
import { IFFlash } from '../../interfaces/if-notification';

import { StatoPagamento } from './../../interfaces/stato_pagamento';
import { IFCurrencies } from 'src/app/interfaces/if_currencies';
import { FatturaElettronicaBody } from 'src/app/interfaces/fatturazione_elettronica/fattura_semplificata';
import { MessagesService } from 'src/app/services/messages.service';
import { UtilsService } from 'src/app/services/utils.service';
import { Router } from '@angular/router';
import { DASHBOARD_KEYWORD, CREDITS_KEYWORD_URL } from 'src/app/constants/keywords';
import { LookupModels } from 'src/app/interfaces/lookup-models';

@Component({
  selector: 'app-if-credit-form',
  templateUrl: './if-credit-form.component.html',
  styleUrls: ['./if-credit-form.component.scss']
})
export class IFCreditFormComponent implements OnInit {
  @Output() creditCreated: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('installmentsInput', {static: true}) installmentsInput: ElementRef;
  @ViewChild('stepper', {static: true}) stepper: ElementRef;

  isLinear = true;
  generals: FormGroup;

  fatturaElettronica: {FatturaElettronicaBody: FatturaElettronicaBody[]};

  invoiceForm: FormGroup;

  smsForm: FormGroup;

  filteredOptions: Client[];
  paymentsStatuses: StatoPagamento[];
  statoPagamentiLabels: {[key: string]: string};

  MIN_VALUE: number;
  showInfoBubble = false;

  IFCurrencies = IFCurrencies;

  addingAModel: string;

  documentTypes: {value: string, label: string}[] = [];

  atLeastOneClient$: Observable<boolean>;

  constructor(
    private fb: FormBuilder,
    private creditsService: CreditsService,
    public clientsService: ClientsService,
    private ns: FlashService,
    public ms: MessagesService,
    public us: UtilsService,
    private router: Router

  ) {
  }


  ngOnInit() {

    this.atLeastOneClient$ = this.clientsService
    .getParameterizedClientsCount({stato: true})
    .pipe(
      map((value: {count: number}) => {
        return !!value.count;
      })
    );

    this.paymentsStatuses = this.creditsService._paymentsStatuses;
    this.statoPagamentiLabels = this.creditsService.paymentsStatusesLabels;

    this.creditsService.setAliquoteIVA();

    this.invoiceForm = this.fb.group({
      FatturaElettronicaBody: [null, Validators.required]
    });

    this.buildGeneralsForm();
    this.generals.patchValue({statopagamentoId: this.paymentsStatuses.find((ps: StatoPagamento) => {
        return ps.token === 'open';
      }).id
    });

    if (this.clientsService.currentlySelectedClient) {
      this.generals.patchValue({
        denominazione: this.clientsService.currentlySelectedClient.UINome,
        clienteId: this.clientsService.currentlySelectedClient.id,
        periodo_verifica: this.clientsService.currentlySelectedClient.periodo_verifica
      });
      this.calculateExpirationDate(this.generals);
    } else {
      if (this.clientsService.me.periodo_verifica_base) {
        this.generals.patchValue({
          periodo_verifica: this.clientsService.me.periodo_verifica_base
        });
      }
    }
    this.buildSmsForm();
    // commentato poiché gli installments sono ora gestiti in tutt'altra maniera
    // this.buildInstallmentsForm();

    this.generals.get('denominazione').valueChanges
    .pipe(
      debounceTime(500)
    )
    .subscribe((term) => {
      if (typeof term === 'string') {
        this.clientsService.getClientsList(
          10,
          0,
          'UINome ASC',
          {UINome: {like: term, options: 'i'}}
        )
        .subscribe((clients: Client[]) => {
          this.filteredOptions = clients;
        });
      }
    });

      this.generals.get('periodo_verifica')
      .valueChanges
      .subscribe((newPeriodVerifica) => {
        this.calculateExpirationDate(this.generals);
      });

    this.generals.get('clienteId')
    .valueChanges
    .subscribe((newPeriodVerifica) => {
      this.calculateExpirationDate(this.generals, newPeriodVerifica);
    });

    this.clientsService.getLookupResource(LookupModels.TipoDocumento)
    .subscribe((response) => {

      const {value} = response;
      const defaultValue = response.default;
      Object.keys(value).map((key) => {
        const tipoDocObj = {
          value: key,
          label: value[key]
        };
        this.documentTypes.push(tipoDocObj);
      });
      this.generals.patchValue({
        tipodocumento: defaultValue
      });
      this.documentTypes = this.documentTypes; // trigger change

    });
  }


  patchFatturaElettronica($event: {FatturaElettronicaBody: FatturaElettronicaBody[]}| null): void {
    if ($event) {
      this.invoiceForm.patchValue($event);
    } else {
      this.invoiceForm.reset();
    }
  }

  buildSmsForm(): void {
    this.smsForm = this.fb.group({
      notifications: this.fb.array([this.createNotification('sms')])
    });
  }

  addNotification(formGroup: FormGroup, notificationType: string): void {
    const items = formGroup.get('notifications') as FormArray;
    items.push(this.createNotification(notificationType));
  }

  createNotification(tipoNotifica: string = 'email'): FormGroup {
    return this.fb.group({
      tipoNotifica
    });
  }

  buildGeneralsForm(): void {
    this.generals = this.fb.group({
      riferimento: [null],
      denominazione: [''],
      clienteId: ['', [Validators.required]],
      tipodocumento: [''],
      statopagamentoId: [''],
      importo: [this.MIN_VALUE, [Validators.required, Validators.min(0)]],
      data_emissione: [new Date()],
      data_scadenza: [new Date(), Validators.required],
      periodo_verifica: [0],
      stop_notifiche: [false]
    });
  }

  get smsData(): FormArray {
    return this.smsForm.get('notifications') as FormArray;
  }

  typingFilter(typed: string, clients: Client[]): Client[] {
    const filtered: Client[] = clients.filter((cl: Client) => {
        return cl.UINome.toLowerCase().includes(typed.toLowerCase());
      });
      return filtered;
  }

  /**
   *
   * @param event il click su un'opzione dell'autocomplete
   * @param clients tutti i clienti
   * @param generalsForm il formGroup di questa prima parte
   */
  selectClient(event: MatAutocompleteSelectedEvent, generalsForm: FormGroup): void {
    generalsForm.patchValue({
      clienteId: event.option.value.id,
      periodo_verifica: event.option.value.periodo_verifica
    }, {
      emitEvent: false,
      onlySelf: true
    });
    this.calculateExpirationDate(this.generals);
  }

  correctImporto(): void {
    const fc: FormControl = <FormControl>this.generals.get('importo');
    this.us.correctFieldWithPaddedNumber(fc, 'importo', this.generals);
  }

  popNotification(data: FormArray, index: number): void {
    data.removeAt(index);
  }

  initializeInstallments(): FormGroup[] {
    const totalImport: number = +(this.installmentsInput.nativeElement.value);
    const array = this.buildArrayForInstallments(totalImport);
    return array;
  }

  buildArrayForInstallments(totalImport: number): any[] {
    const array = [];
    let iterator = 0;
    const installments = this.installmentsInput.nativeElement.value;
    for (iterator = 0; iterator < installments; iterator++) {
      const newInstallment = this.createInstallment(totalImport, installments);
      array.push(newInstallment);
    }
    return array;
  }

  createInstallment(totalImport: number, nInstallments: number): FormGroup {
    const part: number = Math.round((totalImport / nInstallments) * 100) / 100;

    return this.fb.group({
      importoRata: part,
      scadenza: new Date()
    });
  }

  submit(): void {

    const fatturaElettronicaValue: {FatturaElettronicaBody: FatturaElettronicaBody[]} =
    this.invoiceForm.value;
    if (fatturaElettronicaValue.FatturaElettronicaBody) {
      delete(fatturaElettronicaValue.FatturaElettronicaBody[0]['prezzoLordoTotale']);
      delete(fatturaElettronicaValue.FatturaElettronicaBody[0]['prezzoNettoTotale']);
      delete(fatturaElettronicaValue.FatturaElettronicaBody[0]['totaleIVA']);
    }
    const notification: IFFlash = {};
    const values = this.generals.value;
    const newCredit: Credit = {
      riferimento: values.riferimento,
      data_emissione: values.data_emissione,
      data_scadenza: values.data_scadenza,
      importo: values.importo,
      rateizzato: false,
      stato: true,
      periodo_verifica: values.periodo_verifica,
      // pagato: false,
      // timestamp: new Date(),
      // ownerId: '',
      clienteId: values.clienteId,
      tipodocumento: values.tipodocumento,
      statopagamentoId: values.statopagamentoId,
      versamenti: values.versamenti,
      xml: this.us.recursivelyStripToxicFields(
        fatturaElettronicaValue,
        ['prezzoLordoTotale', 'prezzoNettoTotale', 'totaleIVA']
      )
    };

    this.us.recursivelyStripToxicFields(newCredit, []);

    this.creditsService.create(newCredit)
    .subscribe((cr: Credit) => {
      this.ns.emitOKNotification('Credito Creato');
      this.creditCreated.emit(true);
      this.router.navigate([`${DASHBOARD_KEYWORD}/${CREDITS_KEYWORD_URL}/${cr.id}`]);
    }, err => {
      this.ns.emitKONotification('Errore');
    });
  }


  get riferimentoCredito(): string {
    return this.generals.get('riferimento').value;
  }

  /**
   * Questo metodo calcola quando sarà il periodo di
   * scadenza di un determinato cliente, tenendo conto di
   * periodo di scadenza del cliente.
   * @param generalsForm l'istanza del form
   */
  calculateExpirationDate(generalsForm: FormGroup, clientId?: string): void {
    const values = generalsForm.value;
    const date: Date = values.data_emissione;
    if (!clientId) {
      clientId = values.clienteId;
    }
    const periodo_verifica = values.periodo_verifica;
    if ((date) && (clientId)) {

      this.creditsService.calculateExpirationDate(date, clientId, periodo_verifica)
      .subscribe((response: any) => {
        const dataScadenza: Date = new Date(response.data_scadenza);
        generalsForm.patchValue({data_scadenza: dataScadenza} ,{onlySelf: true, emitEvent: false});
      });
    }
  }

  patchStopNotifiche(stop_notifiche: boolean): void {
    this.generals.patchValue({
      stop_notifiche
    });
  }

  /**
   * Quando dall'if-invoice arriva la conferma o meno del fatto che il credito
   * deve avere la fattura elettronica in allegato.
   * @param $event vero/falso, per capire se il form deve passare o meno
   */
  invoiceFormIsRequired($event: boolean): void {
    this.invoiceForm.clearValidators();
    this.invoiceForm.get('FatturaElettronicaBody').clearValidators();
    const fc: FormControl = <FormControl>this.invoiceForm.get('FatturaElettronicaBody');
    $event ? fc.setValidators(Validators.required) : fc.clearValidators();
    fc.updateValueAndValidity();
  }

  get dataScadenza(): Date {
    return this.generals.value.data_scadenza;
  }

}
