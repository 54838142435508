
<section>
  <h2>
      LISTA COMPONENTI
  </h2>
  <mat-card>
    <h2>Bottoni</h2>
    <button class="if-btn">Bottone</button>
    <button class="if-btn btn-dark">Bottone</button>
    <button class="if-btn btn-grey">Bottone</button>
  </mat-card>

  <mat-card>
    <h2>UTENTI</h2>
    <mat-card-content>
      <app-if-credit-form></app-if-credit-form>
    </mat-card-content>
  </mat-card>
</section>
