<div class="img-cont">
  <img src="assets/if-logo.png" />
</div>

<h4>
  Confermi di voler eseguire il logout?
</h4>

<hr>
<div class="img-cont">

  <button
  (click)="clickBtn(false)"
  mat-raised-button color="secondary">
    Annulla
  </button>
  <button
  (click)="clickBtn(true)"
  mat-raised-button color="primary">
    Confermo
  </button>
</div>
