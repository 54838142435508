import { Component, Input, Output, EventEmitter, OnInit, } from '@angular/core';

@Component({
  selector: 'app-if-file-row',
  templateUrl: './if-file-row.component.html',
  styleUrls: ['if-file-row.component.scss']
})
export class IFFileRowComponent implements OnInit {
  @Input('label') label: string;
  @Input('value') value: string;
  @Input('type') type: 'text'|'icon';
  @Input('editing') editing: boolean;
  @Output() actionEmitted = new EventEmitter<boolean>();
  @Output() deleteEmitted = new EventEmitter<string>();

  isBool: boolean;

  constructor() {
    this.type = this.type ? this.type : 'text';
  }

  ngOnInit() {
    this.isBool = typeof this.value === 'boolean';
  }

  emit(): void {
    this.actionEmitted.emit(true);
  }

  delete(value: string): void {
    const sure: boolean = confirm('Sicuro di voler eliminare questo contatto?');
    if (sure) {
      this.deleteEmitted.emit(value);
    }
  }
}
