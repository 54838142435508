/**
 * @license
 * Copyright SMART SRL ®. Tutti i diritti riservati
 *
 * @author
 * Leonardo Maria Miliacca
 *
 * L'uso di questo codice è soggetto a copyright
 */
import { ClientsService } from '../../../services/clients.service';
import { FlashService } from '../../../services/notifications.service';
import { TipoNotifica } from 'src/app/interfaces/tipo_notifica';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MessagesService } from '../../../services/messages.service';
import { IFContactsPlatforms } from 'src/app/interfaces/client_contact';
import { IFFormBlockInput } from 'src/app/interfaces/if_form_block_input';
import { GeoService } from 'src/app/services/geo.service';
import { CreditsService } from 'src/app/services/credits.service';
// import * as numeral from 'numeral';

@Component({
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  @ViewChild('uploadInput', {static: true}) uploadInput: ElementRef;

  modalitaPagamento: {label: string, value: string}[];

  HerePlatform: any;
  searchResults: any;
  IFContactsPlatforms = IFContactsPlatforms;

  inputForm: IFFormBlockInput;

  loading = true;

  DATAS = {
    pagaFacileEdit: false,
    anagrafiche: true, sede: false, fiscali: false, contacts: false, invoice: false};


  contactsForm: FormGroup;
  notificationsTypes: TipoNotifica[];

  constructor(
    private fb: FormBuilder,
    private ns: FlashService,
    public clientsService: ClientsService,
    public crs: CreditsService,
    private ms: MessagesService,
    private fs: FlashService,
    public geo: GeoService
  ) {
    this.contactsForm = this.fb.group({
      tiponotificaId: [Validators.required],
      valore: this.fb.group({
        principale: [],
        secondario: []
      })
    });

  }

  get currentNotificationType(): string {
    const value: string = this.contactsForm.get('tiponotificaId').value;
    if (this.notificationsTypes) {
      const nt: TipoNotifica = this.notificationsTypes.find((tn: TipoNotifica) => {
        return tn.id === value;
      });
      return nt ? nt.nome : '';
    } else {
      return '';
    }
  }

  ngOnInit(): void {

    if (this.crs.modalitaPagamento) {
      this.modalitaPagamento = Object.keys(this.crs.modalitaPagamento).map((key) => {
        return {label: this.crs.modalitaPagamento[key], value: key};
      });
    } else {
      this.crs.modalitaPagamento$.subscribe((modalitaOk) => {
        this.modalitaPagamento = Object.keys(modalitaOk).map((key) => {
          return {label: this.crs.modalitaPagamento[key], value: key};
        });
      });
      this.crs.setModalitaPagamento();
    }

    if (this.ms.notificationsTypes) {
      this.notificationsTypes = this.ms.notificationsTypes;
    } else {
      this.ms.getNotificationsTypes().subscribe((ntss: TipoNotifica[]) => {
        this.notificationsTypes = ntss;
      });
    }



  }

  submit(): void {
    this.ns.emitInfoNotification('Profilo salvato!');
  }


}
