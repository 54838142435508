export enum LookupModels {
  AlCassa = 'AlCassa',
  AlboProfessionale = 'AlboProfessionale',
  AliquotaIVA = 'AliquotaIVA',
  AliquotaRitenuta = 'AliquotaRitenuta',
  CondizioniPagamento = 'CondizioniPagamento',
  EsigibilitaIVA = 'EsigibilitaIVA',
  ModalitaPagamento = 'ModalitaPagamento',
  Natura = 'Natura',
  Nazioni = 'Nazioni',
  Province = 'Province',
  RegimeFiscale = 'RegimeFiscale',
  SocioUnico = 'SocioUnico',
  StatoLiquidazione = 'StatoLiquidazione',
  TipoCassa = 'TipoCassa',
  TipoDocumento = 'TipoDocumento',
  TipologiaFattura = 'TipologiaFattura'
}
