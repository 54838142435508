import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<any>, next: HttpHandler ): Observable<HttpEvent<any>> {

    request = request.clone( {
        setHeaders: {
            ifauth: `${localStorage.getItem('ifauth')}`
        }
    } );

    return next.handle( request ).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
          switch (event.status) {
            case 401:
              window.location.href = '/';
            break;
          }
      }
      return event;
  }));
}

}
