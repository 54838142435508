<h2>Aggiungi Cliente</h2>
<mat-horizontal-stepper [linear]="true" #stepper color="accent">


  <mat-step [stepControl]="anagraphics">
    <form [formGroup]="anagraphics">
      <ng-template matStepLabel>ANAGRAFICA</ng-template>
        <app-if-form-block
        #clientFormBlock
        [inputForm]="{formGroup: anagraphics, type: IFFormBlockInputTypes.anagrafica}"
        (formIsValid)="enableOrDisableNextButton($event)"
        ></app-if-form-block>

      <div class="btn-container">
        <button
          class="if-btn btn-dark"
          [disabled]="!anagraphicsIsValid"
          mat-button
          matStepperNext
        >Avanti</button>
      </div>

    </form>
  </mat-step>

  <mat-step>
      <form [formGroup]="contactsForm">
        <ng-template matStepLabel>CONTATTI</ng-template>

        <div class="contacts-container" *ngIf="anagraphics.valid">
          <div>
            <mat-select
            placeholder="Tipo di contatto"
            formControlName="tiponotificaId"
            >
              <mat-option
              *ngFor="let nt of notificationsTypes"
              [value]="nt.id"
              >
              {{nt.nome}}
              </mat-option>
            </mat-select>
          </div>
          <div>
            <ng-container
            formGroupName="valore"
            >
            <mat-form-field>

              <input
              placeholder="Valore contatto"
              matInput formControlName="principale"/>
            </mat-form-field>
            </ng-container
              >
          </div>
          <div>
            <button
            [disabled]="contactsForm.invalid"
            (click)="newContact()"
            class="if-btn btn-dark">
              <mat-icon>add</mat-icon>
            </button>
          </div>
        </div>
        <div class="contacts-container">




        <app-if-file-row
            [editing]="true"
            *ngFor="let contact of clientContacts"
            (deleteEmitted)="removeContact($event)"
            [type]="'icon'"
            [label]="IFContactsPlatforms[contact| friendlyContact : notificationsTypes]"
            [value]="contact.valore.principale">

          </app-if-file-row>
        </div>



        <div class="btns-container">
          <div class="btn-container">
            <button
              class="if-btn btn-dark"
              mat-button
              matStepperPrevious
            >Indietro</button>
          </div>
          <div class="btn-container">
            <button
            [disabled]="clientContacts.length < 1"
              class="if-btn btn-dark"
              mat-button
              matStepperNext
            >Avanti</button>
          </div>
        </div>
      </form>
    </mat-step>


  <mat-step [stepControl]="paymentsForm">
    <form [formGroup]="paymentsForm">
      <ng-template matStepLabel>PAGAMENTI</ng-template>

      <mat-form-field>
        <mat-select
        formControlName="tipiterminipagamentoId"
        placeholder="Termini di Pagamento*">
          <mat-option *ngFor="let term of paymentsTerms" [value]="term.id">
            {{ term.nome }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div class="inline-form-block">
        Il periodo di verifica per {{anagraphics.get('Denominazione').value}} è impostato a
        <mat-form-field class="inline-form-field" style="width:2rem">
          <input
            #installmentsInput
            matInput
            formControlName="periodoScadenzaBase"
            type="number"
          >
        </mat-form-field>
        giorni
      </div>

      <div class="btns-container">
        <div class="btn-container">
          <button
            class="if-btn btn-dark"
            mat-button
            matStepperPrevious
          >Indietro</button>
        </div>
        <div class="btn-container">
          <button
            class="if-btn btn-dark"
            [disabled]="!paymentsForm.valid"
            mat-button
            matStepperNext
          >Avanti</button>
        </div>
      </div>

    </form>
  </mat-step>

  <mat-step [stepControl]="notificationsForm">
    <form [formGroup]="notificationsForm">
      <ng-template matStepLabel>NOTIFICHE</ng-template>

      <mat-accordion>
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
              EMAIL
            </mat-panel-title>
            <mat-panel-description>
              Gestisci le notifiche per email
            </mat-panel-description>
          </mat-expansion-panel-header>

          <mat-slide-toggle
          [checked]="true"
          (change)="toggleEnableFor($event, emailsData)"
          color="primary">Abilita</mat-slide-toggle>

          <div formArrayName="emails"
          *ngFor="let item of emailsData.controls; let i = index;">
            <div
              class="form-array"
              [formGroupName]="i"
            >
              <mat-form-field *ngIf="modelsByMean.email">
                <mat-select placeholder="Modello">
                  <mat-option *ngFor="let model of modelsByMean.email" [value]="model.id">
                    {{model.titolo}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field>
              <input
                matInput
                placeholder="Giorni Scadenza"
                formControlName="ggScadenza"
                type="number"
                min="1">
              </mat-form-field>
              <div class="remove">
                <div class="remove-wrapper">
                  <mat-icon (click)="removeNotification(notificationsForm.get('emails'), i)">delete</mat-icon>
                  <span (click)="removeNotification(notificationsForm.get('emails'), i)"></span>
                </div>

              </div>
            </div>
          </div>
          <br />
          <span
           *ngIf="emailsData.controls.length > 0"
          (click)="addNotification(emailsData, 'Avviso')"
          class="add">+ Aggiungi</span>

        </mat-expansion-panel>
        <mat-expansion-panel (opened)="panelOpenState = true"
                              (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              SMS
            </mat-panel-title>
            <mat-panel-description>
              Gestisci le notifiche tramite SMS
            </mat-panel-description>
          </mat-expansion-panel-header>
          <mat-slide-toggle
          [checked]="false"
          (change)="toggleEnableFor($event, smsData)"
          color="primary">Abilita</mat-slide-toggle>

          <div formArrayName="sms"
          *ngFor="let item of smsData.controls; let i = index;">
            <div
              class="form-array"
              [formGroupName]="i"
            >
              <mat-form-field>
                <mat-select placeholder="Modello">
                  <mat-option *ngFor="let model of modelsByMean.sms" [value]="model.id">
                    {{model.titolo}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field>
              <input
                matInput
                placeholder="Giorni Scadenza"
                formControlName="ggScadenza"
                type="number"
                min="1">
              </mat-form-field>
              <div class="remove">
                <div class="remove-wrapper">
                  <mat-icon (click)="removeNotification(notificationsForm.get('sms'), i)">delete</mat-icon>
                  <span (click)="removeNotification(notificationsForm.get('sms'), i)">
                  </span>
                </div>

              </div>
            </div>
          </div>
          <span
          *ngIf="smsData.controls.length > 0"
          (click)="addNotification(smsData, 'Avviso')"
          class="add">+ Aggiungi</span>
        </mat-expansion-panel>

        <mat-expansion-panel (opened)="panelOpenState = true"
                              (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              PEC
            </mat-panel-title>
            <mat-panel-description>
                Gestisci le notifiche tramite PEC
            </mat-panel-description>
            </mat-expansion-panel-header>

            <mat-slide-toggle
            [checked]="false"
            (change)="toggleEnableFor($event, pecData)"
            color="primary">Scadenze PEC</mat-slide-toggle>
            <div formArrayName="pec"
            *ngFor="let item of pecData.controls; let i = index;">
              <div
                class="form-array"
                [formGroupName]="i"
              >
                <mat-form-field>
                  <mat-select placeholder="Modello">
                    <mat-option *ngFor="let model of modelsByMean.pec" [value]="model.id">
                      {{model.titolo}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field>
                <input
                  matInput
                  placeholder="Giorni Scadenza"
                  formControlName="ggScadenza"
                  type="number"
                  min="1">
                </mat-form-field>
                <div class="remove">
                  <div class="remove-wrapper">
                    <mat-icon (click)="removeNotification(notificationsForm.get('pec'), i)">delete</mat-icon>
                    <span (click)="removeNotification(notificationsForm.get('pec'), i)">
                    </span>
                  </div>

                </div>
              </div>
            </div>

            <span
            *ngIf="pecData.controls.length > 0"
            (click)="addNotification(pecData, 'Avviso')"
            class="add">+ Aggiungi</span>


          </mat-expansion-panel>
        </mat-accordion>

      <hr />
      <div class="btns-container">
        <div class="btn-container">
          <button
          class="if-btn btn-dark"
          mat-button
          matStepperPrevious
          >Indietro</button>
        </div>
        <div class="btn-container">
          <span
          (click)="submitForm()"
          class="if-btn btn-dark">
            INVIA MODULO
          </span>
        </div>
      </div>


    </form>
  </mat-step>


</mat-horizontal-stepper>
