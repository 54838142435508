import { HereApiResult } from './../../interfaces/responses/here_api_results';
import { IFFormBlockInputTypes } from './../../interfaces/if_form_block_input';
import { FormGroup, FormControl } from '@angular/forms';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { IFFormBlockInput } from 'src/app/interfaces/if_form_block_input';
import { GeoService } from 'src/app/services/geo.service';
import { UtilsService } from 'src/app/services/utils.service';
import { IFGeoClass } from 'src/app/base-classes/if-geo-class';

@Component({
  selector: 'app-if-form-block',
  templateUrl: './if-form-block.component.html',
  styleUrls: ['./if-form-block.component.scss']
})
export class IFFormBlockComponent extends IFGeoClass implements OnInit {
  @Input() inputForm: IFFormBlockInput;
  @Output() formIsValid = new EventEmitter<boolean>();
  public PIVA_LABEL = 'IdCodice';
  public CF_LABEL = 'CodiceFiscale';

  physicalPersonPattern = '(^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$|[0-9]{11})';
  societyPattern = '^[0-9]{11}$';

  cfOrPiva = false;

  IFFormBlockInputTypes = IFFormBlockInputTypes;

  defaultNation = 'IT';

  constructor(
    geo: GeoService,
    private us: UtilsService
  ) {
    super(geo);
  }

  get isPhysicalPerson(): boolean {
    return this.inputForm.formGroup.get('persona_fisica').value;
  }

  get switchPattern(): string {
    let pattern: string;
    if (this.isPhysicalPerson) {
      pattern = this.physicalPersonPattern;
    } else {
      pattern = this.societyPattern;
    }
    return pattern;
  }

  ngOnInit() {
    console.log('welcome to input', this.inputForm);
    const fg: FormGroup = this.inputForm.formGroup.get('Sede') as FormGroup;
    fg.get('Nazione').patchValue(this.defaultNation);

    this.inputForm.formGroup.valueChanges.subscribe((values: any) => {
      let valueToEmit: boolean;

      this.checkWhetherPIVAAndCF(this.inputForm.formGroup, null);

      if ((this.cfOrPiva) && (this.inputForm.formGroup.valid)) {
        valueToEmit = true;
      } else {
        valueToEmit = false;
      }
      this.formIsValid.emit(valueToEmit);
    });
  }

  eraseOppositeInfos(value: boolean): void {
    this.inputForm.formGroup.get(value ? 'IdFiscaleIVA' : 'CodiceFiscale').reset();
  }

  /**
   * Questo metodo si assicura che o partita iva o codice fiscale siano presenti.
   * @param anagraphicsForm il form con i valori
   * @param source la fonte del cambiamento valore
   */
  checkWhetherPIVAAndCF(anagraphicsForm: FormGroup, source: 'IdCodice'|'CodiceFiscale'): void {

    const subForm: FormGroup = anagraphicsForm.get('IdFiscaleIVA') as FormGroup;
    const cfCtrl: FormControl = subForm.get('CodiceFiscale') as FormControl;
    const pivaCtrl: FormControl = subForm.get(this.PIVA_LABEL) as FormControl;

    const cfValue: string = cfCtrl.value || '';
    const pivaValue: string = pivaCtrl.value || '';
    console.log('cfValue', cfValue);
    console.log('pivaValue', pivaValue);

    const cfIsValid: boolean = !!cfValue.match(new RegExp(this.physicalPersonPattern));
    const pivaIsValid: boolean = !!pivaValue.match(new RegExp(this.societyPattern));

    if (pivaIsValid || cfIsValid) {
      this.cfOrPiva = true;
    } else {
      this.cfOrPiva = false;
    }

    const errors = this.us.recursivelySpotErrors(anagraphicsForm);
    console.log(errors);
  }
}
