<div class="flex-row">
  <div class="wrapper">
    <mat-card [formGroup]="sedeForm">
      <mat-card-header>
        <mat-card-title>
          SEDE
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="flex-row" *ngIf="loading">
          <div class="wrapper aligned centered">
            <mat-spinner [strokeWidth]="1" [diameter]="50"></mat-spinner>
          </div>
        </div>

        <div class="flex-row" *ngIf="!loading && error">
          <div class="wrapper aligned centered">
            Errore durante il recupero dati
          </div>
        </div>

        <ng-container *ngIf="!loading && !error">
          <div class="flex-row">
            <div class="wrapper centered aligned">
              <mat-form-field>
                <input
                  placeholder="CAP"
                  matInput
                  required
                  maxlength="5"
                  minlength="5"
                  formControlName="CAP"
                />
                <mat-error *ngIf="sedeForm.get('CAP').errors">
                  <ng-container
                    *ngIf="sedeForm.get('CAP').errors['forbiddenChar']"
                  >
                    Il CAP deve essere numerico
                  </ng-container>
                  <ng-container *ngIf="sedeForm.get('CAP').errors['minlength']">
                    CAP troppo corto
                  </ng-container>
                  <ng-container *ngIf="sedeForm.get('CAP').errors['maxlength']">
                    CAP troppo lungo
                  </ng-container>
                  <ng-container *ngIf="sedeForm.get('CAP').errors['required']">
                    Campo obbligatorio
                  </ng-container>
                </mat-error>
              </mat-form-field>
            </div>

            <div class="wrapper centered aligned">
              <mat-form-field>
                <input
                  matInput
                  required
                  placeholder="Comune"
                  formControlName="Comune"
                  readonly
                  type="text"
                />
                <mat-hint>
                  Campo sola lettura
                </mat-hint>
              </mat-form-field>
            </div>
          </div>
          <div class="flex-row">
            <div class="wrapper centered aligned">
              <mat-form-field>
                <input
                  matInput
                  required
                  placeholder="Provincia"
                  formControlName="Provincia"
                  readonly
                  type="text"
                />
                <mat-hint>
                  Campo sola lettura
                </mat-hint>
              </mat-form-field>
            </div>
            <div class="wrapper centered aligned">
              <mat-form-field>
                <mat-select
                formControlName="Nazione"
                placeholder="Nazione"
                >
                  <mat-option [value]="nation.code" *ngFor="let nation of nations">
                    {{nation.code}} - {{nation.name}}
                  </mat-option>
                </mat-select
                >
              </mat-form-field>
            </div>
          </div>
          <div class="flex-row">
            <div class="wrapper centered aligned">
              <mat-form-field>
                <input
                  placeholder="Indirizzo"
                  formControlName="Indirizzo"
                  matInput
                  required
                  type="text"
                />
              </mat-form-field>
            </div>
            <div class="wrapper centered aligned">
              <mat-form-field>
                <input
                  placeholder="Numero Civico"
                  formControlName="NumeroCivico"
                  matInput
                  required
                  type="text"
                />
              </mat-form-field>
            </div>
          </div>
          <div class="flex-row">
            <div class="wrapper centered aligned">
              <button
                (click)="patchSedeAndSave()"
                [disabled]="sedeForm.invalid"
                class="if-btn btn-dark"
              >
                Salva
              </button>
            </div>
          </div>
        </ng-container>
      </mat-card-content>
    </mat-card>
  </div>

  <div class="wrapper">
    <mat-card [formGroup]="stabileForm">
      <mat-card-header>
        <mat-card-title>
          STABILE ORGANIZZAZIONE
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="flex-row" *ngIf="loading">
          <div class="wrapper aligned centered">
            <mat-spinner [strokeWidth]="1" [diameter]="50"></mat-spinner>
          </div>
        </div>

        <div class="flex-row" *ngIf="!loading && error">
          <div class="wrapper aligned centered">
            Errore durante il recupero dati
          </div>
        </div>

        <ng-container *ngIf="!loading && !error">
          <div class="flex-row">
            <div class="wrapper centered aligned">
              <mat-form-field>
                <input
                  placeholder="CAP"
                  matInput
                  required
                  maxlength="5"
                  minlength="5"
                  formControlName="CAP"
                />
                <mat-error *ngIf="stabileForm.get('CAP').errors">
                  <ng-container
                    *ngIf="stabileForm.get('CAP').errors['forbiddenChar']"
                  >
                    Il CAP deve essere numerico
                  </ng-container>
                  <ng-container
                    *ngIf="stabileForm.get('CAP').errors['minlength']"
                  >
                    CAP troppo corto
                  </ng-container>
                  <ng-container
                    *ngIf="stabileForm.get('CAP').errors['maxlength']"
                  >
                    CAP troppo lungo
                  </ng-container>
                  <ng-container
                    *ngIf="stabileForm.get('CAP').errors['required']"
                  >
                    Campo obbligatorio
                  </ng-container>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="wrapper centered aligned">
              <mat-form-field>
                <input
                  matInput
                  required
                  placeholder="Comune"
                  formControlName="Comune"
                  readonly
                  type="text"
                />
                <mat-hint>
                  Campo sola lettura
                </mat-hint>
              </mat-form-field>
            </div>
          </div>
          <div class="flex-row">
            <div class="wrapper centered aligned">
              <mat-form-field>
                <input
                  matInput
                  required
                  placeholder="Provincia"
                  formControlName="Provincia"
                  readonly
                  type="text"
                />
                <mat-hint>
                  Campo sola lettura
                </mat-hint>
              </mat-form-field>
            </div>
            <div class="wrapper centered aligned">
              <mat-form-field>
                <mat-select
                formControlName="Nazione"
                placeholder="Nazione"
                >
                  <mat-option [value]="nation.code" *ngFor="let nation of nations">
                    {{nation.code}} - {{nation.name}}
                  </mat-option>
                </mat-select
                >
              </mat-form-field>
            </div>
          </div>
          <div class="flex-row">
            <div class="wrapper centered aligned">
              <mat-form-field>
                <input
                  placeholder="Indirizzo"
                  formControlName="Indirizzo"
                  matInput
                  required
                  type="text"
                />
              </mat-form-field>
            </div>
            <div class="wrapper centered aligned">
              <mat-form-field>
                <input
                  placeholder="Numero Civico"
                  formControlName="NumeroCivico"
                  matInput
                  required
                  type="text"
                />
              </mat-form-field>
            </div>
          </div>
          <div class="flex-row">
            <div class="wrapper centered aligned">
              <button
                (click)="patchStabileAndSave()"
                [disabled]="stabileForm.invalid"
                class="if-btn btn-dark"
              >
                Salva
              </button>
            </div>
          </div>
        </ng-container>
      </mat-card-content>
    </mat-card>
  </div>
</div>
