import { NgModule } from '@angular/core';
import { IFDateRowComponent } from './if-date-row.component';
import { MatCardModule } from '@angular/material/card';
import { CommonModule } from '@angular/common';
import { IFPipesModule } from 'src/app/pipes/if-pipes.module';

@NgModule({
  imports: [
    IFPipesModule,
    CommonModule,
    MatCardModule
  ],
  declarations: [
    IFDateRowComponent,
  ],
  exports: [
    IFDateRowComponent
  ]
})
export class IFDateRowModule {}
