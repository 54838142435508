/**
 * @license
 * Copyright SMART SRL ®. Tutti i diritti riservati
 *
 * @author
 * Leonardo Maria Miliacca
 *
 * L'uso di questo codice è soggetto a copyright
 */
import { Pipe, PipeTransform } from '@angular/core';
import { ClientContact } from '../interfaces/client_contact';
import { TipoNotifica } from '../interfaces/tipo_notifica';

@Pipe({name: 'friendlyContact'})
export class FriendlyContactPipe implements PipeTransform {
  transform(contact: ClientContact, notificationTypes: TipoNotifica[]): string {
    const nt = notificationTypes.find((nt: TipoNotifica) => {
      return contact.tiponotificaId === nt.id;
    });
    return nt ? nt.nome : '';
  }
}
