import { NgModule } from '@angular/core';
import { IFFormArrayComponent } from './if-form-array.component';
import { CommonModule } from '@angular/common';
import { IFComponentsModule } from 'src/app/modules/if-components/if-components.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [IFFormArrayComponent],
  imports: [
    CommonModule,
    IFComponentsModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [IFFormArrayComponent]
})
export class IFFormArrayModule {}
