import { DashboardStat } from './../../../../interfaces/dashboard_stat';
/**
 * @license
 * Copyright SMART SRL ®. Tutti i diritti riservati
 *
 * @author
 * Leonardo Maria Miliacca
 *
 * L'uso di questo codice è soggetto a copyright
 */
import { IFProfile } from '../../../../interfaces/if_profile';
import { FlashService } from '../../../../services/notifications.service';
import { CreditsService } from '../../../../services/credits.service';
import { ClientsService } from '../../../../services/clients.service';
import {
  DASHBOARD_KEYWORD,
  CLIENTS_KEYWORD_URL,
  CREDITS_KEYWORD_URL,
  KEYWORDS_URL,
  CONTRACTS_KEYWORD_URL,
  MARKETPLACE_KEYWORD_URL,
  SEARCH_KEYWORD_URL
} from '../../../../constants/keywords';
import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { IFFlash, IFFlashType } from '../../../../interfaces/if-notification';
import { ModalManagerService } from 'src/app/services/modal-manager.service';
import { StatoPagamentiToken } from '../../../../constants/payment_statuses';
import { SMSCountResponse } from '../../../../interfaces/responses/sms_count_response';
import { XMLInvoice } from 'src/app/interfaces/credit';
import { InvoiceService } from 'src/app/services/invoice.service';
import { MessagesService } from 'src/app/services/messages.service';
import { version } from '../../../../../../package.json';
// import * as numeral from 'numeral';

@Component({
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {

  keywords = KEYWORDS_URL;

  statoPagamentiToken = StatoPagamentiToken;
  showMenu = false;
  creating = false;
  modelSelected = 'clients';

  me: IFProfile;
  UIBGColor: string;

  dashboardStats: DashboardStat[];
  managedCredits: DashboardStat;
  unpaidCredits: DashboardStat;
  inVerificationCredits: DashboardStat;
  smsData: DashboardStat;
  emailsData: DashboardStat;
  // alertsData: DashboardStat;

  closing = false;

  VERSION = version;

  CURRENT_YEAR: number = new Date().getFullYear();

  restrictedMenu = false;

  constructor(
    public router: Router,
    private creditsService: CreditsService,
    public clientsService: ClientsService,
    private ns: FlashService,
    private mms: ModalManagerService,
    private is: InvoiceService,
    private ms: MessagesService
  ) {

    console.log('%c ☢️️☢️☢️', 'font-size:3rem;width:100%; text-align:center; ');
    console.log('%c Attenzione', 'width:100%; text-align:center; background: indianred; color: white; font-size:3rem');
    console.log('%c ☢️️☢️☢️', 'font-size:3rem; width:100%; text-align:center; ');
    console.log(
      '%c Noi di SmartSRL ci auguriamo che tu sappia cosa stia facendo, le azioni di reverse engineering sono vietate',
      'color: indianred'
    );

    this.is.currentlySelectedInvoice$.subscribe((xml: XMLInvoice|null) => {
      if (xml) {
        this.openMenu();
        this.modelSelected = this.keywords.editInvoice;
      } else {
        this.closeModal(true);
      }

    });

    this.UIBGColor = this.getRandomColor();
    this.clientsService.myDataObs$
    .subscribe((ds: DashboardStat[]) => {


      this.managedCredits = ds.find((d: DashboardStat) => d.widget === 'managed_credits');
      this.unpaidCredits = ds.find((d: DashboardStat) => d.widget === 'unpaid_credits');
      this.smsData = ds.find((d: DashboardStat) => d.widget === 'sms');
      this.emailsData = ds.find((d: DashboardStat) => d.widget === 'email');

      this.updateNumber(this.managedCredits, (this.managedCredits ? this.managedCredits.data.value : 0));
      this.updateNumber(this.unpaidCredits, (this.unpaidCredits ? this.unpaidCredits.data.value : 0));
      this.updateNumber(this.smsData, (this.smsData ? this.smsData.data.value : 0));
      this.updateNumber(this.emailsData, (this.emailsData ? this.emailsData.data.value : 0));
    });

    this.mms.modelSubject$.subscribe((modelToShow: string) => {
      this.create(modelToShow);
    });
  }

  timeoutCreating(): void {
    this.closing = true;
    setTimeout(() => {
      this.closing = false;
      this.creating = false;
    }, 1000);
  }

  openMenu(): void {
    this.creating = true;
    this.showMenu = false;
  }

  getState(outlet) {
    return outlet.activatedRouteData.state;
  }

  updateNumber(dataBlock: DashboardStat, newQty: number): void {
    if (dataBlock) {
      dataBlock.data.value = newQty;
      dataBlock.updating = true;
      setTimeout(() => {
        dataBlock.updating = false;
      }, 1000);
    }
  }

  create(model: string): void {
    this.showMenu = false;
    this.creating = true;
    this.modelSelected = model;
    this.clientsService.setCurrentlySelectedClient(null);
  }

  closeXMLModal($event: boolean) {
    this.closeAllModals($event);
  }

  closeModal($event: boolean): void {
    this.closeAllModals($event);
  }

  closeAllModals(bool: boolean) {
    if (bool) {
      this.showMenu = false;
      this.creating = false;
    }
  }

  setActiveFilterAndGoToCredits(creditCategoria: string): void {
    this.creditsService.setActiveFilter(creditCategoria);
    this.router.navigate([`${this.keywords.dashboard}/${this.keywords.credits}/`]);
  }

  makeid(): string {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < 5; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
  }

  getRandomArbitrary(): IFFlashType {
    const randN: number = Math.floor(Math.random() * (3 - 0 + 1)) + 0 ;
    let n: IFFlashType;
    switch (randN) {
      case 0:
        n = IFFlashType.check_circle_outline;
      break;
      case 1:
        n = IFFlashType.error;
      break;
      case 2:
        n = IFFlashType.warning;
      break;
      case 3:
        n = IFFlashType.info;
      break;
    }
    return n;
  }


  addNotification(): void {
    const n: IFFlash = {
      text: 'SONO UNA NOTIFICA',
      type: this.getRandomArbitrary(),
      id: this.makeid(),
      time: null
    };
    this.ns.emitNotification(n);
  }

  ngOnInit(): void {
    // this.creating = true;
    // this.modelSelected = this.keywords.clients;
    this.creditsService.setTermsPaymentTypes();
    this.creditsService.setPaymentsStatuses();
    this.ms.setNotificationsTypes();

    this.clientsService.loadMe()
    .subscribe((me: IFProfile[]) => {
      console.log('ME RECEIVED', me);
      this.me = me[0];
      this.setUIIcon(this.me);
      this.clientsService.setMe(this.me);

      this.clientsService.checkGDPRAdempiency(this.me);

      this.clientsService.meObservable$
      .subscribe((newMe: IFProfile) => {
        this.me = newMe;
        this.setUIIcon(this.me);
      });
    }, err => {
      // TODO handle notification
    });

    this.creditsService.getDataForChartsInDashboard()
    .subscribe((response: DashboardStat[]) => {
      this.clientsService.updateStats(response);
    });

    this.clientsService.currentlySelectedClient$.subscribe((obj: any) => {
      this.openMenu();
      if (obj) {
        if (obj.hasOwnProperty('persona_fisica')) {
          // è un cliente
          this.modelSelected = this.keywords.credits;
        }
      }

    });
  }

  get myName(): string {
    return this.me.persona_fisica ?
    `${this.me.CedentePrestatore.DatiAnagrafici.Anagrafica.Nome} ${this.me.CedentePrestatore.DatiAnagrafici.Anagrafica.Cognome}` :
    this.me.CedentePrestatore.DatiAnagrafici.Anagrafica.Denominazione;
  }

  setUIIcon(me: IFProfile): void {
    if (me) {
      me.UIName = me.persona_fisica ?
      `${me.CedentePrestatore.DatiAnagrafici.Anagrafica.Nome} ${me.CedentePrestatore.DatiAnagrafici.Anagrafica.Nome}` :
      me.CedentePrestatore.DatiAnagrafici.Anagrafica.Denominazione;
    }
  }

  getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  transactionDetected(): void {
    console.log('TRANSACTION DETECTED');
    this.modelSelected = null;
    this.creating = false;
    this.smsData.updating = true;
    this.creditsService.getDataForChartsInDashboard()
    .subscribe((response: DashboardStat[]) => {
      this.clientsService.updateStats(response);
    });
  }

  updateSmsCount(smsData: DashboardStat, response: SMSCountResponse): void {
    const currentValue = smsData.data.value;
    const valueToReach = response.sms;
    if ((valueToReach > 0) && (valueToReach !== currentValue)) {
      this.smsData.updating = true;
      this.animateValue(currentValue, valueToReach, 3000, this.smsData);
    }
    setTimeout(() => {
      this.smsData.updating = false;
    }, 1500);
  }

  animateValue(start: number, end: number, duration: number, smsData: DashboardStat) {
    const range = end - start;
    let current = start;
    const increment = end > start ? 1 : -1;
    const stepTime = Math.abs(Math.floor(duration / range));
    const timer = setInterval(function() {
        current += increment;
        smsData.data.value = current;
        if (current === end) {
            clearInterval(timer);
        }
    }, stepTime);
  }


}
