import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
  name: 'safeAutocomplete'
})
export class SafeAutocompletePipe implements PipeTransform {
  transform(value: any): string {
    if (value) {
      return value;
    } else {
      return '';
    }
  }
}
