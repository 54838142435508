import { NgModule } from '@angular/core';
import { IFCreditFormComponent } from './if-credit-form.component';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { IFInvoiceModule } from '../if-invoice/if-invoice.module';
import { MatStepperModule } from '@angular/material/stepper';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { IFSendNotificationModule } from '../if-send-notification/if-send-notification.module';
import { CreditNotificationsModule } from '../credit-notifications/credit-notifications.module';
import { RouterModule } from '@angular/router';
import { SafeAutocompletePipe } from './safe-autocomplete.pipe';
import { IFPipesModule } from 'src/app/pipes/if-pipes.module';

@NgModule({
  declarations: [
    IFCreditFormComponent,
    SafeAutocompletePipe
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatCardModule,
    CreditNotificationsModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    IFPipesModule,
    IFInvoiceModule,
    RouterModule,
    MatStepperModule,
    MatIconModule,
    MatDatepickerModule,
    MatAutocompleteModule
  ],
  providers: [
  ],
  exports: [IFCreditFormComponent]
})
export class IFCreditFormModule {}
