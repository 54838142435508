/**
 * @license
 * Copyright SMART SRL ®. Tutti i diritti riservati
 *
 * @author Leonardo Maria Miliacca
 *
 * L'uso di questo codice è soggetto a copyright.
 * In diversi casi faccio uso di una modale (es: crea cliente, credito).
 * Questo è il service che gestisce le fasi di vita del componente e
 * le comunicazioni fra componenti.
 */
import { TipoNotifica } from '../interfaces/tipo_notifica';
import { KEYWORDS_URL } from './../constants/keywords';
import { API_DOMAIN, API_VERSION } from '../constants/server';
import { Injectable } from '@angular/core';
import { Subject } from '../../../node_modules/rxjs';
import { Modello } from 'src/app/interfaces/modello_messaggio';
import { IFValuePatcher } from '../interfaces/value_patcher';

@Injectable()
export class ModalManagerService {

  private _modelSubject = new Subject<string>();
  public modelSubject$ = this._modelSubject.asObservable();
  public modelSubject: string;

  public valuePatcher: IFValuePatcher = {
    client: null,
    model: null,
    notificationType: null
  };
  public optionalValue: string;

  selectedVoice: TipoNotifica | any;

  keywords = KEYWORDS_URL;

  constructor(
  ) {}

  /**
   * Fa comparire un modal e opzionalmente patcha già l'eventuale form che
   * viene rappresentato nella modale.
   * @param modelToShow cosa mostrare: clienti, crediti etc
   * @param selectedVoice se c'è un form da patchare, è qui che viene determinato
   */
  setNewModel(modelToShow: string, selectedVoice?: TipoNotifica|Modello): void {
    this.selectedVoice = selectedVoice ? selectedVoice : null;
    this.modelSubject = modelToShow;
    this._modelSubject.next(modelToShow);
  }

  /**
   * Simile al metodo precedente. Gestisce dati aggiuntivi.
   * @param modelToShow cosa mostrare: clienti, crediti etc
   * @param valuePatcher
   * @param optionalValue valori opzionali
   */
  openPopupAndPatchValue(modelToShow: string, valuePatcher: IFValuePatcher, optionalValue?: string): void {
    this.modelSubject = modelToShow;
    Object.assign(this.valuePatcher, valuePatcher);
    this._modelSubject.next(modelToShow);
    this.optionalValue = optionalValue ? optionalValue : null;
  }

  /**
   * Dato che il `valuePatcher` può essere utilizzato in diversi contesti,
   * questo metodo lo resetta.
   */
  resetValuePatcher(): void {
    this.modelSubject = null;
    this.valuePatcher = {};
    this.optionalValue = null;
  }


}

