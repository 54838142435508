import { Chart } from 'angular-highcharts';
import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { DashboardBarStat, DashboardData } from 'src/app/interfaces/dashboard_stat';
import { Series, TooltipFormatterContextObject } from 'highcharts';
import { StatoPagamentiToken, StatoPagamentiLabels } from 'src/app/constants/payment_statuses';
import { FriendlyNumberPipe } from 'src/app/pipes/friendly-number.pipe';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'aggregated-months',
  templateUrl: './aggregated-months.component.html',
  styleUrls: ['./aggregated-months.component.scss']
})
export class AggregatedMonthsComponent implements OnInit, OnChanges{
  /**
   * I dati che arrivano dal server, hanno una chiave `data` che contiene oggetti di varia natura.
   */
  @Input() dataFromApi: DashboardBarStat;

  /**
   * La variabile che definisce l'oggetto di tipo {@type {Chart} Chart}
   */
  chart: Chart;

  init = false;

  constructor() {}

  ngOnInit(): void {
    this.processData(this.dataFromApi);
    this.init = true;
  }


  ngOnChanges(): void {
    if (this.init) {
      console.warn('triggered onchange!');
      this.processData(this.dataFromApi);
    }
  }

  /**
   * Quando ricevo i dati dal server, questo metodo si occupa di creare le `categories` e le `series`
   * di Highcharts.
   * @param {DashboardBarStat} dataFromApi i dati dalle API.
   */
  processData(dataFromApi: DashboardBarStat): void {
    const categories: string[] = this.createCategories(dataFromApi);
    const series: Series[] = this.buildSeries(dataFromApi, categories);
    this.buildChart(categories, series);
  }

  /**
   * Questo metodo costruire oggetti da utilizzare nel chart, questo sotto è un modo di crearli,
   * non è l'unico ma per questo grafico specifico vanno bene così
   * @see createCategories
   * @param {DashboardBarStat} dataFromApi i dati da server
   * @param {string[]} categories le categorie precedentemente create dal metodo `createCategories`.
   */
  private buildSeries(dataFromApi: DashboardBarStat, categories: string[]): Series[] {
    const series: any[] = [{
      type: null,
      name: StatoPagamentiToken.open,
      data: [],
      color: 'var(--gestit)',
      metaName: 'Gestiti'
    }, {
      type: null,
      name: StatoPagamentiToken.paid,
      data: [],
      color: 'var(--incass)',
      metaName: 'Incassati'
    }, {
      type: null,
      name: StatoPagamentiToken.unpaid,
      data: [],
      color: 'var(--scadu)',
      metaName: 'Scaduti'
    }, {
      type: null,
      name: StatoPagamentiToken.verify,
      data: [],
      color: 'var(--verif)',
      metaName: 'In Verifica'
    }];
    console.log('categories', categories);
    categories.map((c: string) => {
      const splittedCategory = c.split('/');
      series.map((serie) => {
        const spottedRelatedDD: DashboardData = dataFromApi.data[splittedCategory[1]]
        .find((dd: DashboardData) => {
          return dd.statopagamentoId === serie.name && dd.mese === +(splittedCategory[0]);
        });
        console.log('spottedRelatedDD', dataFromApi.data[splittedCategory[1]]);
        if (spottedRelatedDD) {
          serie.data.push(+((spottedRelatedDD.ammontare).toFixed(2)));
        } else {
          serie.data.push(0);
        }

      });

    });
    return series;
  }

  /**
   * Questo metodo costruisce le `categories` di Highcharts, che in questo caso sono i mesi,
   * o per meglio dire l'asse X.
   * @param {DashboardBarStat} dataFromApi i dati dalle API
   * @returns {string[]} un array di stringhe, che nel grafico sono i mesi
   */
  private createCategories(dataFromApi: DashboardBarStat): string[] {
    console.log('dataFromApi', dataFromApi);
    const years: number[] = Object.keys(dataFromApi.data).map(n => +(n));
    years.sort();
    const categories: string[] = [];
    console.log('years', years);
    years.map((year: number) => {
      // prima il primo anno
      dataFromApi.data['' + year]
      .map((dd: DashboardData) => {
        const category = dd.mese + '/' + dd['anno'];
        if (!categories.includes(category)) {
          categories.push(dd.mese + '/' + year);
        }
      });
    });
    return categories;
  }

  /**
   * Questo metodo costruisce il grafico vero e proprio a partire da un oggetto Highcharts valido.
   * Purtroppo le serie sono tipizzate con `any[]` poiché utilizzando i tipi di Highcharts ottengo un errore di tipizzazione dal tslint.
   * @param {string[]} categories le categorie costruite nel metodo createCategories
   * @param {any[]} series le serie costruite nel metodo buildSeries
   */
  buildChart(categories: string[], series: any[]): void {
    console.log('categories', categories);
    console.log('series', series);
    series.map((s) => {
      s.name = StatoPagamentiLabels[s.name];
    });

    this.chart = new Chart({
      credits: {
        enabled: false,
      },
      chart: {
        type: 'area',
        height: '300px',
        backgroundColor: 'transparent',
        style: {
          fontFamily: '"Oswald", sans-serif;'
        }
      },
      title: {
        text: ''
      },
      xAxis: {
        categories,
        title: {
          style: {
            color: '#999'
          }
        }
      },
      yAxis: {
        title: {
          text: '€'
        },
        labels: {
        }
      },
      tooltip: {
        split: true,
        formatter: function() {
          const pipe: FriendlyNumberPipe = new FriendlyNumberPipe();
          console.log('this', this);
          let string = ``;
          this.points.map((p: TooltipFormatterContextObject) => {
            string += `<strong>${p.series['legendItem']['textStr']}</strong>: ${pipe.transform(p.y)}€ <br />`;
          });
          return string;
        },
      },
      plotOptions: {
        area: {
          stacking: 'normal',
          lineColor: '#666666',
          lineWidth: 1,
          marker: {
            lineWidth: 1,
            lineColor: '#666666'
          }
        }
      },
      series
    });
  }
}
