import { XMLErrorDialogComponent } from './xml-error-dialog/xml-error-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Component, Output, EventEmitter, ViewChild, ElementRef, Input, OnInit, OnDestroy, TemplateRef, ContentChild } from '@angular/core';
import { IMPORT_CLIENTS_KEYWORD_URL,
  IMPORT_CREDITS_KEYWORD_URL,
  IMPORT_INVOICES_KEYWORD_URL, KEYWORDS_URL } from 'src/app/constants/keywords';
import { API_DOMAIN, API_VERSION } from 'src/app/constants/server';
import { IFFlashType, IFFlash } from 'src/app/interfaces/if-notification';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { FlashService } from 'src/app/services/notifications.service';
import { HttpClient, HttpEvent } from '@angular/common/http';
import { HttpEventType } from '@angular/common/http';
import { CreditsService } from 'src/app/services/credits.service';
import { ClientsService } from 'src/app/services/clients.service';
import { IFXMLImportResponse } from 'src/app/interfaces/responses/if_xml_import_response';
import { Router } from '@angular/router';
import { IFXMLImportValidationResponse,
  IFXMLImportValidationResponseCodes } from 'src/app/interfaces/responses/if_xml_import_validation_response';
import { Subscription } from 'rxjs';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-if-xml-import',
  templateUrl: './if-xml-import.component.html',
  styleUrls: ['./if-xml-import.component.scss']
})
export class IFXMLImportComponent implements OnInit, OnDestroy {

  @Output() uploadOk: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('uploader', { static: true }) uploader!: ElementRef;
  @Input() typeOfImport: string;

  formData: FormData = new FormData();

  TYPES = {
    [IMPORT_CLIENTS_KEYWORD_URL]: 'Clienti',
    [IMPORT_CREDITS_KEYWORD_URL]: 'Crediti',
    [IMPORT_INVOICES_KEYWORD_URL]: 'Fatture'
  };

  fileTypesAccepted = {
    [IMPORT_INVOICES_KEYWORD_URL]: '.xml,.zip'
  };

  UPLOAD_DATA = {
    [IMPORT_CLIENTS_KEYWORD_URL]: {
      method: 'POST',
      url: `${API_DOMAIN}/${API_VERSION}/attachments/import/upload`
    },
    [IMPORT_CREDITS_KEYWORD_URL]: {
      method: 'POST',
      url: `${API_DOMAIN}/${API_VERSION}/attachments/import/upload`
    },
    [IMPORT_INVOICES_KEYWORD_URL]: {
      method: 'PUT',
      url: `${API_DOMAIN}/put/xmlcredit/${localStorage.ifauth}`
    }
  };

  KEYWORDS = KEYWORDS_URL;

  msg: {text: string, status: IFFlashType} = {
    text: null,
    status: IFFlashType.info
  };

  loading = false;
  success = false;
  progress = 0;

  uploadForm: FormGroup;

  xmlValue: string;

  error: boolean;
  errorMsg: string;

  uploadingAZip: boolean;

  currentAction: null|'verifica'|'upload'|'processing';

  uploadSub: Subscription;
  resolveUploadSub: Subscription;
  valueChangeSub: Subscription;

  constructor(
    private fs: FlashService,
    private http: HttpClient,
    private fb: FormBuilder,
    private crs: CreditsService,
    private cls: ClientsService,
    private router: Router,
    private d: MatDialog
  ) {

    this.error = false;
    this.uploadForm = this.fb.group({
      separator: [',', [Validators.required, this.noWhitespaceValidator]]
    });

    this.valueChangeSub = this.uploadForm.valueChanges.subscribe((newValues) => {
      this.formData.delete('separator');
    });
  }

  ngOnInit(): void {

  }

  get correctWhiteSpace(): boolean {
    return !!this.uploadForm.get('separator').errors;
  }

  get noFiles(): boolean {
    return !!this.formData.get('xml') || !!this.formData.get('zip');
  }

  get myFile(): string {
    let file: File;
    if (this.formData.get('xml')) {
      file = <File>this.formData.get('xml');
    } else {
      file = <File>this.formData.get('zip');
    }
    return file.name;
  }

  public noWhitespaceValidator(control: FormControl) {
    const hasWhitespace = (control.value.match(/\s/));
    const isValid = !hasWhitespace;
    return isValid ? null : { whitespace: true };
  }

  ngOnDestroy(): void {
    this.uploadSub?.unsubscribe();
    this.resolveUploadSub?.unsubscribe();
    this.valueChangeSub?.unsubscribe();
  }

  startProcess(): void {
    console.log('this', this);
    const file: File = this.uploader.nativeElement.files[0];
    if (file) {
      this.processReceivedFileBothFromInputOrDrop(file);
    }
  }

  /**
   * Quando l'utente immette un file, o per drophandler o per
   * click sull'input, devo gestirli in modo identico.
   * Questo è il metodo che li gestisce.
   * @param file il file che l'utente ha immesso
   */
  processReceivedFileBothFromInputOrDrop(file: File): void {
    const fileType: string = file.type;
    const isZip: boolean = !!fileType.match(new RegExp('zip', 'ig'));
    if (isZip) {
      this.formData.delete('zip');
      this.formData.append('zip', file);
      this.uploadingAZip = true;
    } else {
      this.uploadingAZip = false;
      // è un xml
      this.readTheFileAndAppendValue(file);
    }
  }

  readTheFileAndAppendValue(file: File): void {
    const reader = new FileReader();
    reader.onload = () => {
    this.xmlValue = reader.result.toString();
    };
    reader.readAsText(file);
    this.formData.delete('xml');
    this.formData.append('xml', file);
  }

  dropHandler($event: any): void {
    $event.preventDefault();

    if (this.uploadForm.valid) {
      const file: File = $event.dataTransfer.files[0] as File;
      this.processReceivedFileBothFromInputOrDrop(file);
    }

  }

  allowDrop(ev: any): void {
    if (this.uploadForm.valid) {
      ev.preventDefault();
    }
  }

  /**
   * L'upload vero e proprio dell'xml.
   */
  proceedWithUpload(): void {
    this.currentAction = 'upload';
    this.progress = 0;
    this.resolveUploadSub = this.http.request('PUT', this.UPLOAD_DATA[this.typeOfImport].url, {
      body: {xml: this.xmlValue},
      reportProgress: true,
      observe: 'events'
    })
    .subscribe((event: HttpEvent<Object>) => {
      if (event.type === HttpEventType.Response) {
        const trueresponse: IFXMLImportResponse = event.body as IFXMLImportResponse;
        this.loading = false;
        this.success = true;
        this.fs.emitOKNotification('Upload riuscito');
        this.resolveUpload(null, trueresponse.credito);
      }

      if (event['type'] === HttpEventType.UploadProgress) {
        const percentDone = Math.round(100 * event['loaded'] / event['total']);
        this.progress = percentDone;
      }

    }, err => {
      this.openDialog(err);
      this.loading = false;
      this.success = false;
      this.fs.emitKONotification('Upload non riuscito');
    });
  }

  openDialog(error): void {
    const dialogRef = this.d.open(XMLErrorDialogComponent, {
      width: '250px',
      data: {error}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }



  uploadChosenFile(uploadingAZip: boolean): void {
    this.loading = true;
    if (uploadingAZip) {
      this.uploadZIPFile();
    } else {
      this.uploadXML();
    }

  }

  /**
   * Quando ho un file ZIP, questo è l'handler.
   */
  uploadZIPFile(): void {
    this.currentAction = 'upload';
    this.http.request('PUT', this.UPLOAD_DATA[this.typeOfImport].url,
    {
      observe: 'events',
      body: this.formData,
      reportProgress: true
    })
    .subscribe((event) => {

      if (event['type'] === HttpEventType.UploadProgress) {
        const percentDone = Math.round(100 * event['loaded'] / event['total']);
        this.progress = percentDone;
        if (this.progress === 100) {
          console.log('PROCESSING');
          this.currentAction = 'processing';
        }
      }

      if (event.type === HttpEventType.Response) {
        this.loading = false;
        this.fs.emitOKNotification('Upload riuscito');
        this.formData.delete('zip');
        this.formData.delete('xml');
      }
    }, err => {
      this.currentAction = null;
      this.openDialog(err);
      this.loading = false;
      this.success = false;
      this.fs.emitKONotification('Upload non riuscito');
    });
  }

  /**
   * Quando ho un file XML caricato nella box, è questo il metodo che invoco.
   */
  uploadXML(): void {
    this.proceedWithUpload();
    // this.currentAction = 'verifica';
    // this.uploadSub = this.http.post(`${API_DOMAIN}/verify/xmlcredit/${localStorage.ifauth}`, {
    //   body: {xml: this.xmlValue}
    // }, {
    //   reportProgress: true,
    //   observe: 'events'
    // })
    // .subscribe((response: HttpEvent<Object>) => {
    //   this.processResponse(response);
    // }, error => {
    //   this.currentAction = null;
    //   this.loading = false;
    //   this.fs.emitKONotification('Errore del server');
    // });
  }

  resolveUpload(n: IFFlash, creditId: string): void {
    this.progress = 0;
    if (!n && !creditId) {
      // li sto passando dall'upload di zip risolto
      this.uploadOk.emit(true);
    } else {
      this.uploadOk.emit(true);
      this.router.navigate([`/dashboard/crediti/${creditId}`]);
    }
  }


  /**
   * Quando sto uploadando un file, gestisco il progress in modo univoco.
   * @param response l'evento durante l'upload
   */
  private processResponse(response): void {
    if (response.type === 0) {
      // non fare niente, è l'inizio upload
    } else {
      if (response.type === 1) {
        // upload in corso
        if (response['loaded'] && response['total']) {
          this.progress = Math.floor((response['loaded'] * 100) / response['total']);
        }
      }
    }

    if (response.type === 4) {
      console.log('entro nel true response', response);
      const trueResponse: IFXMLImportValidationResponse = response['body'];
      if (trueResponse.code === IFXMLImportValidationResponseCodes.validXML) {
        this.proceedWithUpload();
      } else {
        this.fs.emitKONotification('XML o ZIP non valido');
      }
    }
  }
}
