import { OnDestroy } from '@angular/core';
/**
 * @license
 * Copyright SMART SRL ®. Tutti i diritti riservati
 *
 * @author
 * Leonardo Maria Miliacca
 *
 * L'uso di questo codice è soggetto a copyright
 */
import { IFProfile } from '../interfaces/if_profile';
import { CLIENTS_KEYWORD, PROFILES_KEYWORD, PRIVACY_KEYWORD_URL } from '../constants/keywords';
import { API_DOMAIN, API_VERSION } from '../constants/server';
import { Client } from '../interfaces/client';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { LoginResponse } from '../interfaces/responses';
import { DashboardStat } from '../interfaces/dashboard_stat';
import { Catalog } from '../interfaces/catalog';
import { UploadLogoResponse } from '../interfaces/responses/upload_logo_response';
import { IFPurchaseResponse } from '../interfaces/purchase_response';
import { SMSCountResponse } from './../interfaces/responses/sms_count_response';
import { IFOrder } from './../interfaces/if_order';
import { WorkersService } from './workers.service';
import { Router } from '@angular/router';
import { LookupModels } from '../interfaces/lookup-models';
import { UtilsService } from './utils.service';
import { map } from 'rxjs/operators';

const V1_URL = `${API_DOMAIN}/${API_VERSION}/${CLIENTS_KEYWORD}`;

@Injectable()
export class ClientsService {

  private _myData = new Subject<DashboardStat[]>();
  public myDataObs$ = this._myData.asObservable();
  public myData: DashboardStat[] = [];

  public token: string;
  public expires: Date;

  public clients: Client[];
  private _clientsSubject = new Subject<Client[]>();
  public _clientsObservable$ = this._clientsSubject.asObservable();

  private myIFAuthToken = new Subject<LoginResponse>();
  public newIFAuth$ = this.myIFAuthToken.asObservable();

  private _me = new Subject<IFProfile>();
  public meObservable$ = this._me.asObservable();
  public me: IFProfile;

  private _currentlySelectedClient = new Subject<Client>();
  public currentlySelectedClient$ = this._currentlySelectedClient.asObservable();
  public currentlySelectedClient: Client;

  constructor(
    private http: HttpClient,
    private ws: WorkersService,
    private router: Router,
    private us: UtilsService
  ) {}

  downloadSituation(clientId: string): Observable<any> {
    const access_token = localStorage.getItem('ifauth');
    console.log('invoke');
    return this.http.get(`${API_DOMAIN}/report/${clientId}`, {
      responseType: 'arraybuffer',
      params: {access_token},
      headers: {
      }
    }).pipe(
      map(data => {
        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        let url = window.URL.createObjectURL(blob);
        let pwa = window.open(url);
        if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
            alert( 'Please disable your Pop-up blocker and try again.');
        }
      })
    )
  }

  /**
   * Invia una PEC di test
   * @param payload solo con campo message
   */
  sendPECTest(payload: {message: string}): Observable<any> {
    return this.http.post<any>(`${API_DOMAIN}/send/test-pec`, payload);
  }

  patchTermsAndHone(id: string, obj: Object): Observable<IFProfile> {
    return this.http.patch<IFProfile>(
      `${API_DOMAIN}/${API_VERSION}/${PROFILES_KEYWORD}/${id}`,
      obj
    );
  }

  /**
   * Dato che ogni risorsa nelle lookup è gestita allo stesso modo,
   * credo questo metodo generico che può gestirli tutti.
   * @param resourceToRetrieve è la risorsa da recuperare, consulta {@link LookupModels}.
   */
  getLookupResource(resourceToRetrieve: LookupModels): Observable<any> {
    return this.http.get<any>(`${API_DOMAIN}/v1/Lookup/${resourceToRetrieve}`);
  }

  updateStats(newData: DashboardStat[]): void {
    console.log('updateStats', newData);
    this._myData.next(newData);
    this.myData = newData;
  }

  setCurrentlySelectedClient(client: Client): void {
    this.currentlySelectedClient = client;
    this._currentlySelectedClient.next(client);
  }

  upsertProfile(obj: IFProfile): Observable<IFProfile> {
    return this.http.put<IFProfile>(`${API_DOMAIN}/${API_VERSION}/${PROFILES_KEYWORD}`, obj);
  }

  saveWholeProfile(profile: IFProfile, profileId: string): Observable<IFProfile> {
    return this.http.patch<IFProfile>(`${API_DOMAIN}/${API_VERSION}/${PROFILES_KEYWORD}/${profileId}`, profile);
  }

  patchAccountAttribute(attributeName: string, object: any, id: string): Observable<IFProfile> {
    return this.http.patch<IFProfile>(`${API_DOMAIN}/${API_VERSION}/${PROFILES_KEYWORD}/${id}`, {
      [attributeName]: object
    });
  }

  /**
   * Salva gli attributi determinati dall'oggetto, che può essere di qualsiasi forma.
   * @param myId l'id profilo
   * @param object l'oggetto da salvare, può essere un parziale del profilo
   */
  patchAccount(myId: string, object: any): Observable<IFProfile> {
    return this.http.patch<IFProfile>(`${API_DOMAIN}/${API_VERSION}/${PROFILES_KEYWORD}/${myId}`, object);
  }

  resetMyPassword(newPassword: string): Observable<Client> {
    return this.http.put<Client>(`${API_DOMAIN}/change/password`, {value: newPassword});
  }

  setMe(me: IFProfile): void {
    this._me.next(me);
    this.me = me;
  }

  /**
   * Questo metodo controlla che entrambi gli attributi `data_accettazione_termini` e
   * `data_accettazione_clausole` siano a `true`. Se non lo sono impedisce di cambiare rotta.
   * @param me
   */
  checkGDPRAdempiency(me: IFProfile): void {
    const accept: string = me.data_accettazione_termini;
    const hone: string = me.data_accettazione_clausole;
    if (!accept || !hone) {
      console.log('deve accettare il contratto');
      this.router.navigate(['/dashboard/' + PRIVACY_KEYWORD_URL]);
    }
  }

  login(email: string, password: string): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(`${API_DOMAIN}/login`, {
      email: email,
      password: password
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  recoverPassword(email: string): Observable<any> {
    return this.http.post(`${API_DOMAIN}/forgot`, {email: email});
  }

  save(client: Client): Observable<Client> {
    return this.http.patch<Client>(`${V1_URL}/${client.id}`, client);
  }

  logout(ifauth: string): Observable<any> {
    return this.http.delete<any>(`${API_DOMAIN}/logout`);
  }

  updateClientsList(newValueclients: Client[]): void {
    console.log('TRIGGERED HERE, CORRECT', newValueclients);
    this.clients = newValueclients;
    this._clientsSubject.next(newValueclients);
  }

  getParameterizedClientsCount(filter: any): Observable<{count: number}> {
    const JSON_FILTER = this.us.buildLoopbackJSONForCount(filter);
    return this.http.get<{count: number}>(`${V1_URL}/count?where=${JSON.stringify(JSON_FILTER)}`);
  }

  getClientsList(limit: number, skip: number, order?: string, filter?: any): Observable<Client[]> {
    const JSON_FILTER = this.us.buildLoopbackJSON(limit, skip, order, filter);
    return this.http.get<Client[]>(`${V1_URL}?filter=${JSON.stringify(JSON_FILTER)}`);
  }

  getClient(clientId: string): Observable<Client> {
    return this.http.get<Client>(`${V1_URL}/${clientId}`);
  }

  getCreditForClientCount(clientId: string, filter?: any): Observable<{count: number}> {
    const JSON_FILTER = this.us.buildLoopbackJSONForCount(filter);
    return this.http
    .get<{count: number}>(
      `${V1_URL}/${clientId}/crediti/count?where=${JSON.stringify(JSON_FILTER)}`
    );
  }

  /**
   * Questo metodo non cancella realmente l'utente ma effettua una PATCH
   * dove modifica soltanto l'attributo `stato`, rappresentativo dell'attività o meno
   * del cliente in questione.
   * La cancellazione è dunque logica e non effettiva.
   * @param clientId
   */
  eraseClient(clientId: string): Observable<Client> {
    return this.http.patch<Client>(`${V1_URL}/${clientId}`, {
      stato: false
    });
  }

  setNewIFAuthToken(token: LoginResponse): void {
    this.expires = new Date(Date.now() + token.ttl);
    this.token = token.IFAuth;

    localStorage.setItem('expires', this.expires.toISOString());
    localStorage.setItem('ifauth', this.token);
    localStorage.setItem('pftoken', token.pftoken);
    console.log('TRIGGER ON SERVICE', this.expires);
    this.myIFAuthToken.next(token);
  }

  createClient(clientObj: Client): Observable<Client> {
    return this.http.post<Client>(`${V1_URL}`, clientObj);
  }

  setMyClients(): void {
    this.http.get(`${V1_URL}?filter={"where": {"stato": true}}`)
    .subscribe((clients: Client[]) => {
      const myWorker: Worker = this.ws.testBlob(clients);
      myWorker.postMessage(clients);
      myWorker.addEventListener('message', (received) => {
        console.log('il risultato ricevuto', received);
        this.setClients(received.data[1]);
      });
    }, error => {
      // TODO handle error
    });
  }

  setClients(clients: Client[]): void {
    this._clientsSubject.next(clients);
    this.clients = clients;
  }

  loadMe(): Observable<IFProfile[]> {
    return this.http.get<IFProfile[]>(`${API_DOMAIN}/${API_VERSION}/${PROFILES_KEYWORD}`);
  }

  /**
   * Questo metodo è utilizzato nel component {@link IFBaseClass}, che viene esteso
   * da diversi componenti nel profilo, e vorrei usarlo come base per molte altre classi.
   */
  loadAndSetMe(): void {
    this.http.get<IFProfile[]>(`${API_DOMAIN}/${API_VERSION}/${PROFILES_KEYWORD}`)
    .subscribe((profile: IFProfile[]) => {
      this.setMe(profile[0]);
    });
  }

  uploadLogo(formData: FormData): Observable<UploadLogoResponse> {
    return this.http.post<UploadLogoResponse>(`${API_DOMAIN}/${API_VERSION}/attachments/images/upload`, formData);
  }

  uploadMyData(formData: FormData): Observable<any> {
    return this.http.post<any>(`${API_DOMAIN}/${API_VERSION}/attachments/import/upload`, formData);
  }

  getCatalogs(): Observable<Catalog[]> {
    return this.http.get<Catalog[]>(`${API_DOMAIN}/${API_VERSION}/Listini?filter={"order":"prezzo ASC"}`);
  }

  purchaseSms(order: IFOrder): Observable<IFPurchaseResponse> {
    return this.http.post<IFPurchaseResponse>(`${API_DOMAIN}/${API_VERSION}/Ordini`, order);
  }

  getSmsCount(): Observable<SMSCountResponse> {
    return this.http.get<SMSCountResponse>(`${API_DOMAIN}/count/sms`);
  }
  getEmailCount(): Observable<SMSCountResponse> {
    return this.http.get<SMSCountResponse>(`${API_DOMAIN}/count/email`);
  }

  verifyEntratel(
    entratel_username: string,
    entratel_password: string,
    entratel_pin: string,
    entratel_pec: string
  ): Observable<any> {
    return this.http.post<any>(`${API_DOMAIN}/verify/entratel`, {
      entratel_username,
      entratel_password,
      entratel_pin,
      entratel_pec
    });
  }

}

