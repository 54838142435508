/**
 * @license
 * Copyright SMART SRL ®. Tutti i diritti riservati
 *
 * @author
 * Leonardo Maria Miliacca
 *
 * L'uso di questo codice è soggetto a copyright
 */
import { DashboardData, DashboardBarStat } from 'src/app/interfaces/dashboard_stat';
import { DashboardStat } from './../../../../interfaces/dashboard_stat';
import { KEYWORDS_URL } from '../../../../constants/keywords';
import { Router } from '@angular/router';
import { ClientsService } from '../../../../services/clients.service';
import { UtilsService } from '../../../../services/utils.service';
import { CreditsService } from '../../../../services/credits.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Credit } from '../../../../interfaces/credit';
import { Client } from '../../../../interfaces/client';
import { StatoPagamentiLabels, StatoPagamentiToken } from '../../../../constants/payment_statuses';
import { StatoPagamento, StatiPagamentoTokens } from './../../../../interfaces/stato_pagamento';
import { MessagesService } from './../../../../services/messages.service';
import { MatSelectChange } from '@angular/material/select';
import { BarChartFilter } from '../../../../interfaces/bar_chart_filter';
import { DashboardPayload } from '../../../../interfaces/requests/bar_chart_payload';
import { Observable, forkJoin, Subscription } from 'rxjs';


@Component({
  templateUrl: './control-panel.component.html',
  styleUrls: ['./control-panel.component.scss']
})
export class ControlPanelComponent implements OnInit {
  @ViewChild('myPieChart', { read: true, static: false }) myPieChart: ElementRef;
  @ViewChild('lineChart', { read: true, static: false }) lineChart: ElementRef;

  LOADING = true;

  MONTHS = [null, 'Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio',
  'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'];

  DAYS = 365;
  nearExpirationCredits: Credit[];
  clientsOfnearExpirationCredits: {[key: string]: Client} = {};
  keywords = KEYWORDS_URL;

  paymentsStatuses: StatoPagamento[];
  dataToShow: DashboardBarStat;
  keysOfDataToShow: string[];

  pieChart: any;
  linesChart: any;
  statoPagamentoToken: StatiPagamentoTokens;

  iterableStatoPagamento: StatiPagamentoTokens[] = [
    StatiPagamentoTokens.open,
    StatiPagamentoTokens.paid,
    StatiPagamentoTokens.verify,
    StatiPagamentoTokens.unpaid
  ];

  now = new Date();

  loadingChart = false;
  barChartFilter: BarChartFilter = BarChartFilter.nessun_filtro;
  filters = BarChartFilter;
  selectedYear: number = (new Date()).getFullYear();
  possibleYears: number[]| string[];

  LABELS = {
    open: {
      color: '#5A99F4',
      label: StatoPagamentiLabels.open
    },
    paid: {
      color: '#12355B',
      label: StatoPagamentiLabels.paid
    },
    verify: {
      color: '#F4DF3D',
      label: StatoPagamentiLabels.verify
    },
    unpaid: {
      color: '#A20021',
      label: StatoPagamentiLabels.unpaid
    },
    deleted: {
      color: '#A20021',
      label: StatoPagamentiLabels.deleted
    }
  };

  summarizedCredits: DashboardData[] = [];

  data: any;

  StatiPagamentoTokens = StatiPagamentoTokens;
  lowerChartData: any;

  clientsAndCreditsSubscription: Subscription;

  clientsCount: number;
  creditsCount: number;

  constructor(
    public creditsService: CreditsService,
    private utilsService: UtilsService,
    public clientsService: ClientsService,
    private router: Router,
    private ms: MessagesService
  ) {


  }

  retrieveFirstDayOfTheYear(): Date {
    const year: number = this.now.getFullYear();
    return new Date(year, 0, 1);
  }

  ngOnInit(): void {
    // non viene ripetuta grazie a shareReplay
    this.creditsService.setPaymentsStatuses();

    forkJoin([
      this.creditsService.getDataForChartsInDashboard(),
      this.creditsService.getPaymentsStatuses(),
      this.creditsService.getParameterizedCreditsCount(null, null, null, {}),
      this.clientsService.getParameterizedClientsCount({}),
    ])
    .subscribe((response: [
      any,
      StatoPagamento[],
      {count: number},
      {count: number}
    ]) => {

      this.creditsCount = response[2].count;
      this.clientsCount = response[3].count;

      this.LOADING = false;

      this.activateLowerChart();

      const barsInitialData = response[0];
      const barsData: DashboardBarStat = barsInitialData.find((obj) => obj.widget === 'bars') || {
        filtro: {
          campo: BarChartFilter.nessun_filtro,
          data_da: null,
          data_a: new Date(),
        },
        data: {[this.now.getFullYear()]: []},
        label: '',
        timestamp: (new Date()).toISOString(),
        widget: 'bars'
      };
      Object.assign(barsData, {
        filtro: this.creditsService.dateFilter
      });

      const barsYearlyData: any = barsData.data;
      const barsYearlyDataKeys: string[] = Object.keys(barsYearlyData);
      this.possibleYears = barsYearlyDataKeys.map((n: string) => +(n)) || [];

      const goal = this.now.getFullYear();

      this.selectedYear = this.possibleYears.reduce(function(prev, curr) {
        return (Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev);
      });

      if ((barsData) && (barsYearlyDataKeys.length > 0)) {
        this.summarizedCredits = barsData.data[this.selectedYear] || [{
          value: 0,
          categoria: 'paid',
          ammontare: 0,
          conteggio: 0,
          width: '0'
        }];
        this.drawBars(this.summarizedCredits);
      }


      this.dataToShow = response[0].find((obj) => obj.widget === 'monthly_mix');
      console.log(this.dataToShow, 'responses[0');

      this.clientsService.updateStats(response[0]);

      this.getExpirationCredits(response[1]);
      this.creditsService.setPaymentsStatuses
    });

  }

  drawBars(stats: DashboardData[]): void {
    this.summarizedCredits = stats;
    for (const credit of this.summarizedCredits) {
      credit.width = this.calcWidth(credit, this.summarizedCredits);
    }
  }

  getExpirationCredits(paymentsStatuses: StatoPagamento[]): void {

    const today = new Date();
    const to = new Date(today.getTime() + this.DAYS * 24 * 60 * 60 * 1000); // 5 days

    const openPaymentStatus: StatoPagamento = paymentsStatuses.find((sp: StatoPagamento) => sp.token === StatoPagamentiToken.open);

    this.creditsService.getCreditsForPeriod(today, to)
    .subscribe((nearExpirationCredits: Credit[]) => {
      console.log('nearExpirationCredits', nearExpirationCredits);
      nearExpirationCredits = nearExpirationCredits.filter((c: Credit) => c.statopagamentoId === openPaymentStatus.id);
      const newResponse: Credit[] = this.utilsService.sort(nearExpirationCredits, 'data_scadenza').reverse();
      this.nearExpirationCredits = this.utilsService.chunkArray(newResponse, 7)[0];

      if (!this.clientsService.clients) {
        this.clientsService._clientsObservable$
        .subscribe((clients: Client[]) => {
          this.setClientsNearExpirationCredits(clients);
        });
      } else {
        this.setClientsNearExpirationCredits(this.clientsService.clients);
      }

    });
  }

  goTo(credit: Credit): void {
    this.router.navigate([`${this.keywords.dashboard}/${this.keywords.credits}/${credit.id}`]);
  }

  setClientsNearExpirationCredits(clients: Client[]): void {
    if ((clients) && (this.nearExpirationCredits)) {
      for (const NEC of this.nearExpirationCredits) {
        const relatedClient: Client = clients.find((c: Client) => c.id === NEC.clienteId);
        relatedClient ? this.clientsOfnearExpirationCredits[relatedClient.id] = relatedClient : (() => {})();
      }
    }
  }

  calcWidth(credit, credits: any[]): string {
    const highestValue = Math.max.apply(Math, credits.map((o) => o.ammontare ));
    const percentage = Math.floor((credit.ammontare / highestValue) * 100);
    return `${percentage}%`;
  }

  setActiveFilterAndGoToCredits(creditCategoria: string): void {
    this.creditsService.setActiveFilter(creditCategoria);
    this.router.navigate([`${this.keywords.dashboard}/${this.keywords.credits}/`]);
  }

  recalcChartByYear($event: MatSelectChange): void {
    this.checkForNewStats(this.barChartFilter, new Date(), new Date());
  }

  updateBarChart($event: MatSelectChange): void {
    this.barChartFilter = $event.value;
    if (this.barChartFilter === BarChartFilter.nessun_filtro) {
      this.creditsService.resetDateFilter();
      this.checkForNewStats(this.barChartFilter, new Date(), new Date());
    } else {
      if (this.creditsService.dateFilter.data_da && this.creditsService.dateFilter.data_a) {
        this.creditsService.dateFilter.campo = $event.value;
        this.checkForNewStats(
          this.barChartFilter,
          this.creditsService.dateFilter.data_da,
          this.creditsService.dateFilter.data_a
        );
      }
    }
  }

  /**
   * IMPORTANTE
   * Questo metodo può essere lanciato in due modi
   * 1 - con il filtro vuoto, oggetto senza campi
   * 2 - con il filtro popolato con `data_da` e `data_a`, e sono obbligatori entrambi
   */
  checkForNewStats(barChartFilter: BarChartFilter, from: Date, to: Date): void {

    const filter: DashboardPayload = {
      widget: 'bars',
      filtro: {
        data_a: null,
        data_da: null
      }
    };

    if (barChartFilter === 'nessun_filtro') {
      this.getNewAggregatedValues(filter);
    } else {
      if (from && to) {
        from instanceof Date ? (() => {})() : from = new Date(from);
        to instanceof Date ? (() => {})() : to = new Date(to);
        Object.assign(filter.filtro, {
          data_da: `${from.getFullYear()}-${from.getMonth() + 1}-${from.getDate()}`,
          data_a: `${to.getFullYear()}-${to.getMonth() + 1}-${to.getDate()}`,
          campo: barChartFilter
        });
        this.getNewAggregatedValues(filter);
      }


    }

  }

  /**
   * Questo metodo accetta in ingresso un payload di tipo {@link DashboardPayload}
   * Su questa chiamata effettuo una [PATCH] che inizia l'elaborazione,
   * per problemi lato server di parallelizzazione, non riusciamo a far sì
   * che i dati mi vengano restituiti alla risoluzione della chiamata.
   * Nemmeno facendo una [GET] subito dopo mi restituisce dati corretti poiché
   * abbiamo visto che l'elaborazione prende fino a 5 secondi.
   * Pertanto utilizzo un intervallo in cui ogni 2 secondi effettuo una [GET]
   * e controllo l'attributo `timestamp` , se è uguale significa che l'elaborazione
   * ancora non è terminata, altrimenti posso utilizzare i nuovi dati.
   * @param payload
   * @returns `void`
   */
  getNewAggregatedValues(payload: DashboardPayload): void {
    this.loadingChart = true;
    this.creditsService.patchAggregatedValues('bars', payload)
      .subscribe((response: DashboardStat) => {

        const timestamp: string = response.timestamp;

        const interval = setInterval(() => {
          this.creditsService.getAggregatedValues('bars').subscribe((stats: DashboardStat) => {
            if (timestamp !== stats.timestamp) {
              clearInterval(interval);
              this.possibleYears = Object.keys(stats.data).map((n: string) => +(n)) || [];
              const dataToShow: DashboardData[] = stats.data[`${this.selectedYear}`];
              this.summarizedCredits = dataToShow ? dataToShow : null;
              if (this.summarizedCredits) {
                this.drawBars(dataToShow);
              }
              this.loadingChart = false;
            }
          }, err => {
            // TODO catch error
            this.loadingChart = false;
          });
        }, 1000);
      }, err => {
        this.loadingChart = false;
      });
  }

  activateLowerChart(): void {
    const tokens = [StatiPagamentoTokens.open, StatiPagamentoTokens.paid, StatiPagamentoTokens.verify, StatiPagamentoTokens.unpaid];
    const calls: Observable<any>[] = tokens.map((t) => {
      return this.creditsService.getParameterizedBarChartsInDashboard(`monthly_${t}`);
    });

    forkJoin(calls)
    .subscribe((allMonthlyResults) => {
      console.log('allMonthlyResults', allMonthlyResults);
      this.lowerChartData = {
        [tokens[0]]: allMonthlyResults[0],
        [tokens[1]]: allMonthlyResults[1],
        [tokens[2]]: allMonthlyResults[2],
        [tokens[3]]: allMonthlyResults[3]
      };
      console.warn('triggerato il caricamento grafici');
    });
  }

}
