<ng-container *ngIf="inputForm.type === IFFormBlockInputTypes.anagrafica">
  <div class="form-container" [formGroup]="inputForm.formGroup">
    <div class="flex-row">
      <div class="wrapper flex-row vertical">
        <h4>Informazioni Anagrafiche</h4>
        <div class="flex-row">
          <div class="wrapper centered">
            <mat-checkbox
              (click)="
                eraseOppositeInfos(
                  inputForm.formGroup.get('persona_fisica').value
                )
              "
              formControlName="persona_fisica"
            >
              Persona Fisica?</mat-checkbox
            >
          </div>
          <div class="wrapper centered" *ngIf="isPhysicalPerson">
            <mat-form-field>
              <input matInput placeholder="Nome*" formControlName="Nome" />
            </mat-form-field>
          </div>
          <div class="wrapper centered" *ngIf="isPhysicalPerson">
            <mat-form-field>
              <input
                matInput
                placeholder="Cognome*"
                formControlName="Cognome"
              />
            </mat-form-field>
          </div>

          <ng-container *ngIf="!isPhysicalPerson">
            <div class="wrapper centered">
              <mat-form-field>
                <input
                  matInput
                  placeholder="Denominazione/Ragione Sociale*"
                  formControlName="Denominazione"
                />
              </mat-form-field>
            </div>
          </ng-container>

        </div>
        <div class="flex-row">
          <div class="wrapper centered">
            <mat-form-field>
              <input
                type="text"
                matInput
                formControlName="CodiceDestinatario"
                placeholder="Codice Destinatario (max 7 caratteri)"
              />
            </mat-form-field>
          </div>
          <div class="wrapper centered">
            <mat-form-field>
              <input
                type="email"
                matInput
                formControlName="pec"
                placeholder="Indirizzo Pec"
              />
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="wrapper flex-row vertical" [formGroupName]="'Sede'">

        <h4>Sede/Indirizzo</h4>
        <div class="flex-row">
          <div class="wrapper centered">
            <mat-form-field>
              <mat-select formControlName="Nazione" placeholder="Nazione">
                <mat-option value="IT">IT</mat-option>
                <mat-option value="FR">FR</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="wrapper centered">
            <mat-form-field class="cap">
              <input
                matInput
                placeholder="CAP*"
                formControlName="CAP"
                (input)="automaticallyPopulateGeoFields($event, inputForm.formGroup.get('Sede'))"
            />
            <mat-hint *ngIf="citySpotted && !cantFindZIP">
              {{citySpotted}}
            </mat-hint>
          </mat-form-field>
          </div>

        </div>

        <div class="flex-row" *ngIf="cantFindZIP">
          <div class="wrapper centered">
            <mat-form-field class="cap">
              <input
                matInput
                placeholder="Comune*"
                formControlName="Comune"
              />

            </mat-form-field>
          </div>
          <div class="wrapper centered">
            <mat-form-field class="cap">
              <input
                matInput
                placeholder="Provincia*"
                formControlName="Provincia"
              />

            </mat-form-field>
          </div>
        </div>

        <div class="flex-row">

          <div class="wrapper centered">
            <mat-form-field class="cap">
              <input
                matInput
                placeholder="Indirizzo*"
                formControlName="Indirizzo"
            />

            </mat-form-field>
          </div>
          <div class="wrapper centered">
            <mat-form-field class="cap">
              <input
                formControlName="NumeroCivico"
                placeholder="Civico"
                type="text"
                matInput
              />
            </mat-form-field>
          </div>
        </div>
      </div>

      <div [formGroupName]="'IdFiscaleIVA'" class="flex-row">
        <div class="wrapper flex-row vertical">
          <h4>Dati Fiscali</h4>
          <div class="flex-row wrapper vertical">
            <div class="wrapper centered">
              <mat-form-field>
                <mat-select placeholder="Codice Paese" formControlName="IdPaese">
                  <mat-option value="IT">IT</mat-option>
                  <mat-option value="FR">FR</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="wrapper centered">
              <mat-form-field>
                <input
                  matInput
                  placeholder="Partita IVA"
                  [formControlName]="PIVA_LABEL"
                  [pattern]="societyPattern"
              />

              <mat-hint *ngIf="!cfOrPiva">
                Almeno uno fra Codice Fiscale e P.IVA deve essere presente
              </mat-hint>
            </mat-form-field>
            </div>
            <div class="wrapper centered">
              <mat-form-field>
                <input
                  matInput
                  placeholder="Codice Fiscale"
                  [formControlName]="CF_LABEL"
                />
                <mat-hint *ngIf="!cfOrPiva">
                  Almeno uno fra Codice Fiscale e P.IVA deve essere presente
                </mat-hint>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</ng-container>
