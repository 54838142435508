/**
 * @license
 * Copyright SMART SRL ®. Tutti i diritti riservati
 *
 * @author Leonardo Maria Miliacca
 * L'uso di questo codice è soggetto a copyright.
 * Questo service utilizza funzioni utilizzate in diverse parti della webapp.
 * Principalmente mostra le notifiche growl in basso a destra.
 */
import { Injectable } from '@angular/core';

import { IFFlash, IFFlashType } from '../interfaces/if-notification';
import { Subject } from 'rxjs';


@Injectable()
export class FlashService {

  private activeNotifications = new Subject<IFFlash[]>();
  public notificationsObservable$ = this.activeNotifications.asObservable();
  public _activeNotifications: IFFlash[] = [];

  constructor(

  ) {}

  /**
   * Mostra una notifica in basso a destra.
   * @param n la notifica
   */
  emitNotification(n: IFFlash): void {
    const timeToWait = n.time ? n.time : 60000;
    const currentNotifications: IFFlash[] = this._activeNotifications;
    currentNotifications.push(n);
    this.activeNotifications.next(currentNotifications);
    this._activeNotifications = currentNotifications;
    setTimeout(() => {
      console.log('fired timtout');
      this._activeNotifications = this._activeNotifications
      .filter((notif: IFFlash) => {
        return notif.id !== n.id;
      });
      this.activeNotifications.next(this._activeNotifications);
    }, timeToWait);
  }

  /**
   * Elimina una notifica dalla coda.
   * @param notificationId l'id della notifica da far sparire
   */
  pop(notificationId: string): void {
    this._activeNotifications = this._activeNotifications
      .filter((notif: IFFlash) => {
        return notif.id !== notificationId;
      });
    this.activeNotifications.next(this._activeNotifications);
  }

  /**
   * Questa funzione, dato un messaggio, connota la notifica
   * con una gravità: 'INFO'.
   * @param msg il messaggio da mostrare.
   */
  emitInfoNotification(msg: string): void {
    const notification: IFFlash = {
      id: '123asdzxqwec',
      text: msg,
      time: 4500,
      type: IFFlashType.info
    };
    this.emitNotification(notification);
  }

  /**
   * E' una short-hand function per emettere una notifica "OK".
   * @param msg il messaggio da mostrare
   */
  emitOKNotification(msg: string): void {
    const notification: IFFlash = {
      id: '123asdzxc',
      text: msg,
      time: 4500,
      type: IFFlashType.check_circle_outline
    };
    this.emitNotification(notification);
  }

  /**
   * E' una short-hand function per emettere una notifica "KO".
   * @param msg il messaggio da mostrare
   */
  emitKONotification(msg: string): void {
    const notification: IFFlash = {
      id: '123asdzxc',
      text: msg,
      time: 4500,
      type: IFFlashType.error
    };
    this.emitNotification(notification);
  }

}

