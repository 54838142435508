<div
  *ngIf="currentNotifications && currentNotifications.length > 0"
  class="notifications-block">
  <div
    class="notification-wrapper"
    *ngFor="let notification of currentNotifications"
    (click)="popNotification(notification)"
  >
  <div class="notification"
  [ngClass]="{
    'success-notification': notification.type === 'check_circle_outline',
    'info-notification': notification.type === 'info',
    'warning-notification': notification.type === 'warning',
    'error-notification': notification.type === 'error',
    'going-out': notification.goingOut,
    'touched': notification.goingOut
  }"
  >
    <div class="icon-slot">
        <mat-icon>{{notification.type}}</mat-icon>
    </div>
    <div class="text-slot">
      {{notification.text}}
    </div>
  </div>

  </div>
</div>
