/**
 * @license
 * Copyright SMART SRL ®. Tutti i diritti riservati
 *
 * @author
 * Leonardo Maria Miliacca
 *
 * L'uso di questo codice è soggetto a copyright
 */
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatGridListModule } from '@angular/material/grid-list';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { IFAnagraphicsBlockModule } from 'src/app/components/if-anagraphics-block/if-anagraphics-block.module';
import { IFDateRowModule } from 'src/app/components/if-date-row/if-date-row.module';
import { IFFileRowModule } from 'src/app/components/if-file-row/if-file-row.module';
import { IFFormArrayModule } from 'src/app/components/if-form-array/if-form-array.module';
import { IFFormBlockModule } from 'src/app/components/if-form-block/if-form-block.module';
import { IFFormRowModule } from 'src/app/components/if-form-row/if-form-row.module';
import { IFInvoiceModule } from 'src/app/components/if-invoice/if-invoice.module';
import { IFSedeBlockModule } from 'src/app/components/if-sede-block/if-sede-block.module';
import { IFTableModule } from 'src/app/components/if-table/if-table.module';
import { IFPipesModule } from 'src/app/pipes/if-pipes.module';
import { ModalManagerService } from 'src/app/services/modal-manager.service';
import { DevComponent } from '../../components/dev/dev.component';
import { IFFormComponent } from '../../components/if-form/if-form.component';
import { IFImportComponent } from '../../components/if-import/if-import.component';
import { IFSendMessageComponent } from '../../components/if-send-message/if-send-message.component';
import { AuthGuard } from '../../guards/auth.guard';
import { CreditsService } from '../../services/credits.service';
import { ExpirationsService } from '../../services/expirations.service';
import { HelperService } from '../../services/helper.service';
import { IFComponentsModule } from '../if-components/if-components.module';
import { MaterialModule } from '../material/material.module';
import { IFNotificationsModule } from '../notifications/if-notifications.module';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { ExpirationsComponent } from './pages/expirations/expirations.component';
import { MainComponent } from './pages/main/main.component';
import { DeleteDialogComponent } from './pages/notifications/dialog/delete-dialog.component';
import { IFLoaderModule } from 'src/app/components/loader/if-loader.module';
import { ProfileModule } from '../profile/profile.module';
import { InvoiceService } from 'src/app/services/invoice.service';
import { IFXMLImportModule } from 'src/app/components/if-xml-import/if-xml-import.module';
import { MonthConverterPipe } from './pipes/month-converter.pipe';
import { AggregatedMonthsModule } from 'src/app/components/charts/aggregated-months/aggregated-months.module';
import { MonthlyTrendModule } from 'src/app/components/charts/monthly-trend/monthly-trend.module';
import { ControlPanelModule } from './pages/control-panel/control-panel.module';
import { IFBuySMSOrEmailModule } from 'src/app/components/app-if-buy-sms/app-if-buy-sms.module';
import { IFCreditFormModule } from 'src/app/components/if-credit-form/if-credit-form.module';
import { IFRechargeBtnModule } from 'src/app/components/if-recharge-btn/if-recharge-btn.module';
import { IFSendNotificationModule } from 'src/app/components/if-send-notification/if-send-notification.module';
import { IFClientFormModule } from 'src/app/components/if-client-form/if-client-form.module';

import { SidebarModule } from './pages/main/sidebar/sidebar.module';

@NgModule({
  imports: [
    IFBuySMSOrEmailModule,
    IFFormArrayModule,
    IFFileRowModule,
    IFFormRowModule,
    IFDateRowModule,
    MaterialModule,
    CommonModule,
    FormsModule,
    BrowserAnimationsModule,
    BrowserModule,
    DashboardRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    IFNotificationsModule,
    CKEditorModule,
    IFFormBlockModule,
    IFComponentsModule,
    IFPipesModule,
    IFAnagraphicsBlockModule,
    IFSedeBlockModule,
    MatGridListModule,
    IFInvoiceModule,
    IFTableModule,
    AggregatedMonthsModule,
    IFLoaderModule,
    ProfileModule,
    IFXMLImportModule,
    ControlPanelModule,
    SidebarModule,
    MonthlyTrendModule,
    IFCreditFormModule,
    IFRechargeBtnModule,
    IFSendNotificationModule,
    IFClientFormModule
  ],
  declarations: [
    MonthConverterPipe,
    IFImportComponent,
    MainComponent,
    IFSendMessageComponent,
    DevComponent,
    ExpirationsComponent,
    IFFormComponent,
    DeleteDialogComponent
  ],
  entryComponents: [DeleteDialogComponent],
  exports: [RouterModule],
  providers: [
    InvoiceService,
    HelperService,
    CreditsService,
    ExpirationsService,
    AuthGuard,
    ModalManagerService
  ]
})
export class DashboardModule { }
