import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CreditNotificationsComponent } from './credit-notifications.component';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { IFSendNotificationModule } from '../if-send-notification/if-send-notification.module';
import { MatIconModule } from '@angular/material/icon';
import { MatSpinner, MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatTabsModule} from '@angular/material/tabs';
import { MatListModule } from '@angular/material/list';
import { ModelsForNotificationTypePipe } from './models-for-nt.pipe';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { SelectedModelsForNotificationTypePipe } from './selected-models-for-nt.pipe';
import { ReceiveMsgPipe } from './receive-msg.pipe';

@NgModule({
  declarations: [
    CreditNotificationsComponent,
    SelectedModelsForNotificationTypePipe,
    ModelsForNotificationTypePipe,
    ReceiveMsgPipe
  ],
  imports: [
    MatCheckboxModule,
    MatListModule,
    CommonModule,
    MatTabsModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    IFSendNotificationModule,
    MatIconModule
  ],
  exports: [
    CreditNotificationsComponent
  ]
})
export class CreditNotificationsModule {}
