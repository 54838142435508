import { ClientsService } from './../../services/clients.service';
import { Client } from './../../interfaces/client';
import { FlashService } from './../../services/notifications.service';
import { Notifica } from './../../interfaces/notifica';
import { MessagesService } from './../../services/messages.service';
import { TipoNotifica, IFBodyType } from './../../interfaces/tipo_notifica';

import { Component, EventEmitter, Output, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { ModalManagerService } from 'src/app/services/modal-manager.service';
import { Modello } from 'src/app/interfaces/modello_messaggio';
import { CK_EDITOR_CONFIG } from '../../constants/configs';
import { Observable, forkJoin } from 'rxjs';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular/ckeditor.component';
@Component({
  selector: 'app-if-send-notification',
  templateUrl: './if-send-notification.component.html',
  styleUrls: ['./if-send-notification.component.scss']
})
export class IFSendNotificationComponent implements OnInit {

  @Output() notificationCreated: EventEmitter<boolean> = new EventEmitter();
  public Editor = ClassicEditor;
  CODES: {placeholder: string, explaination: string, fieldName: string}[] = [{
    placeholder: '%%DENOMINAZIONE%%',
    explaination: 'il nome del cliente correlato',
    fieldName: 'denominazione'
  }, {
    placeholder: '%%DATA_SCADENZA%%',
    explaination: 'il giorno in cui il credito scadrà',
    fieldName: 'data_scadenza'
  }, {
    placeholder: '%%IMPORTO%%',
    explaination: 'credito correlato',
    fieldName: 'importo'
  }, {
    placeholder: '%%RIFERIMENTO%%',
    explaination: 'es.: "fattura n° 7"',
    fieldName: 'riferimento'
  }, {
    placeholder: '%%NOME%%',
    explaination: 'es.: "Mario"',
    fieldName: 'nome'
  }, {
    placeholder: '%%COGNOME%%',
    explaination: 'es.: "Rossi"',
    fieldName: 'nome'
  }, {
    placeholder: '%%PAGAFACILE%%',
    explaination: 'Verrà sostituito con un URL di rimando a PagaFacile',
    fieldName: 'nome'
  }];

  BODY_TYPES = IFBodyType;

  CKEditorConfig = CK_EDITOR_CONFIG;

  notificationTypes: TipoNotifica[];
  notifications: Notifica[];
  notificationsModelsArrays: {[key: string]: Modello[]} = {};
  models: Modello[];

  clients: Client[];
  notificationForm: FormGroup;
  charLimit: number;

  MSG_MIN_CAP = 20;
  MSG_MAX_CAP: number|string;

  selectedMeanFromService: TipoNotifica;

  editing: boolean;

  constructor(
    private cs: ClientsService,
    public ms: MessagesService,
    private fb: FormBuilder,
    private ns: FlashService,
    private mms: ModalManagerService
  ) {
    this.notificationForm = this.fb.group({
      titolo: [null, Validators.required],
      corpo: [null, Validators.required],
      giorni: [4],
      tiponotificaId: [null, Validators.required],
      dopo_data: [true],
      quando: ['data_scadenza'],
      id: [null],
      text_body: [''],
      html_body: ['']
    });
  }

  get SMSContentLength(): number {
    return this.notificationForm.get('text_body').value.length;
  }

  get howManySMS(): number {
    return Math.floor(this.notificationForm.get('text_body').value.length / 160) + 1;
  }

  pickRightColor(SMSContentLength: number): string {
    const initialRealNumber = SMSContentLength > 160 ? (SMSContentLength - (160 * Math.floor(SMSContentLength / 160))) : SMSContentLength;


    let color;
    // 255 sta a 160 come x sta a SMS
    // 255/160 = x/SMS
    const red = (255 * initialRealNumber) / 160;
    color = `rgb(${red}, 0,0)`;
    return color;
  }

  /**
   * Quando l'utente clicca su un placeholder, questo metodo deve patchare il
   * valore del messaggio già digitato finora.
   * @param placeholderCode il codice del placeholder
   */
  addPlaceholder(placeholderCode: any): void {
    const selectedNT: string = this.notificationForm.get('tiponotificaId').value;

    const selectedMean: TipoNotifica = this.notificationTypes
    .find((nt: TipoNotifica) => {
      return nt.id === selectedNT;
    });
    const valueToPatch = selectedMean.nome === 'cellulare' ? 'text_body' : 'html_body';
    const previousValue = this.notificationForm.get(valueToPatch).value;
    this.notificationForm.patchValue({
      [valueToPatch]: `${previousValue} ${placeholderCode.placeholder}`
    });


  }

  checkSelectedMean(): void {
    if (this.mms.selectedVoice) {
      if (!this.editing) {
        this.notificationForm.patchValue({
          tiponotificaId: this.mms.selectedVoice.id,
          corpo: this.mms.selectedVoice.modello_predefinito
        });
        if (this.mms.selectedVoice.nome === 'sms') {
          this.notificationForm
          .patchValue({
            text_body: this.mms.selectedVoice.modello_predefinito
          });
        } else {
          this.notificationForm
          .patchValue({
            html_body: this.mms.selectedVoice.modello_predefinito
          });
        }
      }
    }
  }


  patchBody({ editor }: ChangeEvent ): void {
    this.notificationForm.patchValue({corpo: editor.getData()});
  }

  ngOnInit(): void {

    forkJoin([
      this.ms.getMessagesModels()
    ])
    .subscribe((response: [Modello[]]) => {
      this.models = response[0];
      this.ms.setModels(this.models);

      this.models.map((model: Modello) => {
        this.notificationsModelsArrays[model.tiponotificaId] ?
        this.notificationsModelsArrays[model.tiponotificaId].push(model) :
        this.notificationsModelsArrays[model.tiponotificaId] = [model];
      });
    });

    if (this.cs.clients) {
      this.clients = this.cs.clients;
    } else {
      this.cs._clientsObservable$
      .subscribe((clients: Client[]) => {
        this.clients = clients;
      }, err => {
        // TODO catch
      });
    }

    if (this.ms.notificationsTypes) {
      this.notificationTypes = this.ms.notificationsTypes;
      this.checkSelectedMean();
    } else {
      this.ms.getNotificationsTypes()
      .subscribe((nts: TipoNotifica[]) => {
        this.notificationTypes = nts;
        this.checkSelectedMean();
      }, err => {
        // TODO catch
      });
    }

    if (this.mms.valuePatcher.model) {
      this.notificationForm.patchValue(this.mms.valuePatcher.model);
      this.notificationForm.patchValue({
        corpo: this.mms.valuePatcher.model.corpo,
        html_body: this.mms.valuePatcher.model.corpo,
        text_body: this.mms.valuePatcher.model.corpo
      });
      this.mms.resetValuePatcher();
      this.editing = true;
    } else {
      this.editing = false;
    }
  }

  get afterStatus(): boolean {
    return this.notificationForm.get('dopo_data').value;
  }

  get notificationBodyType(): string {
    const myNt: TipoNotifica = this.notificationTypes.find((nt: TipoNotifica) => {
      return nt.id === this.notificationForm.get('tiponotificaId').value;
    });
    return myNt ? myNt.tipo_corpo : 'html';
  }

  sendMessage(): void {
    const values = this.notificationForm.value;
    const model: Modello = Object.assign({}, values);
    this.createNs(model);
  }

  presetTitleAndMessage(event: MatSelectChange): void {
    const tnId: string = event.value;
    const nt: TipoNotifica = this.notificationTypes.find((t: TipoNotifica) => t.id === tnId);
    this.notificationForm.patchValue({
      html_body: nt.modello_predefinito,
      text_body: nt.modello_predefinito,
      corpo: nt.modello_predefinito
    });
  }

  createNs(model: Modello): void {

    const methodToUse: Observable<Modello> = this.editing ? this.ms.updateNotificationModel(model) : this.ms.createNotificationModel(model);
    delete(model['text_body']);
    delete(model['html_body']);
    methodToUse
    .subscribe((created: Modello) => {
      const allNOtificationsModels: Modello[] = this.ms.models;

      if (this.editing) {
        const modelToEdit: Modello = allNOtificationsModels.find((m: Modello) => m.id === created.id);
        Object.assign(modelToEdit, created);
      } else {
        allNOtificationsModels.push(created);
      }

      this.ms.setModels(allNOtificationsModels);
      this.ns.emitOKNotification(`Modello Notifica ${this.editing ? 'aggiornato' : 'creato'}`);
      this.notificationCreated.emit(true);
    }, err => {
      console.log('error?=', err);
      // TODO catch
    });
  }

  nullifyMsgId(): void {
    this.notificationForm.patchValue({messaggioId: null});
  }
}
