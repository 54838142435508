import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { IFDocumentType } from 'src/app/interfaces/if_document_type';
import { CreditsService } from 'src/app/services/credits.service';
import { IFCurrencies } from 'src/app/interfaces/if_currencies';
import { Client } from 'src/app/interfaces/client';
import { FatturaElettronicaBody, DettaglioLinee, ScontoMaggiorazione } from 'src/app/interfaces/fatturazione_elettronica/fattura_semplificata';
import { InvoiceService } from 'src/app/services/invoice.service';
import { XMLInvoice } from 'src/app/interfaces/credit';
import { ClientsService } from 'src/app/services/clients.service';
import { LookupModels } from 'src/app/interfaces/lookup-models';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-if-invoice',
  templateUrl: './if-invoice.component.html',
  styleUrls: ['./if-invoice.component.scss']
})
export class IFInvoiceComponent implements OnInit, OnChanges {

  @Input() editMode: boolean;

  @Input() client: Client;
  @Input() riferimento: string;

  /**
   * Il valore del form generals in if-credit-form
   */
  @Input() generalFormValue: any;
  @Output() formIsValid = new EventEmitter<{FatturaElettronicaBody: FatturaElettronicaBody[]}>();
  @Output() formIsRequired = new EventEmitter<boolean>();

  documentTypes: IFDocumentType[];

  modalitaPagamento: {label: string, value: string}[];
  condizioniPagamento: {label: string, value: string}[];
  nature: {label: string, value: string}[];

  invoiceForm: FormGroup;
  createInvoice = false;

  errors: any[] = [];

  constructor(
    private fb: FormBuilder,
    public crs: CreditsService,
    public is: InvoiceService,
    private cs: ClientsService,
    private us: UtilsService
  ) {


  }

  get datiPagamentoForm(): FormArray {
    const firstFA: FormGroup = this.invoiceForm.controls['FatturaElettronicaBody'] as FormGroup;
    const firstFg: FormGroup = firstFA.controls[0] as FormGroup;
    return firstFg.get('DatiPagamento') as FormArray;
  }

  get datiBeniServiziForm(): FormArray {
    const firstFA: FormGroup = this.invoiceForm.controls['FatturaElettronicaBody'] as FormGroup;
    const firstFg: FormGroup = firstFA.controls[0] as FormGroup;
    return firstFg.get('DatiBeniServizi').get('DettaglioLinee') as FormArray;
  }

  get datiGeneraliDocumentoForm(): FormGroup {
    const formArray: FormArray = this.invoiceForm.get('FatturaElettronicaBody') as FormArray;
    return formArray.controls[0].get('DatiGenerali').get('DatiGeneraliDocumento') as FormGroup;
  }

  get docType(): string {
    const formArray: FormArray = this.invoiceForm.get('FatturaElettronicaBody') as FormArray;
    const value: string = formArray.controls[0].get('DatiGenerali').get('DatiGeneraliDocumento').get('TipoDocumento').value;
    const docType: IFDocumentType = this.documentTypes.find((dt: IFDocumentType) => dt.value === value);
    return docType ? docType.label : '';
  }

  get invoiceNumberName(): string {
    const formArray: FormArray = this.invoiceForm.get('FatturaElettronicaBody') as FormArray;
    return formArray.controls[0].get('DatiGenerali').get('DatiGeneraliDocumento').get('Numero').value;
  }

  get currentCurrency(): string {
    const formArray: FormArray = this.invoiceForm.get('FatturaElettronicaBody') as FormArray;
    return IFCurrencies[formArray.controls[0].get('DatiGenerali').get('DatiGeneraliDocumento').get('Divisa').value];
  }

  get fatturaElettronicabodyArray(): FormArray {
    return this.invoiceForm.controls['FatturaElettronicaBody'] as FormArray;
  }

  get customerName(): string {
    return this.client ? this.client.UINome : '';
  }

  removeProductItem(index: number): void {
    this.datiBeniServiziForm.removeAt(index);
  }

  /**
   * Questo metodo gestisce il toggle `aggiungere fattura elettronica?`.
   * Quando abilitato, deve impostare le validazioni, quando invece viene
   * disattivato, il form deve risultare valido.
   * @param $event
   */
  buildOrDestroyInvoiceForm($event: MatSlideToggleChange): void {
    if ($event.checked) {
      this.buildInvoiceForm();
      this.formIsRequired.emit(true);
      this.patchFormWithGeneralsValues(this.generalFormValue);
    } else {
      this.invoiceForm = null;
      this.formIsRequired.emit(false);
      this.formIsValid.emit(null);
    }
  }

  save(): void {
    this.is.saveCurrentInvoice(this.invoiceForm.value);
  }

  processExistingInvoice(invoice: XMLInvoice): void {
    this.createInvoice = true;
    this.invoiceForm.patchValue(invoice);
  }

  setNature(nature: any): void {
    this.nature = Object.keys(nature.value).map((key) => {
      return {label: nature.value[key], value: key};
    });
  }

  ngOnInit() {
    this.buildInvoiceForm();

    const listenersFactoryFunction: Function = this.is.setListenersForInvoiceProducts();
    const linesFormGroup: FormGroup = <FormGroup> this.invoiceForm.get('FatturaElettronicaBody');
    const lines: FormGroup[] = <FormGroup[]> linesFormGroup.controls[0].get('DatiBeniServizi').get('DettaglioLinee')['controls'];
    lines.map((line: FormGroup) => {
      listenersFactoryFunction.bind(this)(line);
    });

    if (this.is.nature) {
      this.setNature(this.is.nature);
    } else {
      this.is.getNature().subscribe((newNature: any) => {
        this.setNature(newNature);
        this.is.setNature(newNature);
      });
    }

    if (this.is.currentlySelectedInvoice) {
      this.processExistingInvoice(this.is.currentlySelectedInvoice);
    }

    this.crs.AliquoteIVA$.subscribe((aliquote: number[]) => {
      if (this.is.currentlySelectedInvoice) {
        this.invoiceForm.patchValue(this.is.currentlySelectedInvoice);
      }
    });

    this.crs.setAliquoteIVA();

    if (this.crs.condizioniPagamento) {
      this.setCondizioniPagamentoLocally(this.crs.condizioniPagamento);
    } else {
      this.crs.condizioniPagamento$.subscribe((modalitaOk) => {
        this.setCondizioniPagamentoLocally(modalitaOk);
      });
      this.crs.setCondizioniPagamento();
    }

    if (this.crs.modalitaPagamento) {
      this.modalitaPagamento = Object.keys(this.crs.modalitaPagamento).map((key) => {
        return {label: this.crs.modalitaPagamento[key], value: key};
      });
    } else {
      this.crs.modalitaPagamento$.subscribe((modalitaOk) => {
        this.modalitaPagamento = Object.keys(modalitaOk).map((key) => {
          return {label: this.crs.modalitaPagamento[key], value: key};
        });
      });
      this.crs.setModalitaPagamento();
    }

    this.crs.documentTypes$.subscribe((docTypes: IFDocumentType[]) => {
      this.documentTypes = docTypes;
    });
    this.crs.setDocumentsTypes();

    // di base non è richiesto, pertanto emetto al component padre questa direttiva
    this.formIsRequired.emit(false);
  }

  /**
   * Le imposta localmente e patcha il form con il pagamento completo.
   * @param condizioniPagamento le condizioni
   */
  setCondizioniPagamentoLocally(condizioniPagamento: {[key: string]: string}): void {
    this.condizioniPagamento = Object.keys(condizioniPagamento).map((key) => {
      return {label: condizioniPagamento[key], value: key};
    });

    if (this.is.currentlySelectedInvoice) {
      this.invoiceForm.patchValue(this.is.currentlySelectedInvoice);
    }
  }

  ngOnChanges(): void {
    this.patchFormWithGeneralsValues(this.generalFormValue);
  }

  /**
   * Questo metodo patcha il form attuale della fattura con alcuni dati del form
   * generale, per velocizzare la UX.
   * @param formValues i dati in ingresso del form
   */
  patchFormWithGeneralsValues(formValues: any): void {
    if (formValues) {
      const {tipodocumento} = formValues;
      const linesFormGroup: FormGroup = <FormGroup> this.invoiceForm.get('FatturaElettronicaBody');
      this.patchCondizioniAndModalitaPagamento();
      const firstLineDoc: FormGroup = linesFormGroup.controls[0].get('DatiGenerali').get('DatiGeneraliDocumento') as FormGroup;
      firstLineDoc
      .patchValue({
        Data: formValues.data_emissione,
        Numero: formValues.riferimento,
        TipoDocumento: tipodocumento
      });
    }

  }

  patchCondizioniAndModalitaPagamento(): void {
    const pagamentoCompletoValue: string = this.condizioniPagamento.find(c => c.value === 'TP02').value;
    const datiPagamento: FormGroup = this.invoiceForm.controls
    .FatturaElettronicaBody['controls'][0]
    .get('DatiPagamento')
    .controls[0] as FormGroup;

    // patcho Condizioni Pagamento
    const datiPagamentoFC: FormControl = datiPagamento.get('CondizioniPagamento') as FormControl;
    datiPagamentoFC.patchValue(pagamentoCompletoValue);

    // patcho Modalità Pagamento
    if (this.client) {
      if (this.client.ModalitaPagamento) {
        const modalitaPagamentoFC: FormControl = datiPagamento
        .get('DettaglioPagamento')
        .get('ModalitaPagamento') as FormControl;
        modalitaPagamentoFC.patchValue(this.client.ModalitaPagamento);
      }
    }

    if (this.cs.me) {

      const dettaglioPagamentoFG: FormGroup = datiPagamento.get('DettaglioPagamento') as FormGroup;

      const {IBAN, IstitutoFinanziario, Beneficiario} = dettaglioPagamentoFG.controls;
      IBAN.patchValue(this.cs.me.IBAN);
      IstitutoFinanziario.patchValue(this.cs.me.IstitutoFinanziario);
      Beneficiario.patchValue(this.cs.me.Beneficiario);
    }
  }

  buildInvoiceForm(): void {
    let importo: number;
    if (this.generalFormValue) {
      importo = this.generalFormValue.importo;
    }
    this.invoiceForm = this.is.buildInvoiceForm(importo);

    this.invoiceForm.valueChanges.subscribe((values: {FatturaElettronicaBody: FatturaElettronicaBody[]}) => {
      this.calculateIVAAndTotal();
      this.errors = this.us.recursivelySpotErrors(this.invoiceForm);
      if (this.invoiceForm.valid) {
        this.formIsValid.emit(this.sanitizeFatturaElettronicaBody(values));
      } else {
        this.formIsValid.emit(null);
      }
    });
  }

  /**
   * Devo sanitizzare il blocco ScontoMaggiorazione, per farlo ho 5 possibili casi
   * nessuno sconto/maggiorazione → non mando il blocco ScontoMaggiorazione
   * sconto XX% → mando Tipo: SC, Percentuale: XX%
   * sconto 5€ → mando Tipo: SC, Importo: 5.00
   * maggiorazione YY% → mando Tipo: MG, Percentuale: YY%
   * maggiorazione 1€ → mando Tipo: MG, Importo 1.00
   * @param feb l'array di tipo {@type FatturaElettronicaBody}
   * @returns il corpo corretto della fattura elettronica body
   */
  private sanitizeFatturaElettronicaBody(
    feb: {FatturaElettronicaBody: FatturaElettronicaBody[]}
  ): {FatturaElettronicaBody: FatturaElettronicaBody[]} {
    feb.FatturaElettronicaBody.map((f: FatturaElettronicaBody) => {
      f.DatiBeniServizi.DettaglioLinee.map((linea: DettaglioLinee) => {
        if (linea.ScontoMaggiorazione) {
          if (!linea.ScontoMaggiorazione.Tipo) {
            delete(linea.ScontoMaggiorazione);
          } else {
            if (linea.ScontoMaggiorazione.Percentuale) {
              // Ho sia Tipo che Percentuale
              // lascia così
            } else {
              // Ho Tipo ma non Percentuale
              // lascia così
              if (linea.ScontoMaggiorazione.Importo) {
                // ho Tipo, non Percentuale e Importo
                // lascia così
              } else {
                delete(linea.ScontoMaggiorazione);
                // ho Tipo, non Percentuale e non Importo
              }
            }
          }
        }
      });
    });
    return feb;
  }

  calculateIVAAndTotal(): void {
    const linesFormGroup: FormGroup = <FormGroup> this.invoiceForm.get('FatturaElettronicaBody');
    const lines: FormGroup[] = <FormGroup[]> linesFormGroup.controls[0].get('DatiBeniServizi').get('DettaglioLinee')['controls'];
    let prezzoFinale = 0;
    lines.map((fg: FormGroup) => {
      const quantita = fg.get('Quantita').value;
      let prezzoUnitario: number = fg.get('PrezzoUnitario').value * quantita;
      // const valoreIVA = prezzoUnitario * (aliquotaIVA / 100);

      const scontoMaggiorazione = fg.get('ScontoMaggiorazione');
      const scontoMaggiorazioneType = scontoMaggiorazione.get('Tipo').value;
      const scontoMaggiorazionePercentage = scontoMaggiorazione.get('Percentuale').value;
      const scontoMaggiorazioneImport = scontoMaggiorazione.get('Importo').value;

      if (scontoMaggiorazioneType) {
        if (scontoMaggiorazioneType === 'SC') {
          if (scontoMaggiorazionePercentage) {
            prezzoUnitario = prezzoUnitario - (prezzoUnitario * (scontoMaggiorazionePercentage / 100));
          } else {
            if (scontoMaggiorazioneImport) {
              prezzoUnitario = prezzoUnitario - scontoMaggiorazioneImport;
            }
          }
        }

        if (scontoMaggiorazioneType === 'MG') {
         if (scontoMaggiorazionePercentage) {
          prezzoUnitario = prezzoUnitario + (prezzoUnitario * (scontoMaggiorazionePercentage / 100));
          } else {
            if (scontoMaggiorazioneImport) {
              prezzoUnitario = prezzoUnitario + scontoMaggiorazioneImport;
            }
          }
        }
      }

      prezzoFinale = prezzoFinale + prezzoUnitario;

      fg.patchValue({
        PrezzoTotale: +((prezzoUnitario).toFixed(2))
      }, {
        onlySelf: true,
        emitEvent: false
      });
    });

    this.invoiceForm.controls.FatturaElettronicaBody['controls'][0]
    .controls.DatiGenerali.controls.DatiGeneraliDocumento
    .controls.ImportoTotaleDocumento.patchValue(+(prezzoFinale.toFixed(2)), {
      onlySelf: true,
      emitEvent: false
    });
  }

  addInvoiceProduct(productIndex: number): FormGroup {
    const fg: FormGroup = this.is.addInvoiceProduct(productIndex);
    this.is.setListenersForInvoiceProducts().bind(this)(fg);
    return fg;
  }

  createProductItem(fg: FormArray): void {
    const ctrls: FormGroup[] = fg.controls as FormGroup[];
    this.datiBeniServiziForm.push(this.addInvoiceProduct(ctrls.length));
  }

}
