<div class="fattura-foot">
  <mat-slide-toggle
    (change)="buildOrDestroyInvoiceForm($event)"
    [(ngModel)]="createInvoice"
    >Creare anche una fattura associata a questo credito?</mat-slide-toggle
  >
</div>

<form
  *ngIf="createInvoice && invoiceForm"
  class="fattura-form"
  [formGroup]="invoiceForm"
>
  <div
    class="fattura"
    [formArrayName]="'FatturaElettronicaBody'"
    *ngFor="
      let item of invoiceForm.get('FatturaElettronicaBody')['controls'];
      let i = index
    "
  >
    <ng-container [formGroupName]="i">
      <div class="dicitura">
        <span *ngIf="documentTypes">
          {{ docType }} {{ invoiceNumberName }}
          {{ customerName ? " a " + customerName : "" }}
        </span>
        <ng-container *ngIf="is.currentlySelectedInvoice">
          <button (click)="save()" class="if-btn btn-dark">Salva</button>
        </ng-container>
      </div>
      <div class="fattura-head" [formGroupName]="'DatiGenerali'">
        <ng-container [formGroupName]="'DatiGeneraliDocumento'">
          <mat-form-field>
            <mat-select
              formControlName="TipoDocumento"
              placeholder="Tipo Documento*"
            >
              <mat-option
                *ngFor="let docType of documentTypes"
                [value]="docType.value"
                >{{ docType.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-select formControlName="Divisa" placeholder="Divisa*">
              <mat-option value="EUR">Euro (€)</mat-option>
              <mat-option value="USD">Dollaro ($)</mat-option>
              <mat-option value="GBP">Sterlina (£)</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <input
              formControlName="Data"
              (click)="picker.open()"
              matInput
              [matDatepicker]="picker"
              placeholder="Data*"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
          <mat-form-field>
            <input
              formControlName="Numero"
              type="text"
              matInput
              placeholder="Numero*"
            />
          </mat-form-field>
          <mat-form-field>
            <input
              formControlName="ImportoTotaleDocumento"
              type="number"
              matInput
              placeholder="Importo Totale Documento"
            />
          </mat-form-field>
        </ng-container>
      </div>
      <div class="fattura-body">
        <ng-container formGroupName="DatiBeniServizi">
          <ng-container formGroupName="DatiRiepilogo"> </ng-container>

          <ng-container *ngIf="datiBeniServiziForm.controls.length === 0">
            <blockquote>
              Nessuna linea inserita.
            </blockquote>
          </ng-container>

          <ng-container
            formArrayName="DettaglioLinee"
            *ngFor="let item of datiBeniServiziForm.controls; let k = index"
          >
            <div class="lines" [formGroupName]="k">
              <div class="counter">
                <div class="number-wrapper" title="Numero Linea">
                  {{ k + 1 }}
                </div>
              </div>

              <div (click)="removeProductItem(k)" class="trash" >
                <i class="ico-cestinasmart"></i>
              </div>
              <mat-form-field>
                <input
                  formControlName="Descrizione"
                  matInput
                  placeholder="Prodotto o Servizio*"
                />

                <mat-hint>
                  {{item.value.Descrizione ? item.value.Descrizione.length : 0}}
                  /1000
                </mat-hint>
              </mat-form-field>

              <input type="hidden" formControlName="NumeroLinea" />

              <mat-grid-list cols="4" rowHeight="4:1">
                <mat-grid-tile>
                  <mat-form-field>
                    <input
                      matInput
                      min="0"
                      formControlName="PrezzoUnitario"
                      type="number"
                      placeholder="Costo Unità* ({{ currentCurrency }})"
                    />
                  </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile>
                  <mat-form-field>
                    <input
                      matInput
                      formControlName="UnitaMisura"
                      type="text"
                      placeholder="Unità di Misura"
                    />
                  </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile>
                  <mat-form-field *ngIf="crs.AliquoteIVA">
                    <mat-select
                      placeholder="IVA %*"
                      formControlName="AliquotaIVA"
                    >
                      <mat-option [value]="a" *ngFor="let a of crs.AliquoteIVA">
                        {{ a }}%
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile>
                  <mat-form-field>
                    <input
                      min="1"
                      matInput
                      formControlName="Quantita"
                      type="number"
                      placeholder="Quantità*"
                    />
                  </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile
                  *ngIf="
                    invoiceForm.get('FatturaElettronicaBody')['controls'][0]
                      .controls.DatiBeniServizi.controls.DettaglioLinee
                      .controls[k].controls.AliquotaIVA.value === 0
                  "
                >
                  <mat-form-field>
                    <mat-select placeholder="Natura" formControlName="Natura">
                      <mat-option
                        [value]="nat.value"
                        *ngFor="let nat of nature"
                      >
                        {{ nat.value }} {{ nat.label }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile>
                  <mat-form-field>
                    <input
                      formControlName="DataInizioPeriodo"
                      (click)="picker6.open()"
                      matInput
                      [matDatepicker]="picker6"
                      placeholder="Data Inizio Periodo"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker6"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker6></mat-datepicker>
                  </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile>
                  <mat-form-field>
                    <input
                      formControlName="DataFinePeriodo"
                      (click)="picker7.open()"
                      matInput
                      [matDatepicker]="picker7"
                      placeholder="Data Fine Periodo"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker7"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker7></mat-datepicker>
                  </mat-form-field>
                </mat-grid-tile >

                <mat-grid-tile>
                  <mat-form-field>
                    <input
                    formControlName="Causale"
                    matInput
                    placeholder="Causale"
                  />
                  <mat-hint>
                    {{item.value.Causale ? item.value.Causale.length : 0}}
                    /200
                  </mat-hint>
                  </mat-form-field>
                </mat-grid-tile>

                <ng-container formGroupName="ScontoMaggiorazione">
                  <mat-grid-tile>
                    <mat-form-field>
                      <mat-select
                        placeholder="Sconto/Maggiorazione"
                        formControlName="Tipo"
                      >
                        <mat-option [value]="null">
                          <em>Nessuna</em>
                        </mat-option>
                        <mat-option value="SC">
                          Sconto
                        </mat-option>
                        <mat-option value="MG">
                          Maggiorazione
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </mat-grid-tile>
                  <mat-grid-tile>
                    <mat-form-field>
                      <input
                        #percentage="matInput"
                        placeholder="Percentuale sconto/maggiorazione"
                        formControlName="Percentuale"
                        type="number"
                        matInput
                      />
                      <mat-hint *ngIf="percentage.focused">
                        <ng-container *ngIf="directImport.value">
                          Sovrascrive importo sconto/maggiorazione
                        </ng-container>
                      </mat-hint>
                    </mat-form-field>
                  </mat-grid-tile>
                  <mat-grid-tile>
                    <mat-form-field>
                      <input
                        #directImport="matInput"
                        placeholder="Importo Sconto/maggiorazione"
                        formControlName="Importo"
                        type="number"
                        matInput
                      />
                      <mat-hint *ngIf="directImport.focused">
                        <ng-container *ngIf="percentage.value">
                          Sovrascritto da percentuale sconto/maggiorazione
                        </ng-container>
                      </mat-hint>
                    </mat-form-field>
                  </mat-grid-tile>
                </ng-container>
                <mat-grid-tile>
                  <mat-form-field>
                    <input
                      min="0"
                      matInput
                      type="number"
                      formControlName="PrezzoTotale"
                      placeholder="Prezzo Totale* ({{ currentCurrency }})"
                    />
                  </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile> </mat-grid-tile>
              </mat-grid-list>
            </div>
          </ng-container>
          <div class="add">
            <button
              [disabled]="datiBeniServiziForm.invalid"
              class="if-btn btn-light"
              (click)="createProductItem(datiBeniServiziForm)"
            >
              Aggiungi
            </button>
          </div>
        </ng-container>
      </div>

      <div class="fattura-head">
        <ng-container formArrayName="DatiPagamento">
          <ng-container
            *ngFor="let item of datiPagamentoForm.controls; let k = index"
          >
            <ng-container [formGroupName]="k">
              <div class="fattura-head">
                <mat-form-field *ngIf="condizioniPagamento">
                  <mat-select
                    formControlName="CondizioniPagamento"
                    placeholder="Condizioni Pagamento"
                  >
                    <mat-option
                      *ngFor="let condPagamento of condizioniPagamento"
                      [value]="condPagamento.value"
                    >
                      {{ condPagamento.label }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <ng-container formGroupName="DettaglioPagamento">
                <div class="fattura-head" style="display:table-cell">
                  <mat-form-field *ngIf="modalitaPagamento">
                    <mat-select
                      formControlName="ModalitaPagamento"
                      placeholder="Modalità Pagamento"
                    >
                      <mat-option
                        *ngFor="let modPagamento of modalitaPagamento"
                        [value]="modPagamento.value"
                      >
                        {{ modPagamento.label }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field>
                    <input
                      matInput
                      type="number"
                      formControlName="ImportoPagamento"
                      placeholder="Importo Pagamento"
                    />
                  </mat-form-field>

                  <mat-form-field>
                    <input
                      matInput
                      type="text"
                      formControlName="Beneficiario"
                      placeholder="Beneficiario"
                    />
                  </mat-form-field>
                </div>
                <div class="fattura-head" style="display:table-cell">
                  <mat-form-field>
                    <input
                      matInput
                      type="text"
                      formControlName="IBAN"
                      placeholder="IBAN"
                    />
                  </mat-form-field>
                  <mat-form-field>
                    <input
                      matInput
                      type="text"
                      formControlName="IstitutoFinanziario"
                      placeholder="Istituto Finanziario"
                    />
                  </mat-form-field>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>

    <div class="errors">
      <div class="error" *ngFor="let error of errors">
        {{ error.name }} è richiesto
      </div>
    </div>
  </div>
</form>
