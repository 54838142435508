import { Component, OnInit, Input, OnChanges, ChangeDetectorRef, SimpleChanges, Output } from '@angular/core';
import { MessagesService } from 'src/app/services/messages.service';
import { Modello } from 'src/app/interfaces/modello_messaggio';
import { TipoNotifica } from 'src/app/interfaces/tipo_notifica';
import { FormControl, FormArray, FormGroup, FormBuilder } from '@angular/forms';
import { Credit } from 'src/app/interfaces/credit';
import { Client } from 'src/app/interfaces/client';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-credit-notifications',
  templateUrl: './credit-notifications.component.html',
  styleUrls: ['./credit-notifications.component.scss']
})
export class CreditNotificationsComponent implements OnInit, OnChanges {

  @Input() credit: any;
  @Output() stop_notifiche = new EventEmitter<boolean>();

  /**
   * E' da recuperare manualmente qui.
   */
  relatedClient: Client;

  models: Modello[];
  notificationsTypes: TipoNotifica[];
  modelsById: {[id: string]: Modello[]} = {};

  notificationsFormGroup: FormGroup;

  addingAModel: string;

  constructor(
    private ms: MessagesService,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef
  ) {
    this.notificationsFormGroup = this.fb.group({
      stop_notifiche: [false]
    });
  }

  getRelatedNts(type: 'email'|'cellulare'): string {
    if (this.notificationsTypes) {
      return this.notificationsTypes.find((tn: TipoNotifica) => {
        return tn.nome === type;
      }).id;
    } else {
      return '';
    }
  }

  ngOnInit(): void {
    console.log('credit?', this.credit)
    this.ms.models$.subscribe((mdls: Modello[]) => {
      console.log('retrieving models from obs', mdls);
      this.models = mdls;
      this.setModelsByNtId(this.models, this.notificationsTypes);
      this.checkNotificationsTypePresence(this.models, this.notificationsTypes);
    });

    this.ms.notificationsTypes$.subscribe((nts: TipoNotifica[]) => {
      this.notificationsTypes = nts;
      this.ms.triggerSetModels();
      this.setNotificationForm(this.notificationsTypes);
    });
    this.ms.setNotificationsTypes();

    this.notificationsFormGroup.get('stop_notifiche')
    .valueChanges
    .subscribe((bool: boolean) => {
      this.stop_notifiche.emit(bool);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('changes', changes);
  }

  /**
   * Quando un utente clicca su un checkbox di un modello, patcha
   * il FormControl correlato.
   * @param notificationTypeId l'id di un tipo notifica
   */
  patchRelatedNt(notificationTypeId: string, model: Modello): void {
    const groupCtrl: FormControl = this.notificationsFormGroup.get(notificationTypeId) as FormControl;
    let previousValue: string[] = groupCtrl.value;
    if (previousValue.includes(model.id)) {
      previousValue = previousValue.filter(v => v !== model.id);
    } else {
      previousValue = previousValue.concat([model.id]);
      // per triggerare il checker
      previousValue = previousValue;
    }
    this.notificationsFormGroup.get(notificationTypeId)
    .patchValue(previousValue);
    this.cd.detectChanges();
    groupCtrl.updateValueAndValidity();
    this.notificationsFormGroup.updateValueAndValidity();
  }


  setNotificationForm(nts: TipoNotifica[]): void {
    nts.map((nt: TipoNotifica) => {
      this.notificationsFormGroup.addControl(nt.id, new FormControl([]));
    });
  }

  /**
   * Quando si recuperano i modelli, bisogna vedere qual è il tipo di notifica non
   * coperto da un modello, questo è il metodo che lo scopre.
   * @param models i modelli
   * @param nts i tipi notifica
   */
  checkNotificationsTypePresence(models: Modello[], nts: TipoNotifica[]): void {
    // gli ids dei tipi notifica
    const modelsNtsIds: string[] = models.map(m => m.tiponotificaId);

    nts.map((nt: TipoNotifica) => {
      if (modelsNtsIds.includes(nt.id)) {
        nt.attivo = true;
      } else {
        nt.attivo = false;
      }
    });
  }

  /**
   * Questo metodo serve per identificare i modelli in base al loro tipo.
   */
  setModelsByNtId(models: Modello[], nts: TipoNotifica[]): void {
    nts.map((nt: TipoNotifica) => {
      this.modelsById[nt.id] = models.filter((m: Modello) => {
        return m.tiponotificaId === nt.id;
      });
    });

  }

   /**
   * Se l'utente non ha modelli di un determinato tipo, questo metodo permette di farglieli creare.
   * @param modelType ovvero l'id di tipo messaggio: sms, email etc
   */
  addAModel(modelType: string): void {
    console.log('aggiungo ora', modelType);
    this.addingAModel = modelType;
    if (!modelType) {
      this.ms.getMyModels()
      .subscribe((models: Modello[]) => {
        this.models = models;
        this.setModelsByNtId(this.models, this.notificationsTypes);
      });
    }
  }

  addNotification(formGroup: FormGroup, notificationType: string): void {
    const items = formGroup.get('notifications') as FormArray;
    items.push(this.createNotification(notificationType));
  }

  createNotification(tipoNotifica: string = 'email'): FormGroup {
    return this.fb.group({
      tipoNotifica
    });
  }

  get modelsForSms(): Modello[] {
    if ((this.models) && (this.notificationsTypes)) {
      return [];
    } else {
      return this.models.filter((m: Modello) => {
        return m.tiponotificaId === this.getRelatedNts('cellulare');
      });
    }
  }

  get modelsForEmail(): Modello[] {
    if ((this.models) && (this.notificationsTypes)) {
      return [];
    } else {
      return this.models.filter((m: Modello) => {
        return m.tiponotificaId === this.getRelatedNts('email');
      });
    }
  }

  get smsNtsId(): string {
    return this.getRelatedNts('cellulare');
  }

  get emailNtsId(): string {
    return this.getRelatedNts('email');
  }

}
