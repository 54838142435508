export enum StatiPagamentoTokens {
  open = 'open',
  paid = 'paid',
  unpaid = 'unpaid',
  verify = 'verify'
}

export interface StatoPagamento {
  nome_stato: string;
  id: string;
  token: string;
}
