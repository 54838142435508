import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { LogoutDialogComponent } from "./logout-dialog";

@NgModule({
  declarations: [
    LogoutDialogComponent
  ],
  exports: [

  ],
  imports: [
    CommonModule,
    MatButtonModule
  ]
})
export class LogoutDialogModule {}
