import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { IMPORT_CREDITS_KEYWORD_URL, IMPORT_CLIENTS_KEYWORD_URL } from './../../constants/keywords';
import { FlashService } from './../../services/notifications.service';
import { ClientsService } from './../../services/clients.service';
import { IFFlashType, IFFlash } from './../../interfaces/if-notification';
import { Component, ElementRef, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { HttpRequest, HttpClient, HttpEventType, HttpResponse } from '@angular/common/http';
import { API_DOMAIN, API_VERSION } from '../../constants/server';


@Component({
  selector: 'app-if-import',
  templateUrl: 'if-import.component.html',
  styleUrls: ['./if-import.component.scss']
})
export class IFImportComponent {

  @Output() uploadOk: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('uploader', {read: false, static: false}) uploader: ElementRef;
  @Input() typeOfImport: string;

  formData: FormData = new FormData();

  TYPES = {
    [IMPORT_CLIENTS_KEYWORD_URL]: 'Clienti',
    [IMPORT_CREDITS_KEYWORD_URL]: 'Crediti'
  };

  msg: {text: string, status: IFFlashType} = {
    text: null,
    status: IFFlashType.info
  };

  loading = false;
  success = false;
  progress = 0;

  uploadForm: FormGroup;

  constructor(
    private cs: ClientsService,
    private fs: FlashService,
    private http: HttpClient,
    private fb: FormBuilder
  ) {
    this.uploadForm = this.fb.group({
      separator: [',', [Validators.required, this.noWhitespaceValidator]],
      sms: [false, Validators.required],
      email: [true, Validators.required]
    });

    this.uploadForm.valueChanges.subscribe((newValues) => {
      console.log('new values', newValues);
      this.formData.delete('separator');
      this.formData.delete('notifyEmail');
      this.formData.append('separator', newValues.separator);
      this.formData.append('notifyEmail', newValues.email.toString());
    });
  }

  get correctWhiteSpace(): boolean {
    return !!this.uploadForm.get('separator').errors;
  }

  get noFiles(): boolean {
    return !!this.formData.get('csv');
  }

  get myFile(): string {
    const file: File = <File>this.formData.get('csv');
    return file.name;
  }

  public noWhitespaceValidator(control: FormControl) {
    console.log('invoked', control);
    const hasWhitespace = (control.value.match(/\s/));
    const isValid = !hasWhitespace;
    console.log('is valid?', isValid);
    return isValid ? null : { whitespace: true };
  }

  startProcess(): void {
    const file: File = this.uploader.nativeElement.files[0];
    const values = this.uploadForm.value;
    this.formData.append('csv', file);
    this.formData.append('type', this.typeOfImport);
    this.formData.append('notifyEmail', values.email.toString());
    this.formData.append('separator', values.separator);
  }

  dropHandler($event: any): void {
    if (this.uploadForm.valid) {
      $event.preventDefault();
      const files: File[] = $event.dataTransfer.files;
      const fileType = files[0].type;
      if (fileType !== 'text/csv') {
        this.msg.status = IFFlashType.warning;
        this.msg.text = 'Il formato deve essere CSV';
      } else {
        const values = this.uploadForm.value;
        this.formData.append('csv', files[0]);
        this.formData.append('type', this.typeOfImport);
        this.formData.append('notifyEmail', values.email.toString());
        this.formData.append('separator', values.separator);
        // this.uploadChosenFile(files[0]);
      }
    }

  }

  allowDrop(ev: any): void {
    if (this.uploadForm.valid) {
      ev.preventDefault();
    }
  }


  uploadChosenFile(): void {
    this.loading = true;
    const req = new HttpRequest('POST', `${API_DOMAIN}/${API_VERSION}/attachments/import/upload`, this.formData, {
      reportProgress: true,
    });

    this.http.request(req).subscribe(event => {
      if (event.type === HttpEventType.UploadProgress) {
        const percentDone = Math.round(100 * event.loaded / event.total);
        console.log(`File is ${percentDone}% uploaded.`);
        this.progress = percentDone;
      } else if (event instanceof HttpResponse) {
        console.log('File is completely uploaded!');
        this.loading = false;
        this.success = true;
        const n: IFFlash = {
          text: 'Upload riuscito',
          type: IFFlashType.check_circle_outline,
          time: 3000,
          id: 'upload-ok'
        };
        this.fs.emitNotification(n);
        this.progress = 0;
        this.uploadOk.emit(true);
      }
    }, err => {
      this.loading = false;
      this.success = false;
      const n: IFFlash = {
        text: 'Upload non riuscito',
        type: IFFlashType.error,
        time: 3000,
        id: 'upload-ko'
      };
      this.fs.emitNotification(n);
    });

  }
}
