import { NgModule } from '@angular/core';
import { IFIdFiscaleComponent } from './if-id-fiscale.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';


@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
  ],
  declarations: [IFIdFiscaleComponent],
  exports: [IFIdFiscaleComponent]
})
export class IFIDFiscaleModule {}
