/**
 * @license
 * Copyright SMART SRL ®. Tutti i diritti riservati
 *
 * @author
 * Leonardo Maria Miliacca
 *
 * L'uso di questo codice è soggetto a copyright
 */
import { ClientsService } from '../../../../services/clients.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IFError } from '../../../../interfaces/if_errors';
import { LoginResponse } from '../../../../interfaces/responses';
import { Router } from '@angular/router';
import { FlashService } from '../../../../services/notifications.service';
import { fromEvent, Subscription, interval } from 'rxjs';
import { filter, delay } from 'rxjs/operators';
import { version } from '../../../../../../package.json';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  loading = false;
  success = false;

  loginForm: FormGroup;
  ifauth: string; // DEV
  showingBox = false;
  myEmail: string;
  errorMsg = '';
  error: boolean;
  info: boolean;

  VERSION = version;

  returnKeySub: Subscription;
  timeoutSub: Subscription;

  statusMsg: string;

  constructor(
    private fb: FormBuilder,
    private clientService: ClientsService,
    private router: Router,
    private ns: FlashService
  ) {

  }

  ngOnInit() {
    this.loginForm = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required, Validators.minLength(6)]]
    });

    this.returnKeySub = fromEvent(document, 'keypress')
    .pipe(
      filter((evt: KeyboardEvent) => {
      const key: number = evt.which || evt.keyCode;
      return key === 13;
    }))
    .subscribe((returnKey) => {
      this.login();
    });

  }

  ngOnDestroy(): void {
    if (this.timeoutSub) {
      this.timeoutSub.unsubscribe();
    }
    this.returnKeySub.unsubscribe();
  }

  startRecover(email: string): void {
    console.log('started', email);
    this.clientService.recoverPassword(email)
    .subscribe((response: any) => {
      this.errorMsg = 'Email inviata';
      console.log('response', response);
      this.info = true;
      this.error = true;
      setTimeout(() => {
        this.error = false;
        this.showingBox = false;
        this.myEmail = '';
      }, 2000);
    }, error => {
      console.log('error', error);
      this.info = false;
      this.error = true;
      this.errorMsg = 'Email non esistente';
      setTimeout(() => {
        this.error = false;
      }, 2000);
    });
  }

  login(): void {
    this.statusMsg = 'Effettuo il login';
    this.loading = true;
    const values = this.loginForm.value;
    this.clientService.login(
      values.email,
      values.password
    )
    .pipe(
      delay(1000)
    )
    .subscribe((response: LoginResponse) => {
      this.ifauth = response.IFAuth;
      this.clientService.setNewIFAuthToken(response);
      this.statusMsg = 'Login effettuato!';
      this.success = true;
      const interval$ = interval(1500);
      this.timeoutSub = interval$.subscribe((v) => {
        this.loading = false;
        this.router.navigate([`/dashboard/`]);
      });

    }, (err: IFError) => {
      console.log('statusCode', err.err);
      this.error = true;
      this.loading = false;
      this.errorMsg = 'Credenziali non corrette';
      setTimeout(() => {
        this.error = false;
      }, 2000);
    });
  }


}

