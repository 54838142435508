import { IFFormBlockInputTypes } from './../../interfaces/if_form_block_input';
import { TipoNotifica } from './../../interfaces/tipo_notifica';
import { Modello } from './../../interfaces/modello_messaggio';
import { MessagesService } from './../../services/messages.service';
import { TipiTerminipagamento } from '../../interfaces/tipo_termini_pagamento';
import { ClientsService } from '../../services/clients.service';
import { Client } from '../../interfaces/client';

import { CreditsService } from '../../services/credits.service';
import { FormBuilder, Validators, FormArray, FormGroup, FormControl } from '@angular/forms';
import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { CreditType } from '../../interfaces/credit_type';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { FlashService } from '../../services/notifications.service';
import { IFFlash, IFFlashType } from '../../interfaces/if-notification';
import { Subject } from '../../../../node_modules/rxjs';
import { IFProfile } from '../../interfaces/if_profile';
import { IFContactsPlatforms } from 'src/app/interfaces/client_contact';
import { ClientContact } from '../../interfaces/client_contact';
import { IFFormBlockComponent } from '../if-form-block/if-form-block.component';
import { Router } from '@angular/router';


@Component({
  selector: 'app-if-client-form',
  templateUrl: './if-client-form.component.html',
  styleUrls: ['./if-client-form.component.scss']
})
export class IFClientFormComponent implements OnInit {
  @Output() userCreated: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('installmentsInput', { read: true, static: false }) installmentsInput: ElementRef;
  @ViewChild('clientFormBlock', { read: true, static: false }) clientFormBlock: IFFormBlockComponent;

  IFContactsPlatforms = IFContactsPlatforms;

  public PIVA_LABEL = 'IdCodice';
  public CF_LABEL = 'CodiceFiscale';

  panelOpenState = true;

  private _globalChecker = new Subject<{[key: string]: boolean}>();
  private globalChecker$ = this._globalChecker.asObservable();
  public globalChecker = {
    notificationsTypes: false,
    models: false
  };

  IFFormBlockInputTypes = IFFormBlockInputTypes;

  isLinear = true;
  anagraphics: FormGroup;
  contactsForm: FormGroup;
  installmentsForm: FormGroup;
  notificationsForm: FormGroup;
  paymentsForm: FormGroup;

  clients: Client[];
  clientContacts: ClientContact[] = [];
  notificationsTypes: TipoNotifica[];
  models: Modello[];

  modelsByMean: any = {};

  paymentsTerms: TipiTerminipagamento[];

  anagraphicsIsValid = false;

  essentialProperties = [
    {service: 'ms', serviceMethod: 'getNotificationsTypes', keyword: 'notificationsTypes', checkerKeyword: 'notificationsTypes'},
    {service: 'ms', serviceMethod: 'getMyModels', keyword: 'models', checkerKeyword: 'models'},
  ];

  constructor(
    public fb: FormBuilder,
    private creditsService: CreditsService,
    private clientsService: ClientsService,
    public ms: MessagesService,
    private ns: FlashService,
    private router: Router
  ) { }

  ngOnInit() {

    this.globalChecker$.subscribe((newObj: any) => {
      Object.assign(this.globalChecker, newObj);
      if (!Object.values(this.globalChecker).includes(false)) {
        const notificationsTypes: TipoNotifica[] = this.notificationsTypes;
        notificationsTypes.map((nt: TipoNotifica) => {
          this.modelsByMean[nt.nome] = [];
        });
        this.models.map((m: Modello) => {
          const relatedNotificationType: TipoNotifica = notificationsTypes
          .find((nt: TipoNotifica) => nt.id === m.tiponotificaId);
          this.modelsByMean[relatedNotificationType.nome].push(m);
        });
      }
    });

    this.essentialProperties.map(es => {
      this.getEssentialProperty(es.service, es.serviceMethod, es.keyword, es.checkerKeyword);
    });

    this.clients = this.clientsService.clients;

    this.paymentsTerms = this.creditsService.paymentsTerms;

    this.buildAnagraphicsForm();
    this.contactsForm = this.fb.group({
      tiponotificaId: [null, Validators.required],
      valore: this.fb.group({
        principale: [null, Validators.required],
        secondario: []
      })
    });

    this.paymentsForm = this.fb.group({
      periodoScadenzaBase: [1, Validators.required],
      tipiterminipagamentoId: [null, Validators.required]
    });

    this.notificationsForm = this.fb.group({
      emails: this.fb.array([this.createNotification('Preavviso'), this.createNotification('Avviso')]),
      sms: this.fb.array([]),
      pec: this.fb.array([])
    });

    if (this.clientsService.me) {
      const me: IFProfile = this.clientsService.me;
      this.paymentsForm.patchValue({periodoScadenzaBase: me.periodo_verifica_base});
    }
  }

  buildAnagraphicsForm(): void {
    this.anagraphics = this.fb.group({
      Nome: [''],
      Cognome: [''],
      Denominazione: [''],
      CodiceDestinatario: ['0000000', [Validators.maxLength(7), Validators.minLength(7)]],
      IdFiscaleIVA: this.fb.group({
        IdPaese: ['IT'],
        IdCodice: [''],
        CodiceFiscale: ['']
      }),
      CodiceFiscale: [''],
      persona_fisica: [false],
      sede: [''],
      Sede: this.fb.group({
        Indirizzo: ['', Validators.required],
        NumeroCivico: [''],
        CAP: ['', [Validators.required, Validators.maxLength(5), Validators.minLength(5)]],
        Comune: ['', Validators.required],
        Provincia: ['', Validators.required],
        Nazione: ['', Validators.required],
      }),
      [this.PIVA_LABEL]: [''],
      [this.CF_LABEL]: [''],
      pec: [null, Validators.email]
    });

    this.pecMustPopulateIfValid();
  }

  /**
   * Controllo se l'indirizzo pec è valido, se lo è, patcho la parte "contatti".
   */
  pecMustPopulateIfValid(): void {
    this.anagraphics
    .get('pec')
    .valueChanges
    .subscribe((newValue) => {
      if (this.anagraphics.get('pec').valid) {
        this.patchPec(newValue);
      }
    });
  }

  /**
   * Qui mi occupo di patchare il form generale, nella parte PEC.
   * @param validPec l'indirizzo valido PEC
   */
  patchPec(validPec: string): void {
    const pecNotificationType: TipoNotifica = this.notificationsTypes
    .find((nt: TipoNotifica) => {
      return nt.nome === 'pec';
    });

    this.clientContacts = [{
      tiponotificaId: pecNotificationType.id,
      valore: {
        principale: validPec
      }
    }];
  }

  getEssentialProperty(service: string, serviceMethod: string,  keyword: string, checkerKeyword: string): void {
    if (this[service][keyword]) {
      this[checkerKeyword] = this[service][keyword];
      this._globalChecker.next({[checkerKeyword]: true});
    } else {
      this[service][serviceMethod]()
      .subscribe((response: TipoNotifica[]|Modello[]) => {
        this[checkerKeyword] = response;
        this._globalChecker.next({[checkerKeyword]: true});
      });
    }
  }

  removeItem(list: ClientContact[], contact: ClientContact): void {
    this.clientContacts = list.filter((ct: ClientContact) => {
      return ct.valore.principale !== contact.valore.principale;
    });
  }

  enableOrDisableNextButton(value: boolean): void {
    this.anagraphicsIsValid = value;
  }

  get currentNotificationType(): string {
    // value is an Id
    const value: string = this.contactsForm.get('tiponotificaId').value;
    if (this.notificationsTypes) {
      const nt: TipoNotifica = this.notificationsTypes.find((tn: TipoNotifica) => {
        return tn.id === value;
      });
      return nt ? nt.nome : '';
    } else {
      return '';
    }
  }

  get emailsData(): FormArray {
    return this.notificationsForm.get('emails') as FormArray;
  }
  get smsData(): FormArray {
    return this.notificationsForm.get('sms') as FormArray;
  }

  get pecData(): FormArray {
    return this.notificationsForm.get('pec') as FormArray;
  }

  createNotification(notificationType: string = 'email'): FormGroup {
    return this.fb.group({
      tipoNotifica: notificationType,
      label: '',
      ggScadenza: [1, Validators.required]
    });
  }

  addNotification(formArray: FormArray, notificationType: string): void {
    const items: FormGroup[] = <FormGroup[]>formArray.controls;
    items.push(this.createNotification(notificationType));
  }

  toggleEnableFor(event: MatSlideToggleChange, formArray: FormArray): void {
    if (!event.checked) {
      formArray.controls = [];
    } else {
      formArray.controls = [this.createNotification('Avviso')];
    }
  }

  removeNotification(formArray: FormArray, index: number): void {
    const items: FormGroup[] = <FormGroup[]>formArray.controls;
    items.splice(index, 1);
  }

  buildArrayForInstallments(totalImport: number): any[] {
    const array = [];
    let iterator = 0;
    const installments = this.installmentsInput.nativeElement.value;
    for (iterator = 0; iterator < installments; iterator++) {
      const newInstallment = this.createInstallment(totalImport, installments);
      array.push(newInstallment);
    }
    return array;
  }

  createInstallment(totalImport: number, nInstallments: number): FormGroup {

    const part: number = Math.round((totalImport / nInstallments) * 100) / 100;

    return this.fb.group({
      importoRata: part,
      scadenza: new Date()
    });
  }

  submitForm(): void {
    const notification: IFFlash = {};
    const anagraphics = this.anagraphics.value;
    const contacts = this.clientContacts;
    const payments = this.paymentsForm.value;
    const notifications = this.notificationsForm.value;

    console.log('contacts', contacts);

    const client: Client = {
      persona_fisica: anagraphics.persona_fisica,
      contatti: contacts,
      CodiceDestinatario: anagraphics.CodiceDestinatario,
      CessionarioCommittente: {
        Sede: anagraphics.Sede,
        DatiAnagrafici: {
          IdFiscaleIVA: {
            IdPaese: anagraphics.IdFiscaleIVA.IdPaese,
            IdCodice: anagraphics.IdFiscaleIVA.IdCodice
          },
          CodiceFiscale: anagraphics.IdFiscaleIVA.CodiceFiscale,
          Anagrafica: {
            Denominazione: anagraphics.Denominazione,
            Nome: anagraphics.Nome,
            Cognome: anagraphics.Cognome,
            // Titolo per ora non gestito
            // CodEORI per ora non gestito
          }
        },
      },
      tipiterminipagamentoId: payments.tipiterminipagamentoId,
      periodo_verifica: payments.periodoScadenzaBase
    };
    this.clientsService.createClient(client)
    .subscribe((createdClient: Client) => {
      const clientId: string = createdClient.id;
      this.userCreated.emit(true);
      this.ns.emitOKNotification('Cliente Creato');
      this.router.navigate([`/dashboard/clienti/${clientId}`]);
    }, error => {
      this.ns.emitKONotification('Errore nella creazione cliente');
      // handle error
    });

  }

  newContact(): void {
    this.clientContacts.push(this.contactsForm.value);
    this.contactsForm.reset();
  }

  removeContact(c: string): void {
    console.log('contact received', c);
    this.clientContacts = this.clientContacts.filter((cc: ClientContact) => {
      return cc.valore.principale !== c;
    });
  }
}
