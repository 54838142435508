import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

const ERROR_MESSAGES = {
  502: 'Errore del server',
  403: 'Errore nella richiesta'
};
@Component({
  selector: 'app-paga-facile',
  templateUrl: './pagamento.component.html',
  styleUrls: ['./pagamento.component.scss']
})
export class PagamentoComponent implements OnInit {

  error: HttpErrorResponse;
  payError: HttpErrorResponse;
  ERR_MSGS = ERROR_MESSAGES;

  correctlyPaid: boolean;

  notificationId: string;
  token: string;

  constructor(
    private ar: ActivatedRoute
  ) {
    this.ar.params.subscribe((params: Params) => {
      this.token = params.token;
      this.notificationId = params.notificaId;
      console.log('PARAMS!', params);
    });
  }

  ngOnInit() {}


}
