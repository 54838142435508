<div class="form-container">
  <ng-container *ngIf="!models">
    <mat-spinner [strokeWidth]="1"></mat-spinner>
  </ng-container>

  <ng-container *ngIf="models">
    <mat-card class="card" *ngIf="models.length === 0">
      <mat-card-header>
        <mat-card-title class="danger">
          Attenzione!
        </mat-card-title>

        <mat-card-subtitle>
          Nessun modello messaggio
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        Per poter inviare dei messaggi ai debitori
        <span (click)="addAModel(smsNtsId)" class="link">
          <strong>
            aggiungi un modello messaggio
          </strong>
        </span>
      </mat-card-content>
    </mat-card>

    <mat-card [formGroup]="notificationsFormGroup" class="card" *ngIf="models.length > 0">
      <mat-card-header>
        <mat-card-title class="danger">
          Gestione Invii
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <p>Controlla i messaggi che invierai a {{credit?.denominazione?.UINome}}.</p>
        <mat-checkbox formControlName="stop_notifiche">
          Disabilita messaggi per questo credito.
        </mat-checkbox>
        <mat-tab-group *ngIf="notificationsFormGroup.get('stop_notifiche').value === false">
          <mat-tab *ngFor="let notificationType of notificationsTypes">
            <ng-template mat-tab-label>
              <mat-icon
              [ngClass]="{
                'active': notificationType.attivo,
                'inactive': !notificationType.attivo
              }"
              >{{notificationType.attivo ? 'check': 'close'}}</mat-icon
                >
              <span class="nt">
                {{notificationType.nome}}
              </span>

            </ng-template>

            <div *ngIf="notificationType.attivo">
              <blockquote>
                Qui vengono mostrati i messaggi che saranno inviati a {{credit?.denominazione?.UINome}} tramite {{notificationType.nome}}.
              </blockquote>
              <br />
              <mat-list [formGroupName]="notificationType.id" dense role="list">
                <mat-list-item
                >

                  {{credit?.denominazione?.UINome}} riceverà tramite {{notificationType.nome}}
                  {{(credit|receiveMsg: models: notificationType.id)}}

                </mat-list-item>
              </mat-list>



            <ng-container *ngIf="(notificationsFormGroup.get(notificationType.id).value | selectedModelsForNt: models).length > 0">

            </ng-container>

            </div>
            <div *ngIf="!notificationType.attivo">
              <blockquote>
                Non puoi raggiungere {{credit?.denominazione?.UINome}} tramite <strong>{{notificationType.nome}}</strong>, poiché
                non disponi di un modello messaggio che usa questo mezzo.
              </blockquote>
            </div>
          </mat-tab>

        </mat-tab-group>

        <blockquote *ngIf="notificationsFormGroup.get('stop_notifiche').value === true">
          {{credit?.denominazione?.UINome}} non riceverà alcun messaggio per questo credito.
        </blockquote>
      </mat-card-content>
    </mat-card>
  </ng-container>
</div>

<ng-container *ngIf="models">


  <ng-container *ngIf="addingAModel === smsNtsId">

    <app-if-send-notification
    (notificationCreated)="addAModel(null)"
    ></app-if-send-notification
      >
  </ng-container>


</ng-container>

