import {NgModule} from '@angular/core';
import { ControlPanelComponent } from './control-panel.component';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { IFPipesModule } from 'src/app/pipes/if-pipes.module';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { AggregatedMonthsModule } from 'src/app/components/charts/aggregated-months/aggregated-months.module';
import { MonthlyTrendModule } from 'src/app/components/charts/monthly-trend/monthly-trend.module';
import { IFIntroModule } from 'src/app/components/intro/if-intro.module';
import { IFXMLImportModule } from 'src/app/components/if-xml-import/if-xml-import.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { TruncatePipe } from './truncate.pipe';
import { IFPageLoadModule } from 'src/app/components/if-page-load/if-page-load.module';
@NgModule({
  declarations: [
    ControlPanelComponent,
    TruncatePipe
  ],
  imports: [
    RouterModule.forChild([{
      path: '',
      component: ControlPanelComponent
    }]),
    IFIntroModule,
    CommonModule,
    MatListModule,
    MatIconModule,
    IFPageLoadModule,
    MatCardModule,
    MatExpansionModule,
    IFPipesModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatInputModule,
    IFXMLImportModule,
    MatSelectModule,
    MatCardModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    AggregatedMonthsModule,
    MonthlyTrendModule
  ]
})
export class ControlPanelModule {}
