import { Injectable } from '@angular/core';
import {
  CanActivate, Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';

import { ClientsService } from '../services/clients.service';


@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private clientsService: ClientsService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const url: string = state.url;

    return this.checkLogin(url);
  }

  checkLogin(url: string): boolean {
    const now = new Date();

    if (this.clientsService.expires > now) {
      return true;
    } else {
      this.router.navigate(['/']);
      return false;
    }
  }
}
