import { ValidatorFn, AbstractControl } from '@angular/forms';

export function forbiddenChar(r: RegExp): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      // const r = new RegExp(/^[A-Za-z\s\']+$/);
      // const validity = !!control.value.match(r);
      let validity: boolean;
      if (control.value) {
          if (control.value) {
            validity = !!control.value.match(r);
          }
      } else {
          validity = false;
      }
      return validity ? null : {forbiddenChar: {value: 'Caratteri non ammessi'}};
    };
}
