import {Pipe, PipeTransform} from '@angular/core';
import { IFTableColumn } from 'src/app/interfaces/if-table-column';
@Pipe({
  name: 'nameToLabel'
})
export class NameToLabelPipe implements PipeTransform {
  transform(
    name: string,
    columns: IFTableColumn[]
  ): string {
    return columns.find(c => c.name === name).label;
  }
}
