<section class="form">
  <h3>INVIA UN MESSAGGIO</h3>

  <form [formGroup]="msgForm">
    <ng-container *ngIf="notificationsTypes">

      <mat-form-field>
        <mat-select
        placeholder="Invia tramite...*"
        (selectionChange)="presetTitleAndMessage($event)"
        formControlName="type">
          <mat-option *ngFor="let nt of notificationsTypes" [value]="nt.id">
            {{nt.nome}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <input
        type="text"
        matInput
        placeholder="Cerca fra i tuoi clienti"
        formControlName="filter">
      </mat-form-field>

      <div class="selector">
        <ng-container *ngIf="(!clients || clients.length === 0) && selectedClients.length === 0">
          <div class="no-items">
            <div class="no-elements">
              <ng-container *ngIf="!loading">
                Nessun cliente trovato
              </ng-container>

              <ng-container *ngIf="loading">
                Cerco...
              </ng-container>
            </div>
          </div>
        </ng-container>
        <ng-container *ngFor="let sc of selectedClients">
          <div class="client">
            <mat-checkbox
            [checked]="sc.selected"
            (click)="$event.preventDefault(); selectClient(sc);"
            color="primary">{{sc.UINome}}</mat-checkbox>
          </div>
        </ng-container>
        <ng-container *ngIf="clients">
          <ng-container *ngFor="let client of clients | stringFilter:currentlyWroteName:columnRelatedToNotificationType">
            <div class="client">
              <mat-checkbox
              [checked]="client.selected"
              (click)="$event.preventDefault(); selectClient(client);"
              color="primary">{{client.UINome}}</mat-checkbox>
            </div>
          </ng-container>
        </ng-container>
      </div>

      <div class="well">
        {{howManyContacts}} contatti selezionati.
      </div>

      <hr>

      <ng-container *ngIf="selectedNotificationType?.tipo_corpo === BODY_TYPES.html">
        <mat-form-field *ngIf="isSubjectPresent">
          <input matInput placeholder="Soggetto*" type="text" formControlName="subject" >
        </mat-form-field>
      </ng-container>


      <ng-container *ngIf="selectedNotificationType">
        <ng-container *ngIf="selectedNotificationType?.tipo_corpo === BODY_TYPES.text">
          <mat-form-field>
            <textarea rows="5" cols="6" matInput formControlName="message" placeholder="Scrivi qui il messaggio*">

            </textarea>
          </mat-form-field>
          <div class="counter">
            <div class="numbers">
              <span [ngStyle]="{'color': pickRightColor(SMSContentLength)}">{{SMSContentLength}}</span>/160 : {{howManySMS}} sms
            </div>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="selectedNotificationType?.tipo_corpo === BODY_TYPES.html">
        <ckeditor
        [editor]="Editor"
        formControlName="message"
        [config]="CKEditorConfig"
        debounce="500">
        </ckeditor>
      </ng-container>

      <div class="test-email" *ngIf="isEmailOrPec">
        <ng-container *ngIf="sending">
          <mat-spinner [strokeWidth]="1"></mat-spinner>
        </ng-container>
        <ng-container *ngIf="!sending">
          <span class="highlight" (click)="sendTestEmail()">Inviami un'email di test</span>
        </ng-container>
      </div>

      <div class="error" *ngIf="error">
        {{error}}
      </div>
      <button
      [disabled]="msgForm.invalid"
      (click)="sendMessage()"
      class="if-btn btn-dark">Invia</button>
    </ng-container>
  </form>

</section>
