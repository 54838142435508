/**
 * @license
 * Copyright SMART SRL ®. Tutti i diritti riservati
 *
 * @author
 * Leonardo Maria Miliacca
 *
 * L'uso di questo codice è soggetto a copyright
 */
import { Pipe, PipeTransform } from '@angular/core';
import { SHORT_MONTHS } from '../constants/months';

/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | exponentialStrength:exponent
 * Example:
 *   {{ 2 | exponentialStrength:10 }}
 *   formats to: 1024
*/
@Pipe({name: 'shortDate'})
export class ShortDatePipe implements PipeTransform {
  transform(stringDate: string): string {
    const date = new Date(stringDate);

    const month: number = date.getMonth();
    const monthName = SHORT_MONTHS[month];

    return `${date.getDate()} ${monthName}`;
  }
}
