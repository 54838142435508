import { Component, OnInit, Input } from '@angular/core';
import { ModalManagerService } from 'src/app/services/modal-manager.service';
import { KEYWORDS_URL } from 'src/app/constants/keywords';
import { Router } from '@angular/router';

@Component({
  selector: 'app-if-intro',
  templateUrl: './if-intro.component.html',
  styleUrls: ['./if-intro.component.scss']
})
export class IFIntroComponent implements OnInit {
  @Input('NO_CLIENTS') NO_CLIENTS: boolean;
  @Input('NO_CREDITS') NO_CREDITS: boolean;
  constructor(
    private mms: ModalManagerService,
    private router: Router
  ) {}
  ngOnInit(): void {}
  addClient(): void {
    this.mms.setNewModel(KEYWORDS_URL.clients);
  }
  addCredit(): void {
    this.mms.setNewModel(KEYWORDS_URL.importInvoices);
  }
}
