<h2>IMPORTA FATTURE</h2>

<form [formGroup]="uploadForm">
<div class="dropzone-container">
  <div class="dropzone-wrapper">
    <div
    class="dropzone"
    id="drop_zone"
    (drop)="uploadForm.valid ? dropHandler($event) : null"
    (dragover)="uploadForm.valid ? allowDrop($event) : null"
    (click)="uploadForm.valid ? uploader.click() : null"
    [ngClass]="
    {'error': error}"
    >
    <ng-container *ngIf="!loading && !success && error">
      <h3>{{errorMsg|errorMsgs}}</h3>
    </ng-container>
      <ng-container *ngIf="!loading && !success && uploadForm.valid">
        <h3>Trascina qui un file

            (solo<strong>{{fileTypesAccepted[typeOfImport]}}</strong>)

        </h3>

        <ng-container *ngIf="!noFiles">
          <h4>Oppure clicca per scegliere dalle cartelle</h4>
        </ng-container>
        <ng-container *ngIf="noFiles">
          <h4>File da caricare: {{myFile}}</h4>
        </ng-container>

        <h5>Dimensioni massime del file <strong>5mb</strong></h5>
      </ng-container>
      <ng-container *ngIf="!loading && success">
        <div class="icon">
          <mat-icon>check_circle_outline</mat-icon>
        </div>
      </ng-container>

      <ng-container *ngIf="loading && !success">
        <mat-spinner [strokeWidth]="1"></mat-spinner><br />
        <ng-container *ngIf="currentAction === 'verifica'">
          Verifica file in corso
        </ng-container>
        <ng-container *ngIf="currentAction === 'upload'">
          Upload in corso
        </ng-container>
        <ng-container *ngIf="currentAction === 'processing'">
          Ancora qualche secondo...
        </ng-container>

        <ng-container *ngIf="currentAction !== 'processing'">
          {{progress}}%
        </ng-container>
      </ng-container>
      <input
      (change)="startProcess()"
      accept=".xml,.zip"
      type="file"
      #uploader
      style="display:none"
      >
    </div>
  </div>

  <div class="notify">

    <div class="text second">
      <button
      [disabled]="uploadForm.invalid || !noFiles"
      type="button"
      class="if-btn btn-dark" (click)="uploadChosenFile(uploadingAZip)">Avvia Upload</button>
    </div>
  </div>
</div>

<div *ngIf="msg.text" [ngClass]="{'warning': msg.status === 'warning'}">
  {{msg.text}}
</div>
</form>
