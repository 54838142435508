<section class="form">
  <mat-card>
    <mat-card-header class="flex-row">
      <mat-card-title>
        AGGIUNGI NUOVO MODELLO MESSAGGIO
      </mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <form [formGroup]="notificationForm">
        <mat-form-field>
          <mat-select
            color="secondary"
            formControlName="tiponotificaId"
            (selectionChange)="presetTitleAndMessage($event)"
            placeholder="Tramite*"
          >
            <mat-option *ngFor="let nt of notificationTypes" [value]="nt.id">
              {{ nt.nome }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <input
            matInput
            placeholder="Titolo Modello*"
            type="text"
            formControlName="titolo"
          />
          <mat-hint>Esempio: "SMS 4 giorni dopo la scadenza"</mat-hint>
        </mat-form-field>

        <ng-container *ngIf="notificationTypes">
          <ng-container *ngIf="notificationBodyType === BODY_TYPES.text">
            <mat-form-field>
              <textarea
                formControlName="corpo"
                (input)="patchBody($event)"
                rows="6"
                matInput
                placeholder="Messaggio*"
              ></textarea>
            </mat-form-field>
            <div class="counter">
              <div class="numbers">
                <span [ngStyle]="{ color: pickRightColor(SMSContentLength) }">{{
                  SMSContentLength
                }}</span
                >/160 : {{ howManySMS }} sms
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="notificationBodyType === BODY_TYPES.html">
            <ckeditor
            #ckEditor
            [data]="notificationForm.get('html_body').value"
            [editor]="Editor"
              (change)="patchBody($event, ckEditor)"
              [config]="CKEditorConfig"
              debounce="500"
            >
            </ckeditor>
          </ng-container>
        </ng-container>

        <mat-card style="width:60%">
          <mat-card-header>
            <mat-card-title>
              <h6>Quando inviare?</h6>
            </mat-card-title>
          </mat-card-header>
          <mat-card-content class="diff">

            <mat-form-field>
              <mat-select
              placeholder="Basati su..."
              formControlName="quando"
              >
                <mat-option [value]="'data_scadenza'">
                  Data di scadenza
                </mat-option>

                <mat-option [value]="'data_emissione'">
                  Data di emissione
                </mat-option>
                <mat-option [value]="'data_insoluto'">
                  Data insoluto
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field>
              <input type="number"
              placeholder="Giorni" formControlName="giorni" min="0" matInput />
            </mat-form-field>

            <mat-form-field>
              <mat-select
              [placeholder]="'select'|beforeAhead:notificationForm.get('quando').value"
              formControlName="dopo_data"
              >
                <mat-option [value]="false">
                  Prima dell{{'value'|beforeAhead:notificationForm.get('quando').value: true}}
                </mat-option>

                <mat-option [value]="true">
                  Dopo {{'value'|beforeAhead:notificationForm.get('quando').value: false}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </mat-card-content>
        </mat-card>


        <div class="warn-editing" *ngIf="editing">
          <mat-icon>warning</mat-icon><br />
          Attenzione, modificando questo modello modificherai il messaggio di
          tutte le notifiche non ancora inviate
        </div>

        <div class="codes">
          <ng-container *ngFor="let code of CODES">
            <strong (click)="addPlaceholder(code)">{{
              code.placeholder
            }}</strong>
            - {{ code.explaination }}<br />
          </ng-container>
        </div>

        <button
          [disabled]="notificationForm.invalid"
          type="button"
          (click)="sendMessage()"
          class="if-btn btn-light"
        >
          {{ editing ? "Salva" : "Crea" }}
        </button>
      </form>

    </mat-card-content>
  </mat-card>
</section>
