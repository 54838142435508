/**
 * @license
 * Copyright SMART SRL ®. Tutti i diritti riservati
 *
 * @author
 * Leonardo Maria Miliacca
 *
 * L'uso di questo codice è soggetto a copyright
 */

import { NgModule } from '@angular/core';
import { IFNotificationsComponent } from '../../components/if-notifications/if-notifications.component';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { FlashService } from '../../services/notifications.service';
import { MatIconModule } from '@angular/material/icon';


@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    BrowserModule,
    MatIconModule
  ],
  declarations: [
    IFNotificationsComponent
  ],
  exports: [
    IFNotificationsComponent,
    MatIconModule
  ],
  providers: [
    FlashService
  ]
})
export class IFNotificationsModule { }
