import { MessagesService } from './messages.service';
import { FlashService } from 'src/app/services/notifications.service';
import { ModalManagerService } from 'src/app/services/modal-manager.service';
import { CreditsService } from './credits.service';
import { NOTIFICATIONS_TYPES_KEYWORD, MESSAGES_TYPES_KEYWORD_URL, CREDITS_KEYWORD } from './../constants/keywords';
import { HttpClient } from '@angular/common/http';
/**
 * @license
 * Copyright SMART SRL ®. Tutti i diritti riservati
 *
 * @author
 * Leonardo Maria Miliacca
 *
 * L'uso di questo codice è soggetto a copyright
 */
import { TipoNotifica } from '../interfaces/tipo_notifica';

import { Injectable } from '@angular/core';
import { Subject } from '../../../node_modules/rxjs';
import { Modello } from 'src/app/interfaces/modello_messaggio';
import { ClientsService } from './clients.service';
import { Credit } from '../interfaces/credit';
import { Client } from '../interfaces/client';

@Injectable()
export class HelperService {
  private _objectsSubject = new Subject<{[key: string]: any[]}>();
  public objectsSubject = this._objectsSubject.asObservable();

  constructor(
    private ms: MessagesService,
    private cs: ClientsService,
    private crs: CreditsService,
    private mms: ModalManagerService,
    private ns: FlashService,
  ) {}

  getEssentialProperty(
    service: 'ms'|'cs'|'crs'|'mms'|'ns',
    serviceMethod: string,
    keyword: string
  ): void {
    if (this[service][keyword]) {
      this._objectsSubject.next({
        [keyword]: this[service][keyword]
      });
    } else {
      this[service][serviceMethod]()
      .subscribe((response: Notification[]|Modello[]|Credit[]|TipoNotifica[]|Client[]) => {
        this._objectsSubject.next({
          [keyword]: response
        });
      });
    }
  }

  populateEssentialProperty(
    service: 'ms'|'cs'|'crs'|'mms'|'ns',
    serviceMethod: string,
    keyword: string,
    checkerSubjectName: string
  ): Function {
    return function() {
      if (this[service][keyword]) {
        this[keyword] = this[service][keyword];
        this[checkerSubjectName].next({
          [keyword]: true
        });
      } else {
        this[service][serviceMethod]()
        .subscribe((response: Notification[]|Modello[]|Credit[]|TipoNotifica[]|Client[]) => {
          this[keyword] = response;
          this[checkerSubjectName].next({
            [keyword]: true
          });
        });
      }
    };
  }


}

