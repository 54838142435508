import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { StatoPagamentiToken, StatoPagamentiLabels } from 'src/app/constants/payment_statuses';
import { FriendlyNumberPipe } from 'src/app/pipes/friendly-number.pipe';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'monthly-trend',
  templateUrl: './monthly-trend.component.html',
  styleUrls: ['./monthly-trend.component.scss']
})
export class MonthlyTrendComponent implements OnInit, OnDestroy {
  @Input() lowerChartData: any;

  chart: Chart;

  COLORS = {
    [StatoPagamentiToken.open]: 'var(--gestit)',
    [StatoPagamentiToken.unpaid]: 'var(--scadu)',
    [StatoPagamentiToken.paid]: 'var(--incass)',
    [StatoPagamentiToken.verify]: 'var(--verif)'
  };

  noActivities = false;

  constructor() {}

  ngOnInit(): void {
    this.prepareData(this.lowerChartData);
  }

  ngOnDestroy(): void {}

  prepareData(lowerChartData: any): void {
    console.log('lowerChartData', lowerChartData);
    const keys: StatoPagamentiToken[] = Object.keys(
      lowerChartData
    ) as StatoPagamentiToken[];
    console.log('keys', keys);
    this.buildChart(keys, lowerChartData);
  }

  private buildCategories(
    lowerChartData: any,
    keys: StatoPagamentiToken[]
  ): Date[] {
    let allDates: number[] = [];

    keys.map((k: StatoPagamentiToken) => {
      const dataForState = lowerChartData[k];
      const years: number[] = Object.keys(dataForState.data).map(n => +n);
      years.map(n => {
        const dataForYear = dataForState.data['' + n];
        const months: number[] = dataForYear.map(d => {
          return new Date(n, d.mese).getTime();
        });
        allDates = allDates.concat(months);
      });
    });

    const distinct = (value, index, self) => {
      return self.indexOf(value) === index;
    };
    allDates = allDates.filter(distinct);
    const finalAllDates: Date[] = allDates.map(n => new Date(n));
    finalAllDates.sort();
    return finalAllDates.sort();
  }

  private buildSeries(
    lowerChartData: any,
    categories: Date[],
    keys: StatoPagamentiToken[]
  ): any[] {

    const currentMonth = new Date().getMonth(); // l'human friendly month
    const currentYear = new Date().getFullYear();

    const series: any = [
      {
        name: keys[0],
        y: 0,
        color: this.COLORS[keys[0]]
      },
      {
        name: keys[1],
        y: 0,
        color: this.COLORS[keys[1]]
      },
      {
        name: keys[2],
        y: 0,
        color: this.COLORS[keys[2]]
      },
      {
        name: keys[3],
        y: 0,
        color: this.COLORS[keys[3]]
      }
    ];

    series.map((serieObj: { name: StatoPagamentiToken; y: number }) => {
      const dataForState = lowerChartData[serieObj.name];
      const years: string[] = Object.keys(dataForState.data);
      years.map((y: string) => {
        categories.map((d: Date) => {
          console.log('date', d);
          const goodForSerie = dataForState.data[y].find(obj => {
            return (obj.mese === currentMonth) && (obj.anno === currentYear);
          });
          console.log('goodForSerie',goodForSerie);

          if (goodForSerie) {
            serieObj.y += goodForSerie.ammontare;
          } else {
            serieObj.y = null;
          }
        });
      });
    });

    series.map((s) => {
      s.name = StatoPagamentiLabels[s.name];
    });
    console.log('seires', series);

    // se tutte le y sono null, faccio comparire "non hai attività questo mese"
    this.noActivities = series.every((s) => {
      return !s.y;
    });

    return series;
  }

  private buildChart(keys: StatoPagamentiToken[], lowerChartData: any): void {
    console.log('keys', keys);
    console.log('lowerChartData', lowerChartData);
    const categories: any[] = this.buildCategories(lowerChartData, keys);
    const series: any[] = this.buildSeries(lowerChartData, categories, keys);
    console.log('categories', categories);
    console.log('series', series);
    this.chart = new Chart({
      credits: {
        enabled: false
      },
      chart: {
        type: 'pie',
        options3d: {
          enabled: true,
          alpha: 45,
          beta: 0
        },
        height: '300px',
        backgroundColor: 'transparent',
        style: {
          fontFamily: 'Roboto Condensed, sans-serif;'
        }
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: categories.map((d: Date) => `${d.getMonth() + 1}/${d.getFullYear()}`),
        crosshair: true
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Euro (€)'
        }
      },
      tooltip: {
        formatter: function() {
          const pipe = new FriendlyNumberPipe();
          return `${this.key}: ${pipe.transform(this.y)}€`;
        },
        shared: true,
        useHTML: true
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          depth: 35,
          dataLabels: {
              enabled: true,
              format: '{point.name}'
          }
        }
      },
      series: [{
        type: 'pie',
        name: 'Distribuzione Crediti',
        data: series
    }]
    });
  }
}
