<h2>Crea Credito</h2>
<mat-horizontal-stepper [linear]="true" #stepper>
  <mat-step [stepControl]="generals">
    <form [formGroup]="generals">
      <ng-template matStepLabel>GENERALI</ng-template>
      <mat-form-field>
        <input
          matInput
          placeholder="Cliente*"
          formControlName="denominazione"
          [matAutocomplete]="auto"
          [value]="generals.get('denominazione').value?.UINome | safeAutocomplete "
        />

        <mat-hint class="danger" *ngIf="(atLeastOneClient$|async) === false">
          Attenzione! Non c'è nessun cliente inserito, non potrai pertanto proseguire nella creazione di un credito
        </mat-hint>
      </mat-form-field>
      <mat-autocomplete
        (optionSelected)="selectClient($event, generals)"
        #auto="matAutocomplete"
      >
        <mat-option
          *ngFor="let client of filteredOptions"
          [value]="client"
        >
          {{ client.UINome }}
        </mat-option>
      </mat-autocomplete>

      <!--<small>
          Nuovo cliente? Aggiungi prima di procedere
        </small>-->
      <mat-form-field>
        <mat-select formControlName="tipodocumento" placeholder="Tipo Documento">
          <mat-option
            *ngFor="let creditType of documentTypes"
            [value]="creditType.value"
          >
            {{ creditType.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <input
          type="number"
          matInput
          [min]="MIN_VALUE"
          placeholder="Importo (€)*"
          formControlName="importo"
          step="1"
          (blur)="correctImporto()"
          [value]="generals.get('importo').value"
        />
      </mat-form-field>

      <mat-form-field>
        <input
          type="text"
          matInput
          placeholder="Riferimento (es: numero fattura)"
          formControlName="riferimento"
        />
      </mat-form-field>

      <mat-form-field>
        <mat-select
          formControlName="statopagamentoId"
          placeholder="Stato Pagamento"
        >
          <mat-option
            *ngFor="let status of paymentsStatuses"
            [value]="status.id"
          >
            {{ statoPagamentiLabels[status.id] }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <input
          (click)="picker.open()"
          formControlName="data_emissione"
          matInput
          (dateChange)="calculateExpirationDate(generals)"
          [matDatepicker]="picker"
          placeholder="Data di emissione"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

      <mat-form-field>
        <input
          type="number"
          min="1"
          matInput
          placeholder="Periodo di Verifica"
          formControlName="periodo_verifica"
          (change)="calculateExpirationDate(generals)"
        />
      </mat-form-field>

      <div class="info-ui">
        <mat-form-field>
          <input
            (click)="picker2.open()"
            [matDatepicker]="picker2"
            formControlName="data_scadenza"
            matInput
            placeholder="Data di Scadenza"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker2"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>

      </div>

      <div>
        <button
          class="if-btn btn-dark"
          [disabled]="!generals.valid"
          mat-button
          matStepperNext
        >
          Avanti
        </button>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="invoiceForm">
    <ng-template matStepLabel>FATTURA</ng-template>

    <app-if-invoice
      [generalFormValue]="generals.value"
      [client]="generals.get('denominazione').value"
      (formIsValid)="patchFatturaElettronica($event)"
      (formIsRequired)="invoiceFormIsRequired($event)"
      [riferimento]="riferimentoCredito"
    ></app-if-invoice>

    <div class="fattura-foot">
      <button
        class="if-btn btn-dark"
        matStepperNext
        [disabled]="invoiceForm.invalid"
      >
        Conferma e procedi
      </button>
    </div>
  </mat-step>

  <mat-step [stepControl]="smsForm">
    <form class="form-notifications" [formGroup]="smsForm">
      <ng-template matStepLabel>NOTIFICHE</ng-template>

      <app-credit-notifications
      (stop_notifiche)="patchStopNotifiche($event)"
      [credit]="generals.value"></app-credit-notifications>

      <div class="actions">
        <button class="if-btn btn-grey" mat-button matStepperPrevious>
          Indietro
        </button>
        <button
          mat-button
          matStepperNext
          (click)="submit()"
          class="if-btn btn-dark"
          [disabled]="smsForm.invalid"
        >
          Avanti
        </button>
      </div>
    </form>
  </mat-step>


</mat-horizontal-stepper>
