/**
 * @license
 * Copyright SMART SRL ®. Tutti i diritti riservati
 *
 * @author
 * Leonardo Maria Miliacca
 *
 * L'uso di questo codice è soggetto a copyright
 */
import { Pipe, PipeTransform } from '@angular/core';
import { TipoNotifica } from 'src/app/interfaces/tipo_notifica';
import { Modello } from 'src/app/interfaces/modello_messaggio';

@Pipe({name: 'receiveMsg'})
export class ReceiveMsgPipe implements PipeTransform {

  resolveDay(dataScadenza: Date, giorniExtraDaModello: number, dopo: boolean): string {
    const timestamp: number = dataScadenza.getTime();
    const millisecondsInaDay = 86400000;
    let newDate: Date;
    if (dopo) {
      // aggiungo giorni
      newDate = new Date(timestamp + (giorniExtraDaModello * millisecondsInaDay));
    } else {
      newDate = new Date(timestamp - (giorniExtraDaModello * millisecondsInaDay));
    }
    return `${newDate.getDate()}/${newDate.getMonth() + 1}/${newDate.getFullYear()}`;
  }

  transform(pseudoCredit: any, models: Modello[], notificationTypeId: string): string {
    const results: string[] = [];
    models
    .filter((m) => m.tiponotificaId === notificationTypeId)
    .map((model: Modello) => {

      const dataScadenza: Date = new Date(pseudoCredit.data_scadenza);
      const giorniExtraDaModello = model.giorni;
      const dopo: boolean = model.dopo_data;
      const resolvedDay = this.resolveDay(dataScadenza, giorniExtraDaModello, dopo);

      const string = `un messaggio il ${resolvedDay} `;
      results.push(string);
    });
    return results.join(', ');
  }
}
