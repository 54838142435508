/**
 * @license
 * Copyright SMART SRL ®. Tutti i diritti riservati
 *
 * @author
 * Leonardo Maria Miliacca
 *
 * L'uso di questo codice è soggetto a copyright
 */
import { ExpirationsService } from '../../../../services/expirations.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IFColumnType } from '../../../../interfaces/if-table-column';
import { IFTable } from '../../../../interfaces/if-table';
import { Credit } from '../../../../interfaces/credit';
import { Expiration } from '../../../../interfaces/expiration';

@Component({
  templateUrl: './expirations.component.html',
  styleUrls: ['./expirations.component.scss']
})
export class ExpirationsComponent implements OnInit {

  expirationsIFTable: IFTable = {
    columns: [{
      type: IFColumnType.text,
      label: 'Nome',
      sortable: true,
      name: 'nome',
      width: 5
    }, {
      type: IFColumnType.number,
      label: 'GG Preavviso',
      sortable: true,
      name: 'giorni_preavviso',
      width: 5
    }, {
      type: IFColumnType.boolean,
      label: 'Email?',
      sortable: true,
      name: 'email_flag',
      width: 5
    }, {
      type: IFColumnType.boolean,
      label: 'Pec?',
      sortable: true,
      name: 'pec_flag',
      width: 5
    }, {
      type: IFColumnType.boolean,
      label: 'SMS?',
      sortable: true,
      name: 'sms_flag',
      width: 5
    }],
    perPage: 10
  };

  expirations: Expiration[];

  constructor(
    private expirationsService: ExpirationsService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.expirationsService.getExpirationsList()
    .subscribe((expirations: Expiration[]) => {
      this.expirations = expirations;
    }, error => {
      // TODO handle error
    });
  }
}
