import { Client } from '../../interfaces/client';
import { ClientsService } from '../../services/clients.service';
import { IFTable } from '../../interfaces/if-table';
import { Component, OnInit } from '@angular/core';
import { IFColumnType } from '../../interfaces/if-table-column';

@Component({
  templateUrl: './dev.component.html',
  styleUrls: ['./dev.component.scss']
})
export class DevComponent implements OnInit {

  myIfTable: IFTable;
  myIfDocument: Client[];
  map: string[] = ['name', 'status', 'number', 'date', 'due'];
  constructor(
    private clientsService: ClientsService
  ) {
  }

  ngOnInit(): void {
    this.myIfTable = {
      columns: [{
        type: IFColumnType.text,
        label: 'Denominazione',
        sortable: true,
        name: 'denominazione'
      }, {
        type: IFColumnType.text,
        label: 'Sede',
        sortable: true,
        name: 'sede'
      }, {
        type: IFColumnType.text,
        label: 'codice_fiscale',
        sortable: true,
        name: 'codice_fiscale'
      }, {
        type: IFColumnType.text,
        label: 'email',
        sortable: true,
        name: 'email'
      }, {
        type: IFColumnType.text,
        label: 'pec',
        sortable: true,
        name: 'pec'
      }, {
        type: IFColumnType.text,
        label: 'cellulare',
        sortable: true,
        name: 'cellulare'
      }],
      perPage: 10
    };


  }
}
