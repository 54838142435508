import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { XMLInvoice, Credit } from '../interfaces/credit';
import { FatturaElettronicaBody, DettaglioLinee } from '../interfaces/fatturazione_elettronica/fattura_semplificata';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CreditsService } from './credits.service';
import { HttpClient } from '@angular/common/http';
import { API_DOMAIN, API_VERSION } from '../constants/server';
import { UtilsService } from './utils.service';
import { FlashService } from './notifications.service';

@Injectable()
export class InvoiceService {
  private _currentlySelectedInvoice = new Subject<XMLInvoice|null>();
  public currentlySelectedInvoice$ = this._currentlySelectedInvoice.asObservable();
  public currentlySelectedInvoice: XMLInvoice;

  private _nature = new Subject<{name: string, value: {[key: string]: string}}>();
  public nature$ = this._nature.asObservable();
  public nature: {name: string, value: {[key: string]: string}};

  public relatedCreditId: string;

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private us: UtilsService,
    private fs: FlashService
  ) {}

  getNature(): Observable<{name: string, value: {[key: string]: string}}> {
    return this.http.get<{name: string, value: {[key: string]: string}}>(
      `${API_DOMAIN}/${API_VERSION}/Lookup/Natura`
      );
  }

  setNature(nature: {name: string, value: {[key: string]: string}}): void {
    this._nature.next(nature);
    this.nature = nature;
  }

  setCurrentlySelectedInvoice(xml: XMLInvoice, creditId: string): void {
    this.relatedCreditId = creditId;
    this.currentlySelectedInvoice = xml;
    this._currentlySelectedInvoice.next(xml);
    console.warn(this.currentlySelectedInvoice);
  }

  patchInvoice(invoice: XMLInvoice, creditId: string): void {
    invoice = this.us.recursivelyStripToxicFields(invoice, ['prezzoLordoTotale', 'prezzoNettoTotale', 'totaleIVA']);
    this.http.patch(`${API_DOMAIN}/${API_VERSION}/Crediti/${creditId}`, {
      xml: invoice
    }).subscribe((response: Credit) => {
      this.fs.emitOKNotification('Fattura Salvata');
      console.log('SALVATO', response);
    });
  }

  saveCurrentInvoice(invoice: FatturaElettronicaBody[]): void {
    // con questo chiudo il menu
    this._currentlySelectedInvoice.next(null);
    Object.assign(this.currentlySelectedInvoice, invoice);
    this.patchInvoice(this.currentlySelectedInvoice, this.relatedCreditId);
  }

  buildInvoiceForm(importValue?: number): FormGroup {
    return this.fb.group({
      FatturaElettronicaBody: this.fb.array([this.buildFatturaElettronicaBody(importValue)])
    });
  }

  buildFatturaElettronicaBody(importValue?: number): FormGroup {
    let lines: FormGroup[];

    if (this.currentlySelectedInvoice) {
      if (this.currentlySelectedInvoice.FatturaElettronicaBody) {

        lines = this.currentlySelectedInvoice.FatturaElettronicaBody[0]
        .DatiBeniServizi.DettaglioLinee.map((linea: DettaglioLinee, index: number) => {
          return this.addInvoiceProduct(index);
        });
      } else {
        this.currentlySelectedInvoice.FatturaElettronicaBody = [];
        lines = [this.addInvoiceProduct(0)];

      }
    } else {
      lines = [this.addInvoiceProduct(0)];
    }

    return this.fb.group({
      totaleIVA: [null],
      prezzoLordoTotale: [null],
      prezzoNettoTotale: [null],
      DatiGenerali: this.fb.group({
        DatiGeneraliDocumento: this.fb.group({
          TipoDocumento: [null, Validators.required], // da valutare enum
          Divisa: ['EUR', Validators.required], // enum da creare
          Data: [null, Validators.required], // ISO Date in realtà, formato ISO 8601:2004
          Numero: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(20)]],
          ImportoTotaleDocumento: [null]
        }),
      }),
      DatiBeniServizi: this.fb.group({
        DatiRiepilogo: this.fb.group({
          Natura: [null],
          EsigibilitaIVA: [null]
        }),
        DettaglioLinee: this.fb.array(lines)
      }),
      DatiPagamento: this.fb.array([
        this.fb.group({
          CondizioniPagamento:  [null],
          DettaglioPagamento: this.fb.group({
            ModalitaPagamento: [null],
            ImportoPagamento: [importValue],
            Beneficiario: [null],
            IBAN: [null],
            IstitutoFinanziario: [null],

          })
        })
      ])
    });
  }

  addInvoiceProduct(productIndex: number): FormGroup {
    const fg: FormGroup = this.fb.group({
      NumeroLinea: [productIndex + 1, [Validators.minLength(0), Validators.maxLength(4), Validators.required]],
      Descrizione: ['', [Validators.required, Validators.maxLength(1000)]],
      PrezzoUnitario: [null, Validators.required],
      UnitaMisura: [null],
      DataInizioPeriodo: [null],
      DataFinePeriodo: [null],
      Causale: [],
      PrezzoTotale: [null, Validators.required],
      AliquotaIVA: [22, Validators.required],
      Natura: [null],
      Quantita: [1, Validators.min(1)],
      ScontoMaggiorazione: this.fb.group({
        Tipo: [null],
        Percentuale: [null],
        Importo: [null]
      }),
      totaleIVA: []
    });
    return fg;
  }

  setListenersForInvoiceProducts(): Function {
    return function(fg: FormGroup) {
      ['PrezzoUnitario', 'Quantita', 'AliquotaIVA', 'Descrizione'].map((key) => {
        fg.get(key).valueChanges.subscribe((newQty: number) => {
          this.calculateIVAAndTotal();
          fg.updateValueAndValidity();
          if (this.datiBeniServiziForm.valid) {
            const accumulator = {totaleIVA: 0, prezzoLordoTotale: 0, prezzoNettoTotale: 0};
            this.datiBeniServiziForm.controls.map((fgg: FormGroup) => {
              accumulator.totaleIVA = accumulator.totaleIVA + fgg.get('totaleIVA').value;
              accumulator.prezzoLordoTotale = accumulator.prezzoLordoTotale + fgg.get('PrezzoTotale').value;
              accumulator.prezzoNettoTotale =
              accumulator.prezzoNettoTotale + (fgg.get('PrezzoUnitario').value * fgg.get('Quantita').value);
            });
            this.fatturaElettronicabodyArray.controls[0].patchValue(accumulator);
          }
        });
      });
    }
  }
}
