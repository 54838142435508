import { GeoService } from '../services/geo.service';
import { HereApiResult } from '../interfaces/responses/here_api_results';
import { FormGroup } from '@angular/forms';

/**
 * Questa classe condivide metodi per la geolocalizzazione,
 * è condivisa da IFFormBlockComponent e ClientComponent ,
 * perché in entrambi i casi l'utente può modificare il CAP di un'entità.
 */
export class IFGeoClass {

  NUMBERS_ONLY = new RegExp(/^[0-9]+$/);

  /**
   * Se è vero, significa che la geolocalizzazione automatica
   * non ha funzionato, l'utente deve pertanto inserire a mano.
   */
  cantFindZIP: boolean;

  /**
   * Questo flag serve a indicare se il CAP immesso ha trovato o meno un risultato
   */
  citySpotted: string;

  constructor(
    public geo: GeoService
  ) {

  }

  automaticallyPopulateGeoFields(event: any, sedeFormGroup: FormGroup, country: string = 'IT'): void {
    console.log('4event', country);
    const ZIPCode: string = event.target.value;
    console.log('zipcode', ZIPCode);
    if (ZIPCode.length === 5) {
      const geocoder = this.geo.buildGeoCoder();
      geocoder.search(
        {searchText: `${country} ${ZIPCode}`},
        (data: HereApiResult) => {
          console.log('data found!', data);
          const city: string = data.Response.View[0].Result[0].Location.Address.City;
          const province = data.Response.View[0].Result[0].Location.Address.County;
          console.log('spotted', city);
          if (city) {
            sedeFormGroup.patchValue({
              Comune: city,
              Provincia: province
            });
            this.cantFindZIP = false;
            this.citySpotted = `${city} (${province})`;
          } else {
            this.cantFindZIP = true;
          }
        },
        (ko) => {
          this.citySpotted = null;
        }
      );

    }

  }
}
