import { KEYWORDS_URL } from 'src/app/constants/keywords';
import { Component, Input } from '@angular/core';
import { ModalManagerService } from '../../services/modal-manager.service';

@Component({
  selector: 'app-if-purchase-btn',
  templateUrl: './if-recharge-btn.component.html',
  styleUrls: ['./if-recharge-btn.component.scss']
})
export class IFRechargeButtonComponent {
  @Input() keyword: string;

  constructor(
    private mms: ModalManagerService
  ) {}

  openModal(keyword: string): void {
    this.mms.setNewModel(keyword);
  }


}
