<div class="filter" [formGroup]="filterForm" *ngIf="textSearchableFields">
  <mat-form-field *ngFor="let textSearchable of textSearchableFields">
    <input
    [formControlName]="textSearchable"
    [placeholder]="'Filtra per ' + (textSearchable | nameToLabel: ifTable.columns)"
    matInput/>

  </mat-form-field>


  <ng-container *ngFor="let numerableField of numberFilterableFields">
    <mat-form-field>
      <input
      [formControlName]="numerableField"
      type="number"
      placeholder="Filtra per {{numerableField}}"
      matInput/>
      <mat-hint>
        Maggiore di...
      </mat-hint>
    </mat-form-field>
  </ng-container>

  <ng-container *ngFor="let boolField of boolFilterableFields">
      <mat-checkbox
      [formControlName]="boolField"
      >
      {{(boolField | nameToLabel: ifTable.columns)}}
      </mat-checkbox>

  </ng-container>

</div>

  <ng-container *ngIf="ifDocument.length === 0">
      <div class="no-items">
        <div class="no-elements">
          Nessun risultato
        </div>
      </div>
  </ng-container>

  <table
  *ngIf="ifDocument.length > 0"
  class="debug-tab"
  [ngClass]="{
  'querying': querying
  }"
  [ngStyle]="
    {
      'width': ifTable.width ? (ifTable.width + '%') : 'auto'
    }">
    <thead>
      <ng-container *ngFor="let column of ifTable.columns">
        <th
        *ngIf="column.sortable"
        (click)="sortBy(column)"
        [ngStyle]="{
          'width': column.width ? (column.width + '%') : 'auto'
        }">
        <mat-icon *ngIf="column.selected && ascendingOrder">arrow_upward</mat-icon>
          <mat-icon *ngIf="column.selected && !ascendingOrder">arrow_downward</mat-icon>
          {{column.label}}
          <div
          class="sortable"
          [ngClass]="{
            'selected': column.selected,
            'asc': ascendingOrder,
            'desc': !ascendingOrder
          }"
          >
          </div>
        </th>
        <th
        [ngStyle]="{
          'width': column.width ? (column.width + '%') : 'auto'
        }"
        *ngIf="!column.sortable">
          {{column.label}}
        </th>
      </ng-container>
      <!--
      <th>
        <button class="if-btn btn-head">
          Esporta
        </button>
      </th>
      -->
    </thead>

    <tbody *ngIf="ifDocument && ifDocument.length > 0">
      <tr
      (mouseover)="showDetailOf(entry)"
      [ngClass]="{'hovered': entry.IFTABLE_HOVER}"
      *ngFor="let entry of ifDocument">
        <td
        (click)="column.linked ? show(entry) : null"
        *ngFor="let column of ifTable.columns">
          <ng-container [ngSwitch]="column.type">
            <ng-container *ngSwitchCase="'select'">
                <select (change)="selectTriggered($event, entry, column.name)">
                  <option
                    *ngFor="let option of column.options"
                    [value]="option.id"
                    [selected]="entry[column.name] === option.nome_stato"
                    >
                    {{option.nome_stato}}
                  </option>
                </select>
            </ng-container>
            <ng-container *ngSwitchCase="'currency'">
              {{entry[column.name] | currency:'€'}}
            </ng-container>
            <ng-container *ngSwitchCase="'contacts'">
              <ng-container *ngIf="notificationsTypes">
                <ng-container *ngFor="let contact of entry[column.name]">
                    <i [class]="IFContactsPlatforms[contact| friendlyContact : notificationsTypes]"></i>
                </ng-container>
              </ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'text'">
              {{entry[column.name]}}
            </ng-container>
            <ng-container *ngSwitchCase="'date'">
              <ng-container *ngIf="entry[column.name]">
                {{entry[column.name] | friendlyDate}}
              </ng-container>
              <ng-container *ngIf="!entry[column.name]">
                -
              </ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'status'">
              <div
              class="color-block"
              [ngClass]="{
                'open': entry[column.name] === 'open',
                'verify': entry[column.name] === 'verify',
                'paid': entry[column.name] === 'paid',
                'unpaid': entry[column.name] === 'unpaid',
                'deleted': entry[column.name] === 'deleted'
              }"
              ></div>
            </ng-container>
            <ng-container *ngSwitchCase="'number'">
              {{entry[column.name]}}
            </ng-container>
            <ng-container *ngSwitchCase="'icon'">
              {{entry[column.name]}}
            </ng-container>

            <ng-container *ngSwitchCase="'button'">
              <button (click)="castAction.emit(entry)" color="primary" mat-mini-fab>
                <mat-icon>remove_red_eye</mat-icon>
              </button>
            </ng-container>
            <ng-container *ngSwitchCase="'rating'">
              <div class="stars">
                <div class="star" *ngFor="let r of RATING">
                  <mat-icon [ngClass]="{'active': entry[column.name] > r - 1}">star_border</mat-icon>
                </div>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'boolean'">
              <mat-icon *ngIf="entry[column.name]">done</mat-icon>
              <mat-icon *ngIf="!entry[column.name]">close</mat-icon>
            </ng-container>
            <ng-container *ngSwitchCase="'activeBoolean'">
              <mat-checkbox [checked]="entry[column.name]"></mat-checkbox>
            </ng-container>
          </ng-container>
        </td>
        <!--
        <td class="actions">
          <span
            class="if-btn-narrow btn-dark"
            (click)="show(entry)"
          >
            <img src="assets/svg/eye.svg" alt="">
        </span>

        <span
            class="if-btn-narrow btn-danger"
            (click)="castErase(entry)"
          >
            <img src="assets/svg/garbage.svg" alt="">
        </span>
        </td>
        -->
      </tr>
    </tbody>
  </table>

<div class="pager">
  <div class="counter">

    <strong>{{totalElements}}</strong> risultati<br />
    pagina <strong>{{currentPage + 1}}</strong> di <strong>{{totalElements % 10 > 0 ? (((totalElements / 10)).toFixed(0))  : ((totalElements/10) + 1)}}</strong>
  </div>
  <ng-container *ngIf="pages.length < 7">
    <ng-container *ngFor="let page of pages; let i = index">
      <div
      class="page"
      (click)="changePageForFiltered(i)"
      [ngClass]="{'selected': currentPage === i}"
      >
        {{i+1}}
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="pages.length >= 7">

    <div class="page forward" (click)="changePage(currentPage, 3, false)">
      <<
    </div>

    <div
    *ngIf="currentPage > 1"
    class="page"
    (click)="changePage(currentPage, -2, true)"
    >
    {{currentPage - 1}}
    </div>

    <div
    *ngIf="currentPage > 0"
    class="page"
    (click)="changePage(currentPage, 1, false)"
    >
      {{currentPage}}
    </div>
    <div
    class="page selected"
    >
      {{currentPage + 1}}
    </div>
    <div
    *ngIf="currentPage < pages.length - 1"
    class="page"
    (click)="changePage(currentPage, 1, true)"
    >
    {{currentPage + 2}}
    </div>

    <div
    *ngIf="currentPage < pages.length - 2"
    class="page"
    (click)="changePage(currentPage, 2, true)"
    >
    {{currentPage + 3}}
    </div>

    <div class="page fast-forward" (click)="changePage(currentPage, 3, true)">
      >>
    </div>
  </ng-container>
</div>
