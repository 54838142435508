export enum IFXMLImportValidationResponseCodes {
  validXML = 'validXML',
  invalidXML = 'invalidXML'
}

export interface IFXMLImportValidationResponse {
  info: string; // 'esito validazione: validXML / invalidXML',
  code: IFXMLImportValidationResponseCodes; // 'validXML',
  detail: string; // '..qui descriviamo cosa va in errore..',
  err: number;
}
