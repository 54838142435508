/**
 * @license
 * Copyright SMART SRL ®. Tutti i diritti riservati
 *
 * @author
 * Leonardo Maria Miliacca
 *
 * L'uso di questo codice è soggetto a copyright
 */
import { Injectable, ViewContainerRef, ElementRef } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HERE_APP_ID, HERE_APP_CODE } from '../constants/here.com/config';
import { HereApiResult } from '../interfaces/responses/here_api_results';
import { IFNation } from '../interfaces/if_nation';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { API_DOMAIN } from '../constants/server';
import { Subject } from 'rxjs';

declare const H: any;

@Injectable()
export class GeoService {

  HerePlatform: any;

  nations: IFNation[];
  private _nations = new Subject<IFNation[]>();
  public nations$ = this._nations.asObservable();

  attr: string;

  constructor(
    private http: HttpClient
  ) {
    this.HerePlatform = new H.service.Platform({
      app_id: HERE_APP_ID,
      app_code: HERE_APP_CODE,
      useHTTPS: true
    });
    console.log('HERE PLATFORM', this.HerePlatform);
  }

  returnFunction(data: HereApiResult): {Provincia: string, Comune: string} {
      const city: string = data.Response.View[0].Result[0].Location.Address.City;
      const province = data.Response.View[0].Result[0].Location.Address.County;
      return {
        Provincia: province,
        Comune: city
      };
  }

  buildGeoCoder(): any {
    return this.HerePlatform.getGeocodingService();
  }

  getNations(): Observable<{value: IFNation[]}> {
    return this.http.get<{value: IFNation[]}>(`${API_DOMAIN}/v1/Lookup/Nazioni`);
  }

  setNations(nations: IFNation[]): void {
    this.nations = nations;
    this._nations.next(nations);
  }

}

