/**
 * @license
 * Copyright SMART SRL ®. Tutti i diritti riservati
 *
 * @author
 * Leonardo Maria Miliacca
 *
 * L'uso di questo codice è soggetto a copyright
 */
import { NotificationType } from '../interfaces/notifications_type';
import { Expiration } from '../interfaces/expiration';
import { Credit } from '../interfaces/credit';
import { EXPIRATIONS_KEYWORD } from '../constants/keywords';
import { API_DOMAIN, API_VERSION } from '../constants/server';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class ExpirationsService {

  notificationTypes: NotificationType[];

  constructor(
    private http: HttpClient
  ) {}

  // DEV
  getExpirationsList(): Observable<Expiration[]> {
    return this.http.get<Expiration[]>(`${API_DOMAIN}/${API_VERSION}/${EXPIRATIONS_KEYWORD}`);
  }

  setNotificationsType(): void {
    this.http.get<NotificationType[]>(`${API_DOMAIN}/${API_VERSION}/TipiNotifica`)
    .subscribe((res: NotificationType[]) => {
      this.notificationTypes = res;
    }, error => {
      // TODO handle errors
    });
  }

}

