import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { IFPageLoadComponent } from './if-page-load.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
@NgModule({
  declarations: [
    IFPageLoadComponent
  ],
  exports: [
    IFPageLoadComponent
  ],
  imports: [
    MatProgressSpinnerModule,
    CommonModule
  ]
})
export class IFPageLoadModule {}
