import { NgModule } from '@angular/core';
import { IFTableComponent } from './if-table.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { IFPipesModule } from 'src/app/pipes/if-pipes.module';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NameToLabelPipe } from './name-to-label.pipe';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [
    IFTableComponent,
    NameToLabelPipe
  ],
  imports: [
    MatCheckboxModule,
    IFPipesModule,
    MatButtonModule,
    FormsModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    CommonModule,
    MatIconModule
  ],
  exports: [
    IFTableComponent
  ]
})
export class IFTableModule {}
