import { ModalManagerService } from 'src/app/services/modal-manager.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ClientsService } from './../../services/clients.service';
import { HelperService } from './../../services/helper.service';
import { MessagesService } from './../../services/messages.service';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { CreditsService } from '../../services/credits.service';
import { TipoNotifica, IFBodyType } from '../../interfaces/tipo_notifica';
import { Subject, Observable } from '../../../../node_modules/rxjs';
import 'rxjs/add/operator/map';
import { Client } from '../../interfaces/client';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatSelectChange } from '@angular/material/select';
import { CK_EDITOR_CONFIG } from '../../constants/configs';
import { IFSendMessageRequest } from '../../interfaces/requests/send_message_request';
import { UtilsService } from '../../services/utils.service';
import { IFSendMessageResponse } from '../../interfaces/responses/if_send_message_response';
import { FlashService } from '../../services/notifications.service';
import { IFFlash, IFFlashType } from '../../interfaces/if-notification';
import { ClientContact } from 'src/app/interfaces/client_contact';
import { debounceTime } from 'rxjs/operators';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-if-send-message',
  templateUrl: './if-send-message.component.html',
  styleUrls: ['./if-send-message.component.scss']
})
export class IFSendMessageComponent implements OnInit {

  @Output() msgsSent: EventEmitter<boolean> = new EventEmitter();
  public Editor = ClassicEditor;

  error: string;
  ERROR_CODES = {
    failCredit: 'Credito non sufficiente',
    failSend: 'Invio non riuscito, problema tecnico, riprovare più tardi',
    failMessage: 'Messaggio non valido',
    failRecipients: 'Destinatari non validi',
  };

  BODY_TYPES = IFBodyType;

  GLOBAL_CHECKER = '_globalChecker';
  CKEditorConfig = CK_EDITOR_CONFIG;

  notificationsTypes: TipoNotifica[];
  selectedNotificationType: TipoNotifica;
  clients: Client[] = [];
  selectedClients: Client[] = [];

  msgForm: FormGroup;

  selectAll = true; // serve solo per la option apposita
  sending = false;

  loading = false;

  constructor(
    private crs: CreditsService,
    private ms: MessagesService,
    private mms: ModalManagerService,
    private hs: HelperService,
    private fs: FlashService,
    private cs: ClientsService,
    private fb: FormBuilder,
    private us: UtilsService
  ) {

    this.msgForm = this.fb.group({
      type: [null, Validators.required],
      clientIds: [[], Validators.required],
      subject: [''],
      message: ['', Validators.required],
      filter: ['']
    });

  }

  get mySearchLabel(): string {
    let stringToReturn: string;
    const nt: TipoNotifica = this.notificationsTypes.find((notificationType: TipoNotifica) => {
      return this.msgForm.value.type === notificationType.id;
    }) || this.notificationsTypes[0];
    switch (nt.nome) {
      case 'sms':
      stringToReturn = 'Filtra per nome o numero di telefono';
      break;
      case 'pec':
      stringToReturn = 'Filtra per nome o pec';
      break;
      case 'email':
      stringToReturn = 'Filtra per nome o indirizzo email';
      break;
    }
    return stringToReturn;
  }

  unselectClient(sc: Client) {
    sc.selected = !sc.selected;
  }

  get currentMessageType(): string {
    const value: string|null = this.msgForm.controls.type.value;
    if (value) {
      const nt: TipoNotifica = this.notificationsTypes.find((n: TipoNotifica) => {
        return n.id === value;
      });
      return nt.tipo_corpo;
    } else {
      return '';
    }
  }

  get columnRelatedToNotificationType(): string {
    const nt: TipoNotifica = this.notificationsTypes.find((notificationType: TipoNotifica) => {
      return this.msgForm.value.type === notificationType.id;
    }) || this.notificationsTypes[0];
    return nt.id;
  }

  get howManyContacts(): number {
    return this.selectedClients.filter(c => c.selected).length;
  }

  get SMSContentLength(): number {
    return this.msgForm.get('message').value.length;
  }

  get howManySMS(): number {
    return Math.floor(this.msgForm.get('message').value.length / 160) + 1;
  }

  pickRightColor(SMSContentLength: number): string {
    const initialRealNumber = SMSContentLength > 160 ? (SMSContentLength - (160 * Math.floor(SMSContentLength / 160))) : SMSContentLength;
    let color;
    const red = (255 * initialRealNumber) / 160;
    color = `rgb(${red}, 0,0)`;
    return color;
  }

  ngOnInit(): void {

    this.notificationsTypes = this.ms.notificationsTypes;

    if (this.mms.modelSubject === 'invia') {
      console.log(this.mms.valuePatcher.client);
      this.selectClient(this.mms.valuePatcher.client);
      const notificationType: TipoNotifica = this.ms.notificationsTypes
      .find((obj: TipoNotifica) => {
        return obj.nome === this.mms.valuePatcher.notificationType.nome;
      }) || this.notificationsTypes[0];

      this.msgForm.patchValue({type: notificationType.id});
      this.selectedNotificationType = notificationType;
      this.mms.resetValuePatcher();
    } else {
      this.msgForm.patchValue({
        type: this.notificationsTypes[0].id
      });
      this.selectedNotificationType = this.notificationsTypes[0];
    }
    this.correctlySetFieldsFromSelection(this.selectedNotificationType);

    this.msgForm.get('filter')
    .valueChanges
    .pipe(
      debounceTime(500)
    )
    .subscribe((typed) => {
      this.selectedClients =
      this.selectedClients
      .filter((sc: Client) => {
        return sc.selected;
      });
      this.cs.getClientsList(
        10, 0, 'UINome ASC', {UINome: {like: typed , options: 'i'}}
      )
      .subscribe((clients: Client[]) => {

        this.clients = clients;
      });
    });
  }

  sendMessage(): void {
    const values = this.msgForm.value;
    const mean: TipoNotifica = this.notificationsTypes.find((nt: TipoNotifica) => {
      return nt.id === values.type;
    });
    const clientsIds: string[] = values.clientIds;
    const myPayload: IFSendMessageRequest = {
      titolo: values.subject,
      destinatari: clientsIds,
      messaggio: values.message
    };
    this.ms.sendMessage(mean, myPayload)
    .subscribe((response: IFSendMessageResponse) => {
      if ((response.code === 'smsSent') || (response.code === 'emailSent')) {
        const n: IFFlash = {
          type: IFFlashType.check_circle_outline,
          text: 'Messaggi inviati!',
          time: 3000,
          id: 'msgs-sent'
        };
        this.fs.emitNotification(n);
        this.msgsSent.emit(true);
      }
    }, err => {
      this.error = this.ERROR_CODES[err['error']['code']];
    });
  }

  get isEmailOrPec(): boolean {
    let boolean: boolean;
    const selectedNt: TipoNotifica = this.notificationsTypes.find((nt: TipoNotifica) => {
      return nt.id === this.msgForm.get('type').value;
    });
    if (selectedNt) {
      switch (selectedNt.nome) {
        case 'email':
        case 'pec':
        boolean = true;
        break;
        default:
        boolean = false;
        break;
      }
    } else {
      return false;
    }
    return boolean;
  }

  get isSubjectPresent(): boolean {
    return this.msgForm.controls.subject ? true : false;
  }

  get currentlyWroteName(): string {
    return this.msgForm.value.filter;
  }

  selectClient(client: Client): void {
    client.selected = !client.selected;
    let currentArrayOfIds: string[] = this.msgForm.value.clientIds;
    if (client.selected) {
      currentArrayOfIds.push(client.id);
      this.selectedClients.push(client);
      this.selectedClients = this.selectedClients;
      this.clients = this.clients.filter(c => c.id !== client.id );

    } else {
      currentArrayOfIds = currentArrayOfIds.filter((id: string) => {
        return id !== client.id;
      });
      this.selectedClients = this.selectedClients.filter(c => c.id !== client.id);
    }

    this.msgForm.patchValue({clientIds: currentArrayOfIds});
  }

  presetTitleAndMessage(event: MatSelectChange): void {
    const tnId: string = event.value;
    const nt: TipoNotifica = this.notificationsTypes.find((t: TipoNotifica) => t.id === tnId);
    this.correctlySetFieldsFromSelection(nt);
    this.filterClients(nt);
  }

  filterClients(notificationType: TipoNotifica): void {
    console.log('clients are now', this.cs.clients);
    this.clients = Object.assign([], this.cs.clients.filter((c: Client) => {
      return !!c.contatti.find((cl: ClientContact) => {
        return cl.tiponotificaId === notificationType.id;
      });
    }));
  }

  correctlySetFieldsFromSelection(nt: TipoNotifica): void {
    if (nt.nome === 'sms') {
      this.msgForm.removeControl('subject');
    } else {
      this.msgForm.addControl('subject', new FormControl(null, Validators.required));
    }
    this.selectedNotificationType = nt;
    this.msgForm.patchValue({
      message: ''
    });
  }

  sendTestEmail(): void {
    this.sending = true;
    const payload = {
      messaggio: this.msgForm.get('message').value
    };
    this.ms.sendTestMessage(payload)
    .subscribe((response) => {
      console.log('yo', response);
      this.sending = false;
    }, err => {
      // TODO catch error
      console.log(err);
      this.sending = false;
    });
  }
}
