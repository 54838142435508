import { FormGroup } from '@angular/forms';

export enum IFFormBlockInputTypes {
  anagrafica = 'anagrafica',
  sede = 'sede',
  fiscale = 'fiscale'
}

export interface IFFormBlockInput {
  type: IFFormBlockInputTypes;
  formGroup: FormGroup;
}
