<h2>IMPORTA {{TYPES[typeOfImport]}}</h2>

<form [formGroup]="uploadForm">
<div class="dropzone-container">
  <div class="dropzone-wrapper">
    <div
    class="dropzone"
    id="drop_zone"
    (drop)="uploadForm.valid ? dropHandler($event) : null"
    (dragover)="uploadForm.valid ? allowDrop($event) : null"
    (click)="uploadForm.valid ? uploader.click() : null"
    [ngClass]="
    {'error': correctWhiteSpace}"
    >
    <ng-container *ngIf="!loading && !success && !uploadForm.valid">
      <h3>Separatore non corretto</h3>
      <h4>Usa un qualsiasi carattere</h4>
      <h5>tranne gli spazi</h5>
    </ng-container>
      <ng-container *ngIf="!loading && !success && uploadForm.valid">
        <h3>Trascina qui un file</h3>

        <ng-container *ngIf="!noFiles">
          <h4>Oppure clicca per scegliere dalle cartelle</h4>
        </ng-container>
        <ng-container *ngIf="noFiles">
          <h4>File da caricare: {{myFile}}</h4>
        </ng-container>

        <h5>Dimensioni massime del file <strong>5mb</strong></h5>
      </ng-container>
      <ng-container *ngIf="!loading && success">
        <div class="icon">
          <mat-icon>check_circle_outline</mat-icon>
        </div>
      </ng-container>

      <ng-container *ngIf="loading && !success">
        <mat-spinner [strokeWidth]="1"></mat-spinner><br />
        {{progress}}%
      </ng-container>
      <input
      (change)="startProcess()"
      accept=".xlsx, .xls, .csv"
      type="file"
      #uploader
      style='display:none'>
    </div>
  </div>
  <div class="separator">
    Separatore da utilizzare*
    <mat-form-field>
      <input type="text" matInput formControlName="separator">
    </mat-form-field>

    <span
    [ngClass]="{
      'error': correctWhiteSpace
    }">
    puoi usare qualsiasi carattere tranne gli spazi
    </span>
  </div>
  <div class="notify">
    <div class="text">
      Avvisami quando l'elaborazione del file inviato sarà finita
      <br />
      <mat-checkbox formControlName="email">Tramite Email</mat-checkbox>
    </div>
    <div class="text second">
      <button
      [disabled]="uploadForm.invalid || !noFiles"
      type="button"
      class="if-btn btn-dark" (click)="uploadChosenFile()">Avvia Upload</button>
    </div>
  </div>
</div>

<div *ngIf="msg.text" [ngClass]="{'warning': msg.status === 'warning'}">
  {{msg.text}}
</div>
</form>
