import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'logout-dialot',
  templateUrl: './logout-dialog.html',
  styleUrls: [
    './logout-dialog.scss'
  ]
})
export class LogoutDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<LogoutDialogComponent>
  ){}

  clickBtn(val: boolean): void {
    this.dialogRef.close(val);
  }

}
