import { NgModule } from '@angular/core';
import { IFFileRowComponent } from './if-file-row.component';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [
    IFFileRowComponent
  ],
  imports: [
    CommonModule,
    MatIconModule
  ],
  exports: [
    IFFileRowComponent
  ]
})
export class IFFileRowModule {}
