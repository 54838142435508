/**
 * @license
 * Copyright SMART SRL ®. Tutti i diritti riservati
 *
 * @author
 * Leonardo Maria Miliacca
 *
 * L'uso di questo codice è soggetto a copyright
 */
import { Pipe, PipeTransform } from '@angular/core';
import { Modello } from 'src/app/interfaces/modello_messaggio';
import { FormControl } from '@angular/forms';

@Pipe({name: 'selectedModelsForNt'})
export class SelectedModelsForNotificationTypePipe implements PipeTransform {
  transform(selectedModelsIds: string[], models: Modello[]): Modello[] {
    return models.filter((m: Modello) => {
      return selectedModelsIds.includes(m.id);
    });
  }
}
