
/**
 * Questo service nasce con lo scopo di delegare i lavori più pesanti
 * ai Worker (https://developer.mozilla.org/it/docs/Web/API/Worker) che eseguono i calcoli, computazioni e filtri su un
 * altro thread.
 * @copyright SMART S.R.L.
 * @author Leonardo Maria Miliacca
 */
import { Injectable } from '@angular/core';
import { Client, Anagrafica } from '../interfaces/client';
import { ClientsService } from './clients.service';
import { HttpClient } from '@angular/common/http';
import { TipoNotifica } from '../interfaces/tipo_notifica';
import { ClientContact } from '../interfaces/client_contact';
import { Credit } from '../interfaces/credit';
import { IFNumberFilterCriteria, IFFilterCriteria } from '../interfaces/if_filter';

const BLOBS = {
  test: `function Worker() {
  this.onmessage = function(e) { console.log('Worker received message', e);
  postMessage(someMessage); } }`
};

@Injectable()
export class WorkersService {

  constructor(
  ) {}

  getMinAndMaxFromArray(): Worker {
    const workerFunction = () => {
      onmessage = (data: any) => {
        const arrayOfObjects: any[] = data.data.assets;
        const fieldOfInterest: string = data.data.fieldOfInterest;

        const onlyValues: number[] = arrayOfObjects
          .map(obj => {
            if (typeof obj[fieldOfInterest] === 'number') {
              return obj[fieldOfInterest];
            }
          })
          .filter(obj => obj); // elimino gli undefined

        const result: { min: number; max: number } = {
          min: +Math.min(...onlyValues).toFixed(0),
          max: +Math.max(...onlyValues).toFixed(0)
        };
        postMessage.bind(self)(['type', result]);
      };
    };
    const dataObj = '(' + workerFunction + ')();';

    const blob = new Blob([dataObj]);
    const blobURL = URL.createObjectURL(blob);
    return new Worker(blobURL);
  }

  searchDoc(): Worker {
    const workerFunction = () => {


      onmessage = (orderedData: any) => {

        function flattenObject(ob: any) {
          const toReturn = {};
          for (const i in ob) {
            if (!ob.hasOwnProperty(i)) {
              continue;
            }
            if (ob[i] instanceof Array) {
              toReturn[i] = ob[i].join(', ');
              continue;
            }
            if (typeof ob[i] === 'object' && ob[i] !== null) {
              const flatObject = flattenObject(ob[i]);
              for (const x in flatObject) {
                if (!flatObject.hasOwnProperty(x)) {
                  continue;
                }
                toReturn[i + '.' + x] = flatObject[x];
              }
            } else {
              toReturn[i] = ob[i];
            }
          }
          return toReturn;
        }

        function recursivelySearch(
          term: string,
          array: any[],
          searchableFields: string[]
        ): any[] {
          const resultsIn: any[] = [];

          const myRegexp: RegExp = new RegExp(term, 'ig');

          array.map((obj: any) => {
            const newObj = flattenObject(obj);
            for (const key of searchableFields) {
              if (newObj[key]) {
                if (newObj[key].match(myRegexp)) {
                  resultsIn.push(obj);
                  break;
                }
              }
            }
          });

          return resultsIn;
        }

        function filterByNumberCriteria(
          assets: any[],
          filterCriteria: IFNumberFilterCriteria
        ): any[] {
          console.log('ASSETS TO FILTER', assets);
          console.log('filterCr', filterCriteria);

          return assets.filter((a) => {
            return a[filterCriteria.metric] > filterCriteria['greaterThan'];
          });
        }

        const data: {
          assets: any[];
          filterPool: any[];
          searchableFields: string[];
        } = orderedData.data;


        let results: any[] = data.assets;
        console.log('begin', results);

        const searchableTextFields: string[] = data.searchableFields;
        const anyTextFilter = data.filterPool.find(
          (crfc) => {
            return crfc.type === 'text';
          }
        );

        const anyNumberFilter: IFFilterCriteria[] = data.filterPool.filter(
          (crfc: IFFilterCriteria) => {
            return crfc.type === 'number';
          }
        );

        if (anyTextFilter) {
          if (anyTextFilter['term']) {
            results = recursivelySearch(
              anyTextFilter['term'],
              results,
              searchableTextFields
            );
          }
        }

        console.log('aftertext', results);
        console.log('anynumber', anyNumberFilter);




        if (anyNumberFilter) {
          anyNumberFilter.map((crfc: IFFilterCriteria) => {
            results = filterByNumberCriteria(
              results,
              crfc as IFNumberFilterCriteria
            );
          });
        }

        console.log('received thihgs', results);


        postMessage.bind(self)(['type', results]);
      };
    };
    const dataObj = '(' + workerFunction + ')();';

    const blob = new Blob([dataObj]);
    const blobURL = URL.createObjectURL(blob);
    return new Worker(blobURL);
  }

  filterEverything(): Worker {
    const workerFunction = () => {

      function getRandomColor(): string {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
      }

      function flattenObject(ob) {
        const toReturn = {};
        for (const i in ob) {
            if (!ob.hasOwnProperty(i)) {continue;}
            if ((typeof ob[i]) === 'object' && ob[i] !== null) {
                const flatObject = flattenObject(ob[i]);
                for (const x in flatObject) {
                    if (!flatObject.hasOwnProperty(x)) {continue;}
                    toReturn[i + '.' + x] = flatObject[x];
                }
            } else {
                toReturn[i] = ob[i];
            }
        }
        return toReturn;
    }

      function recursivelySearch(term: string, array: any[]): any[] {
        const results: any[] = [];

        const myRegexp: RegExp = new RegExp(term, 'ig');

        array.map((obj: any) => {
          const newObj = flattenObject(obj);
          const keys: string[] = Object.keys(newObj);

          for (const key of keys) {
            if (typeof newObj[key] === 'string') {
              if (newObj[key].match(myRegexp)) {
                obj.color = getRandomColor();
                results.push(obj);
                break;
              }
            }
          }
        });
        return results;
      }

      onmessage = (orderedData: any) => {

        const data: {clients: Client[], credits: Credit[], term: string} = orderedData.data;

        const results: {clients: Client[], credits: Credit[]} = {
          clients: [],
          credits: []
        };
        results.clients = recursivelySearch(data.term, data.clients);        results.clients = recursivelySearch(data.term, data.clients);
        results.credits = recursivelySearch(data.term, data.credits);


        postMessage.bind(self)(['type', results]);
      };
    };
    const dataObj = '(' + workerFunction + ')();';

    const blob = new Blob([dataObj]);
    const blobURL = URL.createObjectURL(blob);
    return new Worker(blobURL);
  }


  populateUINomeNO_MATTER_WHAT(): Worker {
    const workerFunction = () => {
      onmessage = (e) => {
        const clients: Client[] = e.data.clients;
        console.log('clients', clients);

        clients.map((c: Client) => {
          if (!c.UINome) {
            if (c.persona_fisica) {
              c.UINome = `${c.CessionarioCommittente.DatiAnagrafici.Anagrafica.Nome}
              ${c.CessionarioCommittente.DatiAnagrafici.Anagrafica.Cognome}`;
            } else {
              c.UINome = `${c.CessionarioCommittente.DatiAnagrafici.Anagrafica.Denominazione}`;
            }
          }

        });
        postMessage.bind(self)(['type', clients]);
      };
    };

    // this will convert the function into a string
    const dataObj = '(' + workerFunction + ')();';

    // this is a BlobURL that the browser interprets as a file resource
    const blob = new Blob([dataObj]);
    const blobURL = URL.createObjectURL(blob);
    // and this is our worker
    return new Worker(blobURL);
  }

  testBlob(clients: Client[]): Worker {

      const workerFunction = () => {
        onmessage = (e) => {
          const clientsToElaborate: Client[] = e.data;
          clientsToElaborate.map((c: Client) => {
            let anagrafica: Anagrafica;
            anagrafica = c.CessionarioCommittente.DatiAnagrafici.Anagrafica;

            c.UINome = c.persona_fisica ? `${anagrafica.Cognome} ${anagrafica.Nome}` : anagrafica.Denominazione;
            c.contatti = c.contatti ? c.contatti : [];
            c.UIContacts = c.contatti.map((tn: ClientContact) => {
              return tn.tiponotificaId;
            });

            const {DatiAnagrafici: datiAnagrafici} = c.CessionarioCommittente;

            c.UICodiceFiscale = datiAnagrafici.CodiceFiscale;
            c.UIPartitaIVA = !!datiAnagrafici.IdFiscaleIVA  ?
            `${datiAnagrafici.IdFiscaleIVA.IdPaese} ${datiAnagrafici.IdFiscaleIVA.IdCodice}` :
            ``
            ;
          });
          function compare(a: Client, b: Client) {
            if (a.UINome < b.UINome) {
              return -1;
            }
            if (a.UINome > b.UINome) {
              return 1;
            } else {
              return 0;
            }
          }

          clientsToElaborate.sort(compare);
          postMessage.bind(self)(['type', clientsToElaborate]);
        };
      };

      // this will convert the function into a string
      const dataObj = '(' + workerFunction + ')();';

      // this is a BlobURL that the browser interprets as a file resource
      const blob = new Blob([dataObj]);
      const blobURL = URL.createObjectURL(blob);
      // and this is our worker
      return new Worker(blobURL);
  }


}
