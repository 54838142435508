import { NgModule } from '@angular/core';
import { IFAnagraphicsBlockComponent } from './if-anagraphics-block.component';
import { CommonModule } from '@angular/common';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { IFAnagraphicsModule } from '../if-anagraphics/if-anagraphics.module';
import { IFIDFiscaleModule } from '../if-id-fiscale/if-id-fiscale.module';
import {MatDatepickerModule} from '@angular/material/datepicker';

@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatInputModule,
    IFAnagraphicsModule,
    IFIDFiscaleModule
  ],
  declarations: [
    IFAnagraphicsBlockComponent
  ],
  exports: [
    IFAnagraphicsBlockComponent
  ]
})
export class IFAnagraphicsBlockModule {}
