import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MessagesService } from 'src/app/services/messages.service';
import { Notifica } from 'src/app/interfaces/notifica';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
  FormArray
} from '@angular/forms';
import { IFPagaBoxContent } from 'src/app/interfaces/if-paga-box-content';
import { IF_PAGA_FACILE_EDIT_PLACEHOLDERS } from 'src/app/constants/if_paga_facile_edit_placeholders';
import { ClientsService } from 'src/app/services/clients.service';
import { IFProfile } from 'src/app/interfaces/if_profile';
import { FlashService } from 'src/app/services/notifications.service';
import { IFFeedback } from 'src/app/interfaces/if_feedback';
import { CreditsService } from 'src/app/services/credits.service';
import { InvoiceService } from 'src/app/services/invoice.service';

@Component({
  selector: 'app-paga-facile-edit',
  templateUrl: './paga-facile-edit.component.html',
  styleUrls: ['./paga-facile-edit.component.scss']
})
export class PagaFacileEditComponent implements OnInit {
  @ViewChild('messageTextArea', { read: true, static: false }) messageTextArea: ElementRef;

  POSITIVE_KEYWORD = 'positivi';
  NEGATIVE_KEYWORD = 'negativi';

  notificationForEdit: Notifica;
  token: string;

  editForm: FormGroup;
  feedbacksForm: FormGroup;

  IF_PAGA_FACILE_EDIT_PLACEHOLDERS = IF_PAGA_FACILE_EDIT_PLACEHOLDERS;

  constructor(
    private msgss: MessagesService,
    private fb: FormBuilder,
    public cls: ClientsService,
    private nss: FlashService,
    private crs: CreditsService,
    private is: InvoiceService
  ) {
    this.buildEditForm();
  }

  savePFData(): void {
    const formValue = this.editForm.value;
    this.cls
      .patchAccountAttribute(
        'paga_facile',
        {
          messaggio: formValue.messaggio,
          stripeId: formValue.stripeId,
          positivi: this.cls.me.paga_facile[this.POSITIVE_KEYWORD],
          negativi: this.cls.me.paga_facile[this.NEGATIVE_KEYWORD]
        },
        this.cls.me.id
      )
      .subscribe((p: IFProfile) => {
        this.nss.emitOKNotification('Preferenze Salvate');
      });
  }

  pagaBoxIsBorn($event: boolean): void {
    if (this.cls.me.paga_facile) {
      this.msgss.pushNewValue(this.editForm.value);
    }
  }

  buildEditForm(): void {
    this.editForm = this.fb.group({
      messaggio: ['', Validators.required],
      stripeId: [null, Validators.required]
    });

    this.feedbacksForm = this.fb.group({
      [this.POSITIVE_KEYWORD]: this.buildFeedback(),
      [this.NEGATIVE_KEYWORD]: this.buildFeedback()
    });

    this.editForm.valueChanges.subscribe((newValue: IFPagaBoxContent) => {
      this.msgss.pushNewValue(newValue);
    });
  }

  buildFeedback(optionalValue?: { label: string; value: string }): FormGroup {
    if (optionalValue) {
      return this.fb.group({
        label: [optionalValue.label, Validators.required],
        value: [optionalValue.value, Validators.required]
      });
    } else {
      return this.fb.group({
        label: [null, Validators.required],
        value: [null, Validators.required]
      });
    }
  }

  ngOnInit(): void {

    this.token = localStorage.getItem('pftoken');

    this.crs.getCreditsCount().subscribe((r: {count: number}) => {
      if (r.count === 0) {
        this.notificationForEdit = null;
      } else {
        if (this.cls.me) {
          if (this.cls.me.paga_facile) {
            this.editForm.patchValue({
              messaggio: this.cls.me.paga_facile.messaggio,
              stripeId: this.cls.me.paga_facile.stripeId
            });
          }
        } else {
          this.cls.loadMe().subscribe((me: IFProfile[]) => {
            this.editForm.patchValue({
              messaggio: me[0].paga_facile.messaggio,
              stripeId: me[0].paga_facile.stripeId
            });
            this.cls.setMe(me[0]);
          });
        }

        if (this.msgss.notifications) {
          this.notificationForEdit = this.msgss.notifications[0];
        } else {
          this.msgss.notifications$.subscribe((ntss: Notifica[]) => {
            this.notificationForEdit = ntss[0];
          });
          this.msgss.getMyNotifications(10, 0, null, {}).subscribe((ntss: Notifica[]) => {
            this.msgss.setNotifications(ntss);
          });
        }
      }
    });

  }

  addPlaceholder(plch: string): void {
    const ctrl: FormControl = this.editForm.get('messaggio') as FormControl;
    ctrl.patchValue(ctrl.value + `{${plch}}`);
    this.messageTextArea.nativeElement.focus();
  }

  populateValue(isPositive: boolean): void {
    let relatedFormGroup: FormGroup;
    if (isPositive) {
      relatedFormGroup = this.feedbacksForm.get(
        this.POSITIVE_KEYWORD
      ) as FormGroup;
    } else {
      relatedFormGroup = this.feedbacksForm.get(
        this.NEGATIVE_KEYWORD
      ) as FormGroup;
    }
    const value = relatedFormGroup.get('label').value;
    relatedFormGroup.get('value').patchValue(this.safeleStripLabel(value));
  }

  /**
   * il `isPositive` determina se la finestra da aprire è quella dei feedback positivi o negativi
   */
  triggerFeedBackWindow(isPositive: boolean): void {
    this.msgss.triggerFeedBackWindow(isPositive);
  }

  triggerMainText(): void {
    this.msgss.triggerMainText();
  }

  safeleStripLabel(label: string): string {
    label = label.toLocaleLowerCase();
    label = label.replace(new RegExp(/\s/, 'ig'), '-');
    label = label.replace(new RegExp(/à/, 'ig'), 'a');
    label = label.replace(new RegExp(/è/, 'ig'), 'e');
    label = label.replace(new RegExp(/é/, 'ig'), 'e');
    label = label.replace(new RegExp(/ì/, 'ig'), 'i');
    label = label.replace(new RegExp(/ò/, 'ig'), 'o');
    label = label.replace(new RegExp(/ù/, 'ig'), 'u');
    label = label.replace(new RegExp(/\W/g), '-');

    return label;
  }

  addFeedback(isPositive: boolean): void {
    let valueToConcat: IFFeedback[];
    const keyWordForGroup = isPositive
      ? this.POSITIVE_KEYWORD
      : this.NEGATIVE_KEYWORD;
    if (this.cls.me.paga_facile[keyWordForGroup]) {
      valueToConcat = this.cls.me.paga_facile[keyWordForGroup];
    } else {
      valueToConcat = [];
    }
    console.log(valueToConcat);
    const relatedForm = this.feedbacksForm.get(keyWordForGroup);
    const value = relatedForm.value;
    Object.assign(this.cls.me.paga_facile, {
      [keyWordForGroup]: valueToConcat.concat([value])
    });

    this.pushFeedbackModifications(isPositive, this.cls.me.paga_facile[keyWordForGroup]);

    relatedForm.reset();
  }

  remove(isPositive: boolean, feedback: IFFeedback): void {
    const keyWordForGroup = isPositive
      ? this.POSITIVE_KEYWORD
      : this.NEGATIVE_KEYWORD;

    this.cls.me.paga_facile[keyWordForGroup] = this.cls.me.paga_facile[
      keyWordForGroup
    ].filter((f: IFFeedback) => {
      return f.value !== feedback.value;
    });

    this.pushFeedbackModifications(isPositive, this.cls.me.paga_facile[keyWordForGroup]);
  }

  pushFeedbackModifications(isPositive: boolean, feedbacks: IFFeedback[]): void {
    this.msgss.pushNewFeedback(feedbacks, isPositive);
  }
}
