import { NgModule } from '@angular/core';
import { IFFormRowComponent } from './if-form-row.component';
import { CommonModule } from '@angular/common';
import { IFComponentsModule } from 'src/app/modules/if-components/if-components.module';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
  declarations: [
    IFFormRowComponent
  ],
  imports: [
    IFComponentsModule,
    CommonModule,
    MatCheckboxModule
  ],
  exports: [
    IFFormRowComponent
  ]
})
export class IFFormRowModule {}
