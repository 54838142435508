export enum StatoPagamentiToken {
  all = 'all',
  open = 'open',
  verify = 'verify',
  paid = 'paid',
  unpaid = 'unpaid',
  deleted = 'deleted'
}

export enum StatoPagamentiLabels {
  all = 'Tutti',
  open = 'Aperto',
  verify = 'In Verifica',
  paid = 'Pagato',
  unpaid = 'Non Pagato',
  deleted = 'Storni'
}

export enum StatoPagamentiColors {
  all = 'Tutti',
  open = 'var(--gestit)',
  verify = 'var(--verif)',
  paid = 'var(--incass)',
  unpaid = 'var(--scadu)',
  deleted = 'Storni'
}
