import { NgModule } from '@angular/core';
import { PagaFacileEditComponent } from './paga-facile-edit/paga-facile-edit.component';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { PagaFacileModule } from '../public/pago-facile/pago-facile.module';
import {MatInputModule} from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IFFileRowModule } from 'src/app/components/if-file-row/if-file-row.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    PagaFacileEditComponent
  ],
  exports: [
    PagaFacileEditComponent
  ],
  imports: [
    IFFileRowModule,
    MatFormFieldModule,
    MatInputModule,
    PagaFacileModule,
    MatCardModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild([
      {
        path: '',
        component: PagaFacileEditComponent
      }
    ])
  ]
})
export class PagaFacileEditModule {}
