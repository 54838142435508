export enum IFContactsPlatforms {
  email = 'ico-emailsmart',
  pec = 'ico-emailsmart',
  sms = 'ico-mobilesmart',
  cellulare = 'ico-mobilesmart',
  telefono = 'ico-telefonosmart',
  skype = 'skype',
  facebook = 'facebook',
  instagram = 'instagram',
  whatsapp = 'ico-whatsappsmart',
  linkedin = 'linkedin',
  fax = 'fax',
  telegram = 'telegram'
}

export enum IFContactsTypes {
  email = 'email',
  pec = 'pec',
  sms = 'sms',
  cellulare = 'cellulare',
  telefono = 'telefoni',
  skype = 'skype',
  facebook = 'facebook',
  instagram = 'instagram',
  whatsapp = 'whatsapp',
  linkedin = 'linkedin',
  fax = 'fax',
  telegram = 'telegram'
}

export interface ClientContact {
  tiponotificaId: string; // foreignkey
  timestamp?: string; // creato il
  valore: {
    principale: string; // valore del contatto
    secondario?: string; // ad esempio il prefisso telefonico quando serve
    validazione?: string; // timestamp per quando il contatto è validato
  };
}
