import { DashboardPayload } from './../interfaces/requests/bar_chart_payload';
/**
 * @license
 * Copyright SMART SRL ®. Tutti i diritti riservati
 *
 * @author
 * Leonardo Maria Miliacca
 *
 * L'uso di questo codice è soggetto a copyright
 */
import { StatoPagamento } from '../interfaces/stato_pagamento';
import { Credit, XMLInvoice } from '../interfaces/credit';
import { CLIENTS_KEYWORD, CREDITS_KEYWORD, NOTIFICATIONS_KEYWORD } from '../constants/keywords';
import { API_DOMAIN, API_VERSION } from '../constants/server';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { CreditType } from '../interfaces/credit_type';
import { TipiTerminipagamento } from '../interfaces/tipo_termini_pagamento';
import { StatoPagamentiToken } from '../constants/payment_statuses';
import { CreditDateFilter } from '../interfaces/credits_date_filter';
import { Notifica } from '../interfaces/notifica';
import { DashboardStat } from '../interfaces/dashboard_stat';
import { IFDocumentType } from '../interfaces/if_document_type';
import { BarChartFilter } from '../interfaces/bar_chart_filter';
import { FatturaElettronicaBody } from '../interfaces/fatturazione_elettronica/fattura_semplificata';
import { ClientsService } from './clients.service';
import { InvoiceService } from './invoice.service';
import { PublicCreditResponse } from '../interfaces/responses/public_credit_response';
import { IFPublicPayment, PublicAction, IFPublicNegativeFeedback, IFPublicPositiveFeedback } from '../interfaces/requests/public_payment';
import { UtilsService } from './utils.service';
import { VerifyXMLResponse } from '../interfaces/responses/verify-xml-response';
import { shareReplay } from 'rxjs/operators';

const V1_PARTIAL_URL = `${API_DOMAIN}/${API_VERSION}`;
const V1_URL = `${API_DOMAIN}/${API_VERSION}/${CREDITS_KEYWORD}`;

@Injectable()
export class CreditsService {

  paymentsTerms: TipiTerminipagamento[];

  private _trigger = new Subject<any>();
  public trigger$ = this._trigger.asObservable();

  public tipiCassa: {[key: string]: string};
  private _tipiCassa = new Subject<{[key: string]: string}>();
  public tipiCassa$ = this._tipiCassa.asObservable();


  public modalitaPagamento: {[key: string]: string};
  private _modalitaPagamento = new Subject<{[key: string]: string}>();
  public modalitaPagamento$ = this._modalitaPagamento.asObservable();

  public condizioniPagamento: {[key: string]: string};
  private _condizioniPagamento = new Subject<{[key: string]: string}>();
  public condizioniPagamento$ = this._condizioniPagamento.asObservable();

  public AliquoteIVA: number[];
  private _AliquoteIVA = new Subject<number[]>();
  public AliquoteIVA$ = this._AliquoteIVA.asObservable();

  private _documentTypes = new Subject<IFDocumentType[]>();
  public documentTypes$ = this._documentTypes.asObservable();

  public paymentsStatusesTokens: {[key: string]: string} = {};
  public paymentsStatusesLabels: {[key: string]: string} = {};

  private paymentsStatuses = new Subject<StatoPagamento[]>();
  public paymentsStatusesObservable = this.paymentsStatuses.asObservable();
  public _paymentsStatuses: StatoPagamento[];

  private credits = new Subject<Credit[]>();
  public creditsObservable$ = this.credits.asObservable();
  public _credits: Credit[];

  private _activeFilter = new Subject<StatoPagamentiToken>();
  public activeFilter$ = this._activeFilter.asObservable();
  public activeFilter: StatoPagamentiToken;

  public dateFilter: CreditDateFilter = {data_da: null , data_a: null};

  public currentInvoice: XMLInvoice;

  constructor(
    private http: HttpClient,
    private is: InvoiceService,
    private us: UtilsService
  ) {}

  /**
   * Questo è un metodo molto generico che serve per triggerare un qualche
   * cambiamento.
   * RICORDA DI EFFETTUARE L'UNSUBSCRIBE NELL'ONDESTROY!
   * @param object qualunque cosa
   */
  trigger(object): void {
    this._trigger.next(object);
  }

  /**
   * Questo metodo invia la corrente fattura allo SDI, o perlomeno
   * inizia il processo che avverrà lato server
   * @param creditId l'id credito
   */
  sendToSDI(creditId: string): Observable<any> {
    return this.http.post<any>(`${API_DOMAIN}/sdi/xmlcredit/${creditId}`, {});
  }

  setCurrentInvoice(xml: XMLInvoice, creditId: string): void {
    this.currentInvoice = xml;
    this.is.setCurrentlySelectedInvoice(xml, creditId);
  }

  setTipiCassa(): void {
    this.http.get(`${V1_PARTIAL_URL}/Lookup/TipoCassa`)
    .subscribe((response: {value: {[key: string]: string}}) => {
      this.tipiCassa = response.value;
      this._tipiCassa.next(response.value);
    });
  }

  getModalitaPagamento(): Observable<{[key: string]: string}[]> {
    return this.http.get<{[key: string]: string}[]>(`${V1_PARTIAL_URL}/Lookup/ModalitaPagamento`);
  }

  setModalitaPagamento(): void {
    this.http.get(`${V1_PARTIAL_URL}/Lookup/ModalitaPagamento`)
    .subscribe((response: {value: {[key: string]: string}}) => {
      this.modalitaPagamento = response.value;
      this._modalitaPagamento.next(response.value);
    });
  }

  setCondizioniPagamento(): void {
    this.http.get(`${V1_PARTIAL_URL}/Lookup/CondizioniPagamento`)
    .subscribe((response: {value: {[key: string]: string}}) => {
      this.condizioniPagamento = response.value;
      this._condizioniPagamento.next(response.value);
    });
  }

  setAliquoteIVA(): void {
    this.http.get(`${V1_PARTIAL_URL}/Lookup/AliquotaIVA`)
    .subscribe((response: {name: string, value: number[]}) => {
      this.AliquoteIVA = response.value;
      this._AliquoteIVA.next(response.value);
    });
  }

  setDocumentsTypes(): void {
    this.http.get(`${V1_PARTIAL_URL}/Lookup/TipoDocumento`)
    .subscribe((response: {name: string, value: {[key: string]: string}}) => {
      const docTypes: IFDocumentType[] = [];
      Object.keys(response.value).map((k: string) => {
        docTypes.push({
          value: k,
          label:  response.value[k]
        });
      });
      this._documentTypes.next(docTypes);
    });
  }

  setActiveFilter(filter: string): void {
    console.log('setting active filter', filter);
    this.activeFilter = StatoPagamentiToken[filter];
    this._activeFilter.next(StatoPagamentiToken[filter]);
  }

  getDataForChartsInDashboard(): Observable<any> {
    return this.http.get(`${V1_PARTIAL_URL}/Dashboard`);
  }

  create(credit: Credit): Observable<Credit> {
    return this.http.post<Credit>(`${V1_URL}`, credit);
  }

  addCredit(newCredit: Credit): void {
    this._credits.push(newCredit);
    this.credits.next(this._credits);
  }


  /**
   *
   * @param limit
   * @param skip
   */
  getCreditsList(limit: number, skip: number, order?: string, filter?: any): Observable<Credit[]> {
    const JSON_FILTER = this.us.buildLoopbackJSON(limit, skip, order, filter);

    return this.http.get<Credit[]>(`${V1_URL}?filter=${JSON.stringify(JSON_FILTER)}`);
  }

  /**
   *
   * @param limit
   * @param skip
   */
  getLoopbackFriendlyCreditsList(limit: number, skip: number, order?: string, filter?: any): Observable<Credit[]> {
    filter = {
      ...filter,
      skip,
      limit
    };
    return this.http.get<Credit[]>(`${V1_URL}?filter=${JSON.stringify(filter)}`);
  }

  getParameterizedCreditsCountLoopback(limit: number, skip: number, order?: string, filter?: any): Observable<{count: number}> {
    const newFilter = filter.where ? filter.where : {};
    return this.http.get<{count: number}>(`${V1_URL}/count?where=${JSON.stringify(newFilter)}`);
  }

  getPublicCreditInfo(token: string, notificaId: string): Observable<PublicCreditResponse> {
    return this.http.get<PublicCreditResponse>(`${API_DOMAIN}/pf/${token}/${notificaId}`);
  }

  givePublicFeedback(token: string, notificaId: string, payload: IFPublicNegativeFeedback|IFPublicPositiveFeedback): Observable<Credit> {
    return this.http.post<Credit>(`${API_DOMAIN}/pf/${token}/${notificaId}`, payload);
  }

  payPublicCreditInfo(token: string, notificaId: string, stripeToken: string): Observable<Credit> {
    const payload: IFPublicPayment = {
      action: PublicAction.payNow,
      data: {
        tokenCC: stripeToken
      }
    };
    return this.http.post<Credit>(`${API_DOMAIN}/pf/${token}/${notificaId}`, payload);
  }

  getCredit(creditId: string): Observable<Credit> {
    return this.http.get<Credit>(`${V1_URL}/${creditId}`);
  }

  getCreditsCount(): Observable<{count: number}> {
    return this.http.get<{count: number}>(`${V1_URL}/count`);
  }

  getParameterizedCreditsCount(limit: number, skip: number, order?: string, filter?: any): Observable<{count: number}> {
    const JSON_FILTER = this.us.buildLoopbackJSONForCount(filter);
    return this.http.get<{count: number}>(`${V1_URL}/count?where=${JSON.stringify(JSON_FILTER)}`);
  }

  getCreditsForPeriod(from: Date, to: Date): Observable<Credit[]> {
    // {"where": {"and": [{"data_scadenza": {"gt": "2018-09-10T00:00:00.000Z"}}, {"data_scadenza":{"lt": "2018-09-16T00:00:00.000Z"}}]}}
    const filter = `{"where":
    {
      "stato":true,
      "and":
      [{"data_scadenza": {"gt": "${from.toISOString()}"}},
      {"data_scadenza":{"lt": "${to.toISOString()}"}}]
    }}`;
    return this.http.get<Credit[]>(`${V1_URL}?filter=${filter}`);
  }

  /**
   * A differenza della chiamata paginata, questo metodo recupera soltando
   * i campi necessari per tirare fuori un aggregato complessivo.
   * @param clientId l'id cliente
   */
  getAllCreditsForClient(
    clientId: string,
  ): Observable<Credit[]> {
    const JSON_FILTER = {fields: {
      statopagamentoId: true,
      saldo: true,
      data_emissione: true,
      incassato: true,
      stato: true,
      importo: true, id: true, riferimento: true}};
    return this.http
    .get<Credit[]>(
      `${V1_PARTIAL_URL}/${CLIENTS_KEYWORD}/${clientId}/crediti?filter=${JSON.stringify(JSON_FILTER)}`
    );
  }

  getCreditForClient(
    clientId: string,
    limit: number,
    skip: number,
    sort: string = 'data_scadenza ASC',
    filter?: any): Observable<Credit[]> {
    const JSON_FILTER = this.us.buildLoopbackJSON(limit, skip, sort, filter);
    return this.http
    .get<Credit[]>(
      `${V1_PARTIAL_URL}/${CLIENTS_KEYWORD}/${clientId}/crediti?where={stato:true}&filter=${JSON.stringify(JSON_FILTER)}`
    );
  }

  /**
   * Mancava questa chiamata
   */
  getTermsPayments(): Observable<TipiTerminipagamento[]> {
    return this.http.get<TipiTerminipagamento[]>(`${API_DOMAIN}/${API_VERSION}/TipiTerminipagamento`);
  }

  setTermsPaymentTypes(): void  {
    this.http.get<TipiTerminipagamento[]>(`${API_DOMAIN}/${API_VERSION}/TipiTerminipagamento`)
    .subscribe((terms: TipiTerminipagamento[]) => {
      this.paymentsTerms = terms;
    }, error => {
      // TODO gestire errore
    });
  }

  private baseGetPaymentStatuses(): Observable<StatoPagamento[]> {
    return this.http.get<StatoPagamento[]>(`${API_DOMAIN}/${API_VERSION}/StatoPagamenti`)
    .pipe(
      shareReplay()
    );
  }

  setPaymentsStatuses(): void {
    this.baseGetPaymentStatuses()
    .subscribe((ps: StatoPagamento[]) => {
      this.paymentsStatuses.next(ps);
      this._paymentsStatuses = ps;

      this._paymentsStatuses.map((psmapped: StatoPagamento) => {
        this.paymentsStatusesLabels[psmapped.id] = psmapped.nome_stato;
        this.paymentsStatusesTokens[psmapped.id] = psmapped.token;
      });
    }, error => {
      // TODO gestire errore
    });
  }

  getPaymentsStatuses(): Observable<StatoPagamento[]> {
    return this.baseGetPaymentStatuses();
  }

  patchCredit(credit: Credit|string, columnName: string, newValue: any): Observable<Credit> {
    let creditId: string;
    if (typeof credit === 'string') {
      creditId = credit;
    } else {
      creditId = credit.id;
    }
    return this.http.patch<Credit>(`${API_DOMAIN}/${API_VERSION}/Crediti/${creditId}`, {
      [columnName]: newValue
    });
  }

  saveCredit(credit: Credit): Observable<Credit> {
    const allCredits: Credit[] = this._credits;
    const creditToEdit: Credit = allCredits.find((obj: Credit) => obj.id === credit.id);
    Object.assign(creditToEdit, credit);
    this.credits.next(allCredits);
    return this.http.patch<Credit>(`${API_DOMAIN}/${API_VERSION}/Crediti/${credit.id}`, credit);
  }

  eraseCredit(credit: Credit): Observable<Credit> {
    return this.http.patch<Credit>(`${API_DOMAIN}/${API_VERSION}/Crediti/${credit.id}`, {
      stato: false
    });
  }

  setCredits(): void {
    this.http.get<Credit[]>(`${V1_URL}?filter={"fields":{"xml": false}"where":{"stato": true}}`)
    .subscribe((response: Credit[]) => {
      this._credits = response;
      this.credits.next(response);
    }, error => {
      // TODO handle error
    });
  }

  updateCreditsList(credits: Credit[]): void {
    this._credits = credits;
    this.credits.next(credits);
  }

  getNotificationsForCredit(credit: Credit): Observable<Notifica[]> {
    return this.http.get<Notifica[]>
    (`${API_DOMAIN}/${API_VERSION}/Notifiche?filter={"order":"data_invio ASC","where":{"creditoId": "${credit.id}"}}`);
  }

  calculateExpirationDate(dataEmissione: Date, clientId: string, periodo_verifica: number): Observable<any> {
    return this.http.post<any>(`${API_DOMAIN}/check/due`, {
      clienteId: clientId,
      data_emissione: dataEmissione,
      periodo_verifica
    });
  }

  patchAggregatedValues(widgetId: string, payload: DashboardPayload): Observable<DashboardStat> {
    return this.http.patch<DashboardStat>(`${API_DOMAIN}/${API_VERSION}/Dashboard/${widgetId}`, payload);
  }

  getAggregatedValues(widgetId: string): Observable<DashboardStat> {
    return this.http.get<DashboardStat>(`${API_DOMAIN}/${API_VERSION}/Dashboard/${widgetId}`);
  }

  getParameterizedBarChartsInDashboard(widgetId: string): Observable<any> {
    return this.http.get(`${V1_PARTIAL_URL}/Dashboard/${widgetId}`);
  }

  resetDateFilter(): void {
    this.dateFilter = {
      campo: BarChartFilter.nessun_filtro,
      data_da: null ,
      data_a: null
    };
    const payload: DashboardPayload = {
      widget: 'bars',
      filtro: this.dateFilter
    };
    this.patchAggregatedValues('bars', payload).subscribe((response: DashboardStat) => {
      console.log('resetted');
    });
  }

  verifyXML(creditId: string): Observable<VerifyXMLResponse> {
    return this.http.post<VerifyXMLResponse>(`${API_DOMAIN}/verify/xmlcredit/${creditId} `, {});
  }

}

