<ng-container *ngIf="creating">
  <section
    [ngClass]="{
      closing: closing
    }"
    class="backdrop"
    (click)="timeoutCreating()"
  ></section>

  <div
    class="form"
    [ngClass]="{
      closing: closing
    }"
  >
    <div class="close" (click)="timeoutCreating()">
      <mat-icon>close</mat-icon>
    </div>

    <ng-container *ngIf="modelSelected === keywords.editInvoice">
      <app-if-invoice></app-if-invoice>
    </ng-container>

    <ng-container
      *ngIf="
        modelSelected === keywords.buySms ||
        modelSelected === keywords.buyEmails
      "
    >
      <app-if-buy-items
        [itemType]="modelSelected"
        (transationOk)="transactionDetected($event)"
      ></app-if-buy-items>
    </ng-container>

    <ng-container *ngIf="modelSelected === keywords.credits">
      <app-if-credit-form
        (creditCreated)="closeModal($event)"
      ></app-if-credit-form>
    </ng-container>

    <ng-container *ngIf="modelSelected === keywords.clients">
      <app-if-client-form
        (userCreated)="closeModal($event)"
      ></app-if-client-form>
    </ng-container>

    <ng-container
      *ngIf="
        modelSelected === keywords.notifications ||
        modelSelected === keywords.model
      "
    >
      <app-if-send-notification
        (notificationCreated)="closeModal($event)"
      ></app-if-send-notification>
    </ng-container>

    <ng-container
      *ngIf="
        modelSelected === keywords.importClients ||
        modelSelected === keywords.importCredits
      "
    >
      <app-if-import
        (uploadOk)="closeModal($event)"
        [typeOfImport]="modelSelected"
      ></app-if-import>
    </ng-container>
    <ng-container *ngIf="modelSelected === keywords.importInvoices">
      <app-if-xml-import
        (uploadOk)="closeXMLModal($event)"
        [typeOfImport]="modelSelected"
      ></app-if-xml-import>
    </ng-container>

    <ng-container *ngIf="modelSelected === keywords.send">
      <app-if-send-message
        (msgsSent)="transactionDetected()"
      ></app-if-send-message>
    </ng-container>
  </div>
</ng-container>

<mat-drawer-container autosize>
  <header>
    <div class="flexy">
      <div
      (click)="restrictedMenu = !restrictedMenu"
      class="logo">
        <img src="assets/if-logo.png" alt="Incasso Facile" />
      </div>
      <div class="title">
        <h2 *ngIf="!me"><span>Incasso Facile</span></h2>
        <h2 *ngIf="me">
          <span>{{ myName }}</span>
        </h2>
      </div>
    </div>
    <div class="flexy">
      <div class="flex-container">
        <div><h4>crediti non pagati</h4></div>
        <div>
          <span
            (click)="setActiveFilterAndGoToCredits(statoPagamentiToken.unpaid)"
            class="btn-header"
          >
            vedi
          </span>
        </div>
      </div>
      <div class="h3">
        <div class="icon-wrapper">
          <i class="icocredito-scaduto"></i>
        </div>
        <div
          (click)="setActiveFilterAndGoToCredits(statoPagamentiToken.unpaid)"
          class="text-wrapper scaduto"
          *ngIf="unpaidCredits"
          [ngClass]="{
            updated: unpaidCredits.updating
          }"
        >
          {{ unpaidCredits ? unpaidCredits.data.value : 0 }}
        </div>
      </div>
    </div>
    <div class="flexy">
      <div class="flex-container">
        <div><h4>crediti aperti</h4></div>
        <div>
          <span
            (click)="setActiveFilterAndGoToCredits(statoPagamentiToken.open)"
            class="btn-header"
            >vedi</span
          >
        </div>
      </div>
      <div class="h3">
        <div class="icon-wrapper">
          <i class="icocredito-gestito"></i>
        </div>
        <div
          *ngIf="managedCredits"
          (click)="setActiveFilterAndGoToCredits(statoPagamentiToken.open)"
          class="text-wrapper gestito"
          [ngClass]="{
            updated: managedCredits.updating
          }"
        >
          {{ managedCredits ? managedCredits.data.value : 0 }}
        </div>
      </div>
    </div>
    <div class="flexy">
      <div class="flex-container">
        <div><h4>SMS</h4></div>
        <div>
          <app-if-purchase-btn
            [keyword]="keywords.buySms"
          ></app-if-purchase-btn>
        </div>
      </div>
      <div class="h3">
        <div class="icon-wrapper">
          <i class="ico-notificasmart"></i>
        </div>
        <div
          *ngIf="smsData"
          class="text-wrapper gestito"
          [ngClass]="{
            updated: smsData.updating
          }"
        >
          <div class="premium">
            {{ smsData?.data.premium + smsData?.data.bonus }}
            <div class="tooltip">
              Acquistati: {{ smsData?.data.premium }}<br />
              Bonus: {{ smsData?.data.bonus }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flexy">
      <div class="flex-container">
        <div><h4>EMAIL</h4></div>
        <div>
          <app-if-purchase-btn
            [keyword]="keywords.buyEmails"
          ></app-if-purchase-btn>
        </div>
      </div>
      <div class="h3">
        <div class="icon-wrapper">
          <i class="ico-emailsmart"></i>
        </div>
        <div
          *ngIf="emailsData"
          class="text-wrapper"
          [ngClass]="{
            updated: emailsData.updating
          }"
        >
          <div class="premium">
            {{ emailsData?.data.premium + emailsData?.data.bonus }}
            <div class="tooltip">
              Acquistate: {{ emailsData?.data.premium }}<br />
              Bonus: {{ emailsData?.data.bonus }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flexy">
      <h4>aggiungi</h4>
      <div class="flex-center" (click)="showMenu = !showMenu">
        <i class="ico-piusmart" [ngClass]="{ open: showMenu }"></i>
      </div>

      <div class="create-menu" [ngClass]="{ show: showMenu, hide: !showMenu }">
        <div
          class="create"
          [ngClass]="{ entering: showMenu }"
          (click)="create(keywords.importInvoices)"
        >
          Importa Fatture
        </div>
        <div
          class="create"
          [ngClass]="{ entering: showMenu }"
          (click)="create(keywords.importCredits)"
        >
          Importa Crediti
        </div>
        <div
          class="create"
          [ngClass]="{ entering: showMenu }"
          (click)="create(keywords.importClients)"
        >
          Importa Clienti
        </div>
        <div
          class="create"
          [ngClass]="{ entering: showMenu }"
          (click)="create(keywords.credits)"
        >
          Nuovo Credito
        </div>
        <div
          class="create"
          [ngClass]="{ entering: showMenu }"
          (click)="create(keywords.clients)"
        >
          Nuovo Cliente
        </div>
        <div
          class="create"
          [ngClass]="{ entering: showMenu }"
          (click)="create(keywords.notifications)"
        >
          Nuovo Modello
        </div>
        <div
          class="create"
          [ngClass]="{ entering: showMenu }"
          (click)="create(keywords.send)"
        >
          Nuovo Messaggio
        </div>
      </div>
    </div>
  </header>

  <main>
    <if-sidebar [restricted]="restrictedMenu"></if-sidebar>

    <section
    [ngStyle]="{
      'margin-left': restrictedMenu ? '2.5rem' : '14rem',
      width: restrictedMenu ? 'calc(100% - 2.5rem)' : 'calc(100% - 14rem)'
    }"
    class="content-container">
      <router-outlet></router-outlet>

      <app-if-notifications></app-if-notifications>
    </section>

  </main>


  <footer [ngStyle]="{
    'margin-left': restrictedMenu ? '2.5rem' : '14rem',
    width: restrictedMenu ? 'calc(100% - 2.5rem)' : 'calc(100% - 14rem)'
  }">
    <a href="https://www.smartinnovative.it" target="_blank"
      >© SMART Srl 2018-{{CURRENT_YEAR}}</a
    >
    &nbsp;
    <span class="version"> - INCASSO FACILE v{{ VERSION }} </span>
  </footer>
</mat-drawer-container>
