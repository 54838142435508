<section id="form-holder">
  <div class="bubbles-container">
    <div class="bubble-wrapper">
      <div class="bubble gestit fluctuate"></div>
      <div class="bubble verif fluctuate"></div>
      <div class="bubble scadu fluctuate"></div>
      <div class="bubble incass fluctuate"></div>
    </div>
  </div>
  <div
    [ngClass]="{
      show: error,
      info: info
    }"
    class="notification"
  >
    {{ errorMsg }}
  </div>

  <div [ngClass]="{ showing: showingBox }" class="recover-password-box">
    <div class="input-wrapper">
      <input
        matInput
        type="email"
        [(ngModel)]="myEmail"
        placeholder="Nome utente"
      />
    </div>
    <div class="button-wrapper">
      <button
        (click)="startRecover(myEmail)"
        type="button"
        class="if-btn btn-light"
      >
        Avvia Recupero
      </button>
    </div>
  </div>

  <mat-card>
    <mat-card-header></mat-card-header>
    <mat-card-content>
      <div class="img-container">
        <img src="assets/if-logo.png" alt="Incasso Facile" />
      </div>
      <div *ngIf="loading" class="loading">

        <mat-spinner *ngIf="!success" [diameter]="50" [strokeWidth]="1"></mat-spinner>

        <img
        *ngIf="success"
        src="/assets/svg/checkmark-dark.svg" alt="">

        <div class="message">
          {{ statusMsg }}
        </div>
      </div>

      <ng-container *ngIf="!loading">
        <form [formGroup]="loginForm">
          <mat-form-field>
            <img matPrefix src="assets/svg/user.svg" alt="" />
            <input
              matInput
              formControlName="email"
              type="text"
              placeholder="Email"
            />
            <mat-error *ngIf="loginForm.get('email').hasError('email')">
              Utilizzare un indirizzo email valido
            </mat-error>

          </mat-form-field>
          <br />
          <mat-form-field>
            <img matPrefix src="assets/svg/padlock.svg" alt="" />

            <input
              matInput
              formControlName="password"
              type="password"
              placeholder="Password"
            />

            <mat-error *ngIf="loginForm.get('password').hasError('minlength')">
              Almeno 6 caratteri
            </mat-error>
          </mat-form-field>
          <br />
          <button
            [disabled]="!loginForm.valid || loading"
            (click)="login()"
            type="button"
            class="if-btn btn-dark"
          >
            Login
          </button>
        </form>
        <div class="password-recover" (click)="showingBox = !showingBox">
          Recupera la password
        </div>
      </ng-container>



      <div class="build">
        <small>v{{ VERSION }}</small>
      </div>
    </mat-card-content>
  </mat-card>
</section>
