import {NgModule} from '@angular/core';
import { ProfileComponent } from './profile/profile.component';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { PagaFacileEditModule } from '../paga-facile-edit/paga-facile-edit.module';
import {MatTabsModule} from '@angular/material/tabs';

@NgModule({
  imports: [
    PagaFacileEditModule,
    CommonModule,
    MatTabsModule,
    RouterModule.forChild([
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'anagrafiche',
        // component: ProfileComponent,
      }, {
        path: 'pec',
        component: ProfileComponent,
        loadChildren: () => import('../pec-config/pec-config.module').then((m) => m.PecConfigModule)
      }, {
        path: 'paga-facile',
        component: ProfileComponent,
        loadChildren: () => import('../paga-facile-edit/paga-facile-edit.module').then((m) => m.PagaFacileEditModule)
      }, {
        path: 'sede',
        component: ProfileComponent,
        loadChildren: () => import('../../components/if-sede-block/if-sede-block.module').then((m) => m.IFSedeBlockModule)
      }, {
        path: 'anagrafiche',
        component: ProfileComponent,
        loadChildren: () => import('../anagraphics/anagraphics.module').then((m) => m.AnagraphicsModule)
      }, {
        path: 'contatti',
        component: ProfileComponent,
        loadChildren: () => import('../contacts/contacts.module').then((m) => m.ContactsModule)
      }, {
        path: 'conto',
        component: ProfileComponent,
        loadChildren: () => import('../iban-account/iban-account.module').then(m => m.IBANAccountModule)
      }, {
        path: 'cassetto-fiscale',
        component: ProfileComponent,
        loadChildren: () => import('../cassetto-fiscale/cassetto-fiscale.module').then(m => m.CassettoFiscaleModule)
      }
    ])
  ],
  declarations: [
    ProfileComponent
  ],
  exports: [
    ProfileComponent,
    RouterModule
  ]
})
export class ProfileModule {}
