/**
 * @license
 * Copyright SMART SRL ®. Tutti i diritti riservati
 *
 * @author
 * Leonardo Maria Miliacca
 *
 * L'uso di questo codice è soggetto a copyright
 */
import { Pipe, PipeTransform } from '@angular/core';
/**
 * Utilizza come separatore di decimali la virgola, e come
 * separatore delle migliaia il punto.
 */
@Pipe({name: 'friendlyNumber'})
export class FriendlyNumberPipe implements PipeTransform {
  transform(value: number): string {
    if ( value) {
      const adaptValue = +(value).toFixed(2);
      const splittedValue = adaptValue.toString().split('.');
      if (splittedValue.length === 1) {
        if (value > 1000) {
          const stringedValue = splittedValue[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
          return `${stringedValue}${splittedValue[1] ? `,${splittedValue[1]}` : ''}`;
        } else {
          return `${value}`;
        }
      } else {
        const stringedValue = splittedValue[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        return `${stringedValue}${splittedValue[1] ? `,${splittedValue[1]}` : '0'}`;
      }
    } else {
      return '';
    }

  }
}
