export enum IFBodyType {
  text = 'text',
  html = 'html',
  email = 'email'
}

export interface TipoNotifica {
  nome: string;
  attivo?: boolean; // se è presente fra i modelli o meno
  modello_predefinito: string;
  tipo_corpo: IFBodyType;
  id: string;
  stato: boolean;
}
