import { IFSendMessageResponse } from './../interfaces/responses/if_send_message_response';
import { NOTIFICATIONS_TYPES_KEYWORD, MESSAGES_TYPES_KEYWORD_URL } from './../constants/keywords';
import { HttpClient } from '@angular/common/http';
/**
 * @license
 * Copyright SMART SRL ®. Tutti i diritti riservati
 *
 * @author
 * Leonardo Maria Miliacca
 *
 * L'uso di questo codice è soggetto a copyright
 */
import { TipoNotifica } from '../interfaces/tipo_notifica';

import { API_DOMAIN, API_VERSION } from '../constants/server';
import { Injectable } from '@angular/core';
import { Subject, Observable } from '../../../node_modules/rxjs';
import { Notifica } from '../interfaces/notifica';
import { Modello } from 'src/app/interfaces/modello_messaggio';
import { IFSendMessageRequest } from '../interfaces/requests/send_message_request';
import { SendTestEmailRequest } from '../interfaces/requests/send_test_email_request';
import { IFPagaBoxContent } from '../interfaces/if-paga-box-content';
import { IFFeedback } from '../interfaces/if_feedback';
import { UtilsService } from './utils.service';

const V1_PARTIAL_URL = `${API_DOMAIN}/${API_VERSION}`;

@Injectable()
export class MessagesService {

  /**
   * Le tre variabili sotto gestiscono i tipi notifica, il subject
   * e l'observable che casta i nuovi valori ai listener
   */
  private _notificationsTypes = new Subject<TipoNotifica[]>();
  public notificationsTypes$ = this._notificationsTypes.asObservable();
  public notificationsTypes: TipoNotifica[];

  /**
   * Le tre variabili sotto gestiscono le notifiche, il subject
   * e l'observable che casta i nuovi valori ai listener
   */
  private _notifications = new Subject<Notifica[]>();
  public notifications$ = this._notifications.asObservable();
  public notifications: Notifica[];

  /**
   * Le tre variabili sotto gestiscono i modelli, il subject
   * e l'observable che casta i nuovi valori ai listener
   */
  private _models = new Subject<Modello[]>();
  public models$ = this._models.asObservable();
  public models: Modello[];

  /**
   * Quando da dashboard clicco su un tipo di notifica,
   * questa è la variabile che ne tiene traccia.
   */
  public activeFilter: TipoNotifica;

  /**
   * Le tre variabili sotto gestiscono i, il subject
   * e l'observable che casta i nuovi valori ai listener del
   * box paga-facile.
   */
  _pagaBoxValue = new Subject<IFPagaBoxContent>();
  pagaBoxValue: IFPagaBoxContent;
  pagaBoxValue$ = this._pagaBoxValue.asObservable();

  /**
   * Invia i nuovi trigger al box paga-facile
   */
  private _triggerFeedbackWindow = new Subject<boolean>();
  public triggerFeedbackWindow$ = this._triggerFeedbackWindow.asObservable();

  /**
   * Invia il nuovo testo al box paga-facile
   */
  private _triggerMainText = new Subject<null>();
  public triggerMainText$ = this._triggerMainText.asObservable();

  /**
   * Quando si aggiunge o toglie un feedback dal box paga-facile,
   * questo è l'observable.
   */
  private _newFeedbackAdded = new Subject<{feedbacks: IFFeedback[], arePositive: boolean}>();
  public newFeedbackAdded$ = this._newFeedbackAdded.asObservable();

  constructor(
    private http: HttpClient,
    private us: UtilsService
  ) {}

  /**
   * Questo metodo invia un feedback all'array di feedback paga-facile.
   * I feedback sono divisi in "positivi" e "negativi".
   * POSITIVI: "ho già pagato"
   * NEGATIVI: "Non voglio pagare"
   * @param feedbacks i feedback paga-facile
   * @param arePositive se sono i feedback di esito positivo o negativo
   */
  pushNewFeedback(feedbacks: IFFeedback[], arePositive: boolean): void {
    this._newFeedbackAdded.next({
      feedbacks: feedbacks,
      arePositive: arePositive
    });
  }

  /**
   *
   * @param newValue il nuovo valore da mandare alla box
   */
  pushNewValue(newValue): void {
    this.pagaBoxValue = newValue;
    this._pagaBoxValue.next(newValue);
  }

  /* MODELLI MESSAGGI */

  /**
   * Recupera i modelli dei messaggi.
   */
  getMessagesModels(): Observable<Modello[]> {
    return this.http.get<Modello[]>(`${V1_PARTIAL_URL}/${MESSAGES_TYPES_KEYWORD_URL}?filter={"where":{"stato":true}}`);
  }


  /**
   * Recupera i tipi notifica
   */
  getNotificationsTypes(): Observable<TipoNotifica[]> {
    return this.http.get<TipoNotifica[]>(`${V1_PARTIAL_URL}/${NOTIFICATIONS_TYPES_KEYWORD}?filter={"where":{"stato":true}}`);
  }

  /**
   * Mostra tutte le notifiche
   */
  unsetActiveFilter(): void {
    this.activeFilter = null;
  }

  /**
   * Questo metodo imposta il filtro nella pagina delle notifiche.
   * @param nt il tipo notifica selezionato
   * @param models i modelli coinvolti
   * @param sent se vogliamo solo i messaggi inviati
   */
  setActiveFilter(nt: TipoNotifica|null, models: Modello[], sent: boolean): Notifica[] {
    this.activeFilter = nt;

    if (nt) {
      const relatedModels = models
        .filter((m: Modello) => m.tiponotificaId === nt.id)
        .map((m: Modello) => {
          return m.id;
        });
      const notificationsToReturn: Notifica[] = this.notifications
      .filter((n: Notifica) => {
        return relatedModels.includes(n.modelloId) && n.processata === sent;
      });
      return notificationsToReturn;
    } else {
      return this.notifications.filter((nti: Notifica) => nti.processata === sent);
    }

  }

  /**
   *
   * @param model
   */
  createNotificationModel(model: Modello): Observable<Modello> {
    return this.http.post<Modello>(`${V1_PARTIAL_URL}/${MESSAGES_TYPES_KEYWORD_URL}`, model);
  }

  /**
   * Aggiorno un modello messaggio.
   * @param model il modello messaggio
   */
  updateNotificationModel(model: Modello): Observable<Modello> {
    console.log('PATCHANDO', model);
    return this.http.patch<Modello>(`${V1_PARTIAL_URL}/${MESSAGES_TYPES_KEYWORD_URL}/${model.id}`, model);
  }

  /**
   * Quando elimino un messaggio invoco questo metodo, è una patch
   * che imposta lo stato a false, è quindi una cancellazione logica.
   * @param model il modello messaggio in questione
   */
  deleteNotificationModel(model: Modello): Observable<Modello> {
    return this.http.patch<Modello>(`${V1_PARTIAL_URL}/${MESSAGES_TYPES_KEYWORD_URL}/${model.id}`, {stato: false});
  }

  /**
   * Recupera e imposta i modelli messaggio.
   */
  triggerSetModels(): void {
    this.http.get<Modello[]>(`${V1_PARTIAL_URL}/${MESSAGES_TYPES_KEYWORD_URL}?filter={"where":{"stato":true}}`)
    .subscribe((mdls: Modello[]) => {
      this.setModels(mdls);
    });
  }

  /**
   * Invia ai subscriber i modelli messaggio.
   * @param models i nuovi modelli
   */
  setModels(models: Modello[]): void {
    console.log('settings models', models);
    this.models = models;
    this._models.next(models);
  }

  /**
   * Recupera i modelli messaggio
   */
  getMyModels(): Observable<Modello[]> {
    return this.http.get<Modello[]>(`${V1_PARTIAL_URL}/${MESSAGES_TYPES_KEYWORD_URL}?filter={"where":{"stato":true}}`);
  }

  /* NOTIFICATIONS/SCADENZA */

  getMyNotificationsCount(filter): Observable<{count: number}> {
    const JSON_FILTER = this.us.buildLoopbackJSONForCount(filter);
    return this.http.get<{count: number}>(`${V1_PARTIAL_URL}/Notifiche/count?where=${JSON.stringify(JSON_FILTER)}`);
  }
  getMyNotifications(limit: number, skip: number, order?: string, filter?: any): Observable<Notifica[]> {
    const JSON_FILTER = this.us.buildLoopbackJSON(limit, skip, order, filter);
    return this.http.get<Notifica[]>(`${V1_PARTIAL_URL}/Notifiche?filter=${JSON.stringify(JSON_FILTER)}`);
  }

  setMyNotifications(): void {
    this.http.get<Notifica[]>(`${V1_PARTIAL_URL}/Notifiche`)
    .subscribe((notifications: Notifica[]) => {
      this._notifications.next(notifications);
      this.notifications = notifications;
    });
  }

  getNotificationsByClientId(clientId: string): Observable<Notifica[]> {
    return this.http.get<Notifica[]>(`${V1_PARTIAL_URL}/Notifiche?filter={"where":{"clienteId":"${clientId}"},"order":"data_invio ASC"}`);
  }

  setNotifications(nts: Notifica[]): void {
    this.notifications = nts;
    this._notifications.next(nts);
  }

  setNotificationsTypes(): void {
    this.http.get<TipoNotifica[]>(`${V1_PARTIAL_URL}/${NOTIFICATIONS_TYPES_KEYWORD}?filter={"where":{"stato":true}}`)
    .subscribe((nts: TipoNotifica[]) => {
      this.notificationsTypes = nts;
      this._notificationsTypes.next(nts);
    });
  }

  patchNotification(nObj: Notifica): Observable<Notifica> {
    return this.http.patch<Notifica>(`${V1_PARTIAL_URL}/Notifiche/${nObj.id}`, nObj);
  }

  // DA QUI CI SONO FUNZIONI CONDIVISE SU PIÙ COMPONENTS
  splitNotificationsInSentAndNotSent(
    notifications: Notifica[],
    models: Modello[]
  ): {sent: Notifica[], notSent: Notifica[]} {

    const detailNotifications = {sent: [], notSent: []};
    notifications.map((n: Notifica) => {
      // n.UIBodyType = notificationsTypes.find((nt: TipoNotifica) => nt.id === notificationTypeId).tipo_corpo;
      n.relatedModel = models.find((m: Modello) => m.id === n.modelloId);
    });

    detailNotifications.sent = notifications.filter(((n: Notifica) => n.processata));
    detailNotifications.notSent = notifications.filter(((n: Notifica) => !n.processata));
    console.log('splitNotificationsInSentAndNotSent', detailNotifications);
    return detailNotifications;

  }

  sendMessage(mean: TipoNotifica, payload: IFSendMessageRequest ): Observable<IFSendMessageResponse> {
    return this.http.post<IFSendMessageResponse>(`${API_DOMAIN}/send/${mean.nome}`, payload);
  }

  sendTestMessage(payload: SendTestEmailRequest): Observable<IFSendMessageResponse> {
    return this.http.post<IFSendMessageResponse>(`${API_DOMAIN}/send/test-email`, payload);
  }

  triggerFeedBackWindow(bool: boolean): void {
    this._triggerFeedbackWindow.next(bool);
  }

  triggerMainText(): void {
    this._triggerMainText.next(null);
  }
}

