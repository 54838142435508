import { NgModule } from '@angular/core';
import { IFClientFormComponent } from './if-client-form.component';
import { MatStepperModule } from '@angular/material/stepper';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { IFFormBlockModule } from '../if-form-block/if-form-block.module';
import { IFFormArrayModule } from '../if-form-array/if-form-array.module';
import { IFFileRowModule } from '../if-file-row/if-file-row.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatIconModule } from '@angular/material/icon';
import { IFPipesModule } from 'src/app/pipes/if-pipes.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    IFClientFormComponent
  ],
  imports: [
    MatSelectModule,
    MatStepperModule,
    FormsModule,
    ReactiveFormsModule,
    IFPipesModule,
    CommonModule,
    IFFormArrayModule,
    MatIconModule,
    RouterModule,
    IFFormBlockModule,
    MatFormFieldModule,
    MatExpansionModule,
    MatInputModule,
    MatSlideToggleModule,
    MatSelectModule,
    IFFileRowModule,
  ],
  exports: [IFClientFormComponent]
})
export class IFClientFormModule {}
