/**
 * @license
 * Copyright SMART SRL ®. Tutti i diritti riservati
 *
 * @author
 * Leonardo Maria Miliacca
 *
 * L'uso di questo codice è soggetto a copyright.
 * Questo service nasce con lo scopo di effettuare operazioni comuni
 * quali lo spezzettamento di array in sotto-array, o l'eliminazione
 * di campi null ricorsivo.
 */
import { Injectable } from '@angular/core';
import { FormControl, FormGroup, FormArray } from '@angular/forms';


@Injectable()
export class UtilsService {

  attr: string;

  constructor(

  ) {}

  sort(array: any[], attribute: string): any[] {
    return array
    .sort((a, b) => {
      return (a[attribute] > b[attribute]) ? 1 : ((b[attribute] > a[attribute]) ? -1 : 0);
    } ).reverse();
  }

  /**
   * Questo metodo parte da un array qualunque, e suddivide le entries in
   * sotto array della dimensione indicata.
   * @param myArray l'array di partenza
   * @param chunk_size la dimensione degli array che si vuole come risultato
   */
  chunkArray(myArray: any[], chunk_size: number): any[] {
    let index = 0;
    const arrayLength = myArray.length;
    const tempArray = [];
    for (index = 0; index < arrayLength; index += chunk_size) {
        const myChunk = myArray.slice(index, index + chunk_size);
        // Do something if you want with the group
        tempArray.push(myChunk);
    }
    return tempArray;
  }

  /**
   * Patcha il valore con virgola e numeri con lo zero finale.
   * Sarebbe meglio sostituirlo con un Pipe.
   * @param control il controllo Angular
   * @param formControlName il nome del controllo
   * @param formGroup il formGroup Angular
   */
  correctFieldWithPaddedNumber(control: FormControl,  formControlName: string, formGroup: FormGroup): void {
    formGroup.patchValue({[formControlName]: control.value.toFixed(2)});
  }

  /**
   * Dato un qualsiasi formGroup, questo metodo recupera tutti gli errori e li
   * restituisce in un array concatenato.
   * @param fg il formGroup in esame
   */
  recursivelySpotErrors(fg: FormGroup|FormControl, name?: string ): any[] {
    let errorPool: any[] = [];
    if (fg.errors) {
      Object.keys(fg.errors)
      .map((k: string) => {
        errorPool.push({name, [name]: fg.errors[k]});
      });
    }

    if (fg instanceof FormGroup) {
      Object.keys(fg.controls)
      .map((k: string) => {
        errorPool = errorPool.concat(this.recursivelySpotErrors(fg.controls[k] as FormControl, k));
      });
    }

    if (fg instanceof FormArray) {
      fg.controls.map((ctrlOrGroup: FormControl|FormGroup) => {
        errorPool = errorPool.concat(this.recursivelySpotErrors(ctrlOrGroup));
      });
    }

    return errorPool;
  }

  /**
   * Questo metodo elimina tutte le chiavi che hanno un valore falsy, ricorsivamente.
   * @param object un oggetto qualunque, a n livelli
   * @param toxicFields i campi da eliminare se null
   */
  recursivelyStripToxicFields(object: any, toxicFields: string[], level: number = 0): any {
    const keys: string[] = Object.keys(object).filter((k) => {
      return toxicFields.includes(k);
    });
    if (keys.length === 0) {
      // è un oggetto ma non ci sono campi tossici,
      // stabilisco il tipo di oggetto
      Object.keys(object).map((key) => {

        if (object[key] instanceof Array) {
          // il caso di fatturaelettronicabody
          object[key].map((subObj: any) => {
            subObj = this.recursivelyStripToxicFields(subObj, toxicFields, level + 1);
          });
        } else {
          if (typeof object[key] === 'object' && object[key]) {
            const subKeys = Object.keys(object[key]);
            if (subKeys.length === 0) {
            } else {
              object[key] = this.recursivelyStripToxicFields(object[key], toxicFields);
            }
          }
        }
      });

    } else {
      keys.map((k) => {
        delete(object[k]);
      });
      // una volta che ho cancellato i campi tossici,
      // devo anche elimnarli nei sotto-oggetti
      Object.values(object)
      .filter((o) => typeof o === 'object')
      .filter(o => o)
      .map((o) => {
        o = this.recursivelyStripToxicFields(o, toxicFields);
      });
    }
    return object;
  }

  /**
   *
   * @param limit il limite pagine
   * @param skip offset
   * @param order criterio ordinamento
   * @param filter il filtro loopback
   */
  buildLoopbackJSON(limit: number, skip: number, order?: string, filter?: any): any {
    const JSON_FILTER = {limit, skip, fields: {xml: false}, where: {stato: true}};
    if (order) {
      Object.assign(JSON_FILTER, {order});
    }
    if (filter) {
      Object.assign(JSON_FILTER.where, {...filter});
    }
    return JSON_FILTER;
  }

  /**
   *
   * @param limit il limite pagine
   * @param skip offset
   * @param order criterio ordinamento
   * @param filter il filtro loopback
   */
  buildLoopbackJSONForCount(filter: any): any {
    const JSON_FILTER = {...filter, stato: true};

    return JSON_FILTER;
  }

}

