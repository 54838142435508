<ng-container *ngIf="LOADING">
  <if-page-load></if-page-load>
</ng-container>
<ng-container *ngIf="!creditsCount && !clientsCount && !LOADING">
  <app-if-intro
    [NO_CLIENTS]="clientsCount === 0"
    [NO_CREDITS]="creditsCount === 0"
  ></app-if-intro>
</ng-container>

<ng-container *ngIf="clientsCount === 0">
  <div class="main">
    <div class="flex-container">
      <div class="panel-wrapper">
        <mat-card>
          <mat-card-header>
            <div mat-card-avatar class="example-header-image"></div>
            <mat-card-title>
              Nessun Cliente Presente
            </mat-card-title>
            <mat-card-subtitle>
              Non abbiamo dati da mostrare
            </mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <p class="content">
              Assicurati di popolare sia
                clienti
              che
                crediti
            </p>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</ng-container>

<div
  *ngIf="creditsCount && clientsCount"
  class="main"
  style="display: flex; flex-direction: column; min-height: 100%"
>
  <div class="flex-container">
    <div class="panel-wrapper">
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title> Upload Fatture </mat-panel-title>
            <mat-panel-description>
              Carica con un click le tue fatture
            </mat-panel-description>
          </mat-expansion-panel-header>

          <app-if-xml-import
            [typeOfImport]="'importInvoices'"
          ></app-if-xml-import>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>

  <div *ngIf="creditsCount && clientsCount" class="flex-container">
    <div class="panel-wrapper">
      <mat-card>
        <mat-card-header>
          <h3>IN SCADENZA</h3>
          <div class="icon">
            <img src="assets/svg/warning.svg" alt="" />
          </div>
        </mat-card-header>
        <mat-card-content>
          <mat-list>
            <mat-list-item
              (click)="goTo(credito)"
              *ngFor="let credito of nearExpirationCredits"
            >
              {{ credito.UINome | truncate }}

              <div class="separated">
                <span class="date">
                  {{ credito.data_scadenza | shortDate }}
                </span>

                <span class="import">
                  {{ credito.importo | friendlyNumber }} &euro;
                </span>
              </div>
            </mat-list-item>
            <mat-list-item (click)="setActiveFilterAndGoToCredits('open')">
              Vedi tutti

              <mat-icon> chevron_right </mat-icon>
            </mat-list-item>
          </mat-list>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="panel-wrapper">
      <mat-card>
        <mat-card-header>
          <h3>PERIODO</h3>
          <div class="year-wrapper">
            <mat-form-field>
              <mat-select
                (selectionChange)="recalcChartByYear($event)"
                [(ngModel)]="selectedYear"
                placeholder="Anno Fiscale"
              >
                <mat-option *ngFor="let year of possibleYears" [value]="year">
                  {{ year }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </mat-card-header>
        <mat-card-content>
          <ng-container *ngIf="loadingChart">
            <div class="no-data">
              <div class="no-data-text">
                <mat-spinner [strokeWidth]="1"></mat-spinner>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="summarizedCredits.length > 0 && !loadingChart">
            <div class="isto-row" *ngFor="let credit of summarizedCredits">
              <div class="isto-title-wrapper">
                <div class="isto-title">
                  {{ LABELS[credit.categoria].label }}
                </div>
                <div class="isto-subtitle">
                  {{ credit.ammontare | friendlyNumber }}€
                </div>
              </div>
              <div class="isto-value">
                <div class="value-container">
                  <div
                    class="value"
                    (click)="setActiveFilterAndGoToCredits(credit.categoria)"
                    [ngClass]="{
                      verify: credit.categoria === 'verify',
                      open: credit.categoria === 'open',
                      paid: credit.categoria === 'paid',
                      unpaid: credit.categoria === 'unpaid'
                    }"
                    [ngStyle]="{
                      'background-color': LABELS[credit.categoria].color,
                      width: credit.width,
                      'max-width': credit.width
                    }"
                  ></div>
                </div>
              </div>
            </div>
          </ng-container>
        </mat-card-content>
      </mat-card>
      <!-- <div class="panel">
        <div class="titles-container">
          <div class="titles">
            <h3>PERIODO</h3>
            <h4></h4>
          </div>
          <div class="year-wrapper">
            <mat-form-field>
              <mat-select
              (selectionChange)="recalcChartByYear($event)"
              [(ngModel)]="selectedYear"
              placeholder="Anno Fiscale">
                <mat-option *ngFor="let year of possibleYears" [value]="year">
                  {{year}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="rows-container">
          <div class="isto-container">
            <ng-container *ngIf="summarizedCredits">
              <ng-container *ngIf="summarizedCredits.length === 0 && !loadingChart">
                <div class="no-data">
                  <div class="no-data-text">
                    Nessun dato disponibile per le date selezionate
                  </div>
                </div>

              </ng-container>


            </ng-container>
          </div>
        </div>
        <div class="selectors">
          <div class="filter-by">
            <mat-form-field>
              <mat-select
              (selectionChange)="updateBarChart($event)"
              [(ngModel)]="creditsService.dateFilter.campo"
              placeholder="Filtra per">
                <mat-option [value]="filters.nessun_filtro">
                  Nessun Filtro
                </mat-option>
                <mat-option [value]="filters.data_pagamento">
                  Data Pagamento
                </mat-option>
                <mat-option [value]="filters.data_emissione">
                  Data Emissione
                </mat-option>
                <mat-option [value]="filters.data_scadenza">
                  Data Scadenza
                </mat-option>

              </mat-select>
            </mat-form-field>
          </div>
          <div class="filter-by">
            <ng-container *ngIf="creditsService.dateFilter.campo !== filters.nessun_filtro">
              <div class="date">
                <mat-form-field>
                  <input
                  (click)="picker.open()"
                  (dateChange)="checkForNewStats(creditsService.dateFilter.campo, creditsService.dateFilter.data_da, creditsService.dateFilter.data_a)"
                  [(ngModel)]="creditsService.dateFilter.data_da"
                  matInput
                  [matDatepicker]="picker"
                  [max]="now"
                  placeholder="Dal...">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="date">
                <mat-form-field>
                  <input
                  [(ngModel)]="creditsService.dateFilter.data_a"
                  (click)="picker2.open()"
                  (dateChange)="checkForNewStats(creditsService.dateFilter.campo, creditsService.dateFilter.data_da, creditsService.dateFilter.data_a)"
                  [max]="now"
                  matInput
                  [matDatepicker]="picker2"
                  placeholder="Al...">
                  <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                  <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>
              </div>
            </ng-container>
          </div>
        </div>
      </div> -->
    </div>
  </div>

  <div *ngIf="creditsCount && clientsCount" class="flex-container">
    <div class="panel-wrapper">
      <mat-card>
        <mat-card-header>
          <h3>ANDAMENTO MENSILE</h3>
        </mat-card-header>
        <mat-card-content>
          <monthly-trend
            *ngIf="lowerChartData"
            [lowerChartData]="lowerChartData"
          ></monthly-trend>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="panel-wrapper">
      <mat-card>
        <mat-card-header>
          <h3>AGGREGATO MENSILE</h3>
        </mat-card-header>
        <mat-card-content>
          <aggregated-months
            *ngIf="dataToShow"
            [dataFromApi]="dataToShow"
          ></aggregated-months>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
