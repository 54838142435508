import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'beforeAhead'
})
export class BeforeAheadPipe implements PipeTransform {
  /**
   *
   * @param fromWhere la stringa può provenire dalla select o da un valore
   */
  transform(
    fromWhere: 'select' | 'value',
    currentCtrl: 'data_emissione' | 'data_scadenza' | 'data_insoluto',
    before?: boolean
  ): string {
    if (fromWhere === 'select') {
      let particle = 'Prima o dopo la data ';
      switch (currentCtrl) {
        case 'data_emissione':
          particle += 'di emissione?';
          break;
        case 'data_scadenza':
          particle += 'di scadenza?';
          break;

        case 'data_insoluto':
          particle += 'insoluto?';
          break;
      }
      return particle;
    } else {
      if (before) {
        switch (currentCtrl) {
          case 'data_emissione':
            return '\'emissione';
            break;
          case 'data_scadenza':
            return 'a scadenza';
            break;
          case 'data_insoluto':
            return '\'insoluto';
            break;
        }
      } else {
        switch (currentCtrl) {
          case 'data_emissione':
            return 'l\'emissione';
            break;
          case 'data_scadenza':
            return 'la scadenza';
            break;
          case 'data_insoluto':
            return 'l\'insoluto';
            break;
        }
      }
    }
  }

}
