
import {NgModule} from '@angular/core';
import { AppIfBuySmsComponent } from './app-if-buy-sms.component';
import { MaterialModule } from 'src/app/modules/material/material.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
@NgModule({
  declarations: [
    AppIfBuySmsComponent
  ],
  imports: [
    MaterialModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    AppIfBuySmsComponent
  ]
})
export class IFBuySMSOrEmailModule {}
