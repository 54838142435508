import { ClientsService } from '../services/clients.service';
import { IFProfile } from '../interfaces/if_profile';
import { Subscription } from 'rxjs';
import { OnInit, OnDestroy, Optional, Directive } from '@angular/core';
import { FlashService } from '../services/notifications.service';
import { DASHBOARD_KEYWORD, KEYWORDS_URL } from '../constants/keywords';
import { ModalManagerService } from '../services/modal-manager.service';

@Directive()
export class IFBaseClass implements OnInit, OnDestroy {
  loading = true;
  error = false;

  me: IFProfile;
  meSub: Subscription;
  saveSub: Subscription;

  public KEYWORDS = KEYWORDS_URL;

  constructor(
    @Optional() public cs: ClientsService,
    public fs: FlashService,
    @Optional() public mms: ModalManagerService
  ) {

  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    if (this.saveSub) {
      this.saveSub.unsubscribe();
    }
    if (this.meSub) {
      this.meSub.unsubscribe();
    }
  }

  /**
   * Questo metodo apre il modal nel main component.
   * @param {string} keyword
   */
  openModal(keyword: string): void {
    this.mms.setNewModel(keyword);
  }

  loadAndSetMe(): void {
    this.cs.loadAndSetMe();
  }


  saveProfile(me: IFProfile): void {
    this.saveSub = this.cs.saveWholeProfile(me, me.id)
    .subscribe((response: IFProfile) => {
      this.error = false;
      this.loading = false;
      this.fs.emitOKNotification('Profilo salvato');
    }, err => {
      this.fs.emitKONotification('Profilo non salvato');
    });
  }


}
