import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {
  transform(v: string): string {
    if (v.length > 25) {
      return v.split('').splice(0, 25).join('') + '...';
    } else {
      return v;
    }
  }
}
