import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MonthlyTrendComponent } from './monthly-trend.component';
import { ChartModule } from 'angular-highcharts';

@NgModule({
  declarations: [
    MonthlyTrendComponent
  ],
  imports: [
    CommonModule,
    ChartModule
  ],
  exports: [
    MonthlyTrendComponent
  ]
})
export class MonthlyTrendModule {}
