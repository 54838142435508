import { NgModule } from '@angular/core';
import { FriendlyContactPipe } from './friendly-contact.pipe';
import { FriendlyDatePipe } from './friendly-date.pipe';
import { FriendlyPaymentStatusPipe } from './friendly-payment-status.pipe';
import { ShortDatePipe } from './short-date.pipe';
import { StringFilterPipe } from './string-filter.pipe';
import { FriendlyNumberPipe } from './friendly-number.pipe';
import {RetrieveContactPipe} from './retrieve-contact.pipe';
import { SelectedFilterPipe } from './selected-filter.pipe';
import { PartialPaidPipe } from './partial-paid.pipe';
import { PartialUnpaidPipe } from './partial-unpaid.pipe';
@NgModule({
  imports: [
  ],
  declarations: [
    FriendlyContactPipe,
    FriendlyDatePipe,
    FriendlyPaymentStatusPipe,
    ShortDatePipe,
    StringFilterPipe,
    PartialPaidPipe,
    PartialUnpaidPipe,
    FriendlyNumberPipe,
    RetrieveContactPipe,
    SelectedFilterPipe,
  ],
  exports: [
    FriendlyContactPipe,
    FriendlyDatePipe,
    PartialPaidPipe,
    FriendlyPaymentStatusPipe,
    ShortDatePipe,
    PartialUnpaidPipe,
    StringFilterPipe,
    FriendlyNumberPipe,
    SelectedFilterPipe
  ]
})
export class IFPipesModule { }
