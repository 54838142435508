<section
  [ngClass]="{
    'with-feedback': showOther || showFeedback
  }"
  class="form"
  *ngIf="credit"
>
  <div class="paid-wrapper" *ngIf="correctlyPaid">
    <div class="circle-wrapper">
      <div class="circle">
        <div class="paid-container">
          <div class="paid">
            PAGATO
          </div>
        </div>
      </div>
    </div>
  </div>

  <div (click)="openOtherFeedback()" class="other-wrapper">
    Altro...
  </div>
  <div (click)="openFeedback()" class="feedback-wrapper">
    Hai già pagato?
  </div>

  <div class="order">
    <section class="company-logo">
      <img [src]="DOMAIN + credit.logo" alt="" />
    </section>

    <p>
      <ng-container *ngIf="pagaBoxContent?.parsedMessage">
        {{ pagaBoxContent.parsedMessage }}
      </ng-container>
      <ng-container *ngIf="!pagaBoxContent">
        {{credit.messaggio}}
      </ng-container>
    </p>
    <p>
      Scadenza:
      <strong>
        {{ credit?.data_scadenza | friendlyDate }}
      </strong>
    </p>
  </div>
  <div class="pay-form">
    <form id="payment-form" #stripeForm [formGroup]="paymentForm">
      <div class="card-wrapper">
        <div id="card" class="card"></div>
        <div id="card-errors"></div>
      </div>
      <div class="btn-wrapper">
        <ng-container *ngIf="loading">
          <mat-spinner
          [strokeWidth]="1"
          [mode]="'indeterminate'"
          [diameter]="30"></mat-spinner>
        </ng-container>
        <button
          type="submit"
          [disabled]="loading"
          class="if-btn btn-dark"
          *ngIf="!loading"
        >
            Paga ora
        </button>
      </div>
    </form>
  </div>
</section>

<div class="box-down-wrapper">
  <div *ngIf="showFeedback" class="feedback">
    <form [formGroup]="feedbackForm" class="text-wrapper">
      <p class="p">
        Ho già pagato
      </p>

      <mat-form-field>
        <input
          formControlName="when"
          (click)="picker.open()"
          matInput
          [matDatepicker]="picker"
          placeholder="In data..."
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker touchUi #picker></mat-datepicker>
      </mat-form-field>
      <mat-form-field>
        <mat-select
        #positiveSelect
        formControlName="how" placeholder="A mezzo...">
            <mat-option
            *ngFor="let feedback of positiveFeedbacks"
            [value]="feedback.value">
              {{feedback.label}}
            </mat-option>
        </mat-select>
      </mat-form-field>

      <button
        (click)="sendPositiveForm()"
        [disabled]="feedbackForm.invalid"
        class="if-btn btn-light"
      >
        Invia
      </button>
    </form>
  </div>

  <div *ngIf="showOther" class="other-feedback">
    <form [formGroup]="otherForm" class="text-wrapper">
      <p>Spiegaci la tua necessità</p>
      <mat-form-field>
        <mat-select
        #negativeSelect
        placeholder="Seleziona un'opzione" formControlName="why">
          <mat-option
          *ngFor="let feedback of negativeFeedbacks"
          [value]="feedback.value">
            {{feedback.label}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <br />
      <button
        (click)="sendNegativeForm()"
        [disabled]="otherForm.invalid"
        class="if-btn btn-light"
      >
        Invia
      </button>
    </form>
  </div>
</div>
