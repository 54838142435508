<div class="intro">
  <div class="welcome">
    <h3>Benvenuti su Incasso Facile</h3>
  </div>
  <div class="btns">
    <div class="title">
      All'inizio potresti...
    </div>
    <div class="btns-container">
      <!-- <div class="btn-wrapper" (click)="addClient()">
        <div class="circle incass" [ngClass]="{
          'success': !NO_CLIENTS
        }">
        <i class="ico-clientismart" *ngIf="NO_CLIENTS"></i>
        <i class="ico-oksmart" *ngIf="!NO_CLIENTS"></i>
        </div>
        <div>Aggiungere</div>
        <div>un nuovo cliente</div>
      </div> -->
      <mat-card class="btn-wrapper" (click)="addCredit()">
        <div class="circle scadu">
          <i class="ico-credito-pagatosmart"></i>
        </div>
        <div>Importare</div>
        <div>una nuova fattura, ed IncassoFacile creerà per te anagrafiche e crediti</div>
      </mat-card>
      <mat-card class="btn-wrapper" (click)="addClient()">
        <div class="circle">
          <i class="icoclienti"></i>
        </div>
        <div>Crea</div>
        <div>manualmente un'anagrafica cliente e poi associa i crediti</div>
      </mat-card>
    </div>
  </div>
</div>
