/**
 * @license
 * Copyright SMART SRL ®. Tutti i diritti riservati
 *
 * @author
 * Leonardo Maria Miliacca
 *
 * L'uso di questo codice è soggetto a copyright
 */
import { LoginComponent } from './modules/public/pages/login/login.component';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './modules/public/pages/page-not-found/page-not-found.component';
import { NgModule } from '@angular/core';
import { DashboardRoutingModule } from './modules/dashboard/dashboard-routing.module';

export const ROUTES: Routes = [
  { path: '', component: LoginComponent},
  {
    path: 'playground',
    loadChildren: () => import('./modules/playground/playground.module').then(m => m.PlaygroundModule)
  },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [
    DashboardRoutingModule,
    RouterModule.forRoot(
      ROUTES
    )
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {}
