import { Component, Inject, ViewEncapsulation } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

interface IFErrorDialogData {
  error: {
    error: {

      code: string; // "wrongSingleOrProcessingGroup"
      credito: boolean; // false
      err: number; // -1
      info: string; // "errore in decodifica xml; non ho ricevuto una fattura xml valida"
    }
  }
}

@Component({
  templateUrl: 'xml-error-dialog.component.html'
})
export class XMLErrorDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<XMLErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public error: IFErrorDialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

}
