import {IFIntroComponent} from './if-intro.component';
import {NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
@NgModule({
  declarations: [
    IFIntroComponent
  ],
  imports: [
    CommonModule,
    MatCardModule
  ],
  exports: [
    IFIntroComponent
  ]
})
export class IFIntroModule {}
