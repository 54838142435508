<main>
  <div class="ok" *ngIf="correctlyPaid">
    Grazie, il pagamento è andato a buon fine!
  </div>


  <div class="ko" *ngIf="payError">
    Errore tecnico del server,

    <a href="mailto:support@incassofacile.com" target="_blank">contatta l'assistenza</a>
  </div>

  <div class="error" *ngIf="error">

    <h2>Oooops! Errore {{error.status}}</h2>
    <p>Alcune monetine si sono incastrate negli ingranaggi dei nostri server...!</p>
    <h3>
      {{ERR_MSGS[error.status]}},

      <a href="mailto:support@incassofacile.com">contatta l'assistenza</a>
    </h3>
  </div>

  <app-paga-box
  *ngIf="notificationId && token"
  [token]="token"
  [notificationId]="notificationId"
  (payErrorOccurred)="payError = $event"
  (errorOccurred)="error = $event"
  (correctlyPaidEvent)="correctlyPaid = $event"></app-paga-box>


</main>
