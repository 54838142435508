import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PagamentoComponent } from './pagamento/pagamento.component';
import { HttpClientModule } from '@angular/common/http';
import { IFPipesModule } from 'src/app/pipes/if-pipes.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { PagaBoxComponent } from './paga-box/paga-box.component';
import { IFFileRowModule } from 'src/app/components/if-file-row/if-file-row.module';

@NgModule({
  declarations: [
    PagaBoxComponent,
    PagamentoComponent
  ],
  imports: [
    MatProgressSpinnerModule,
    IFFileRowModule,
    MatDatepickerModule,
    IFPipesModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    HttpClientModule,
    CommonModule,
    RouterModule.forChild([
      {
        path: 'paga-facile/:token/:notificaId',
        component: PagamentoComponent
      }
    ])
  ],
  exports: [
    PagaBoxComponent,
    RouterModule
  ]
})
export class PagaFacileModule {}
