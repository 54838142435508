/**
 * @license
 * Copyright SMART SRL ®. Tutti i diritti riservati
 *
 * @author
 * Leonardo Maria Miliacca
 *
 * L'uso di questo codice è soggetto a copyright
 */
import { ExpirationsComponent } from './pages/expirations/expirations.component';
import {
  CLIENTS_KEYWORD_URL,
  DASHBOARD_KEYWORD,
  CREDITS_KEYWORD_URL,
  EXPIRATIONS_KEYWORD_URL,
  NOTIFICATIONS_KEYWORD,
  SEARCH_KEYWORD_URL} from '../../constants/keywords';
import { ControlPanelComponent } from './pages/control-panel/control-panel.component';
import { NgModule } from '@angular/core';
import { MainComponent } from './pages/main/main.component';
import { DevComponent } from '../../components/dev/dev.component';
import { RouterModule } from '@angular/router';
import { AuthGuard } from '../../guards/auth.guard';
import { ContractsComponent } from './pages/contracts/contracts.component';

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot([{
      path: DASHBOARD_KEYWORD,
      component: MainComponent,
      canActivate: [AuthGuard],
      data: {
        state: 'main'
      },
      children: [
        {
          path: CLIENTS_KEYWORD_URL,
          loadChildren: () => import('../clients/clients.module').then(m => m.ClientsModule)
        },
        {
          path: 'consensi',
          loadChildren: '../privacy/privacy.module#PrivacyModule'
        },
        {
          path: '',
          component: ControlPanelComponent
        },
        {path: 'dev', component: DevComponent, data: {state: 'dev'}},
        {
          path: 'profilo',
          loadChildren: '../profile/profile.module#ProfileModule'
        },
        {
          path: 'contratti',
          loadChildren: () => import('./pages/contracts/contracts.module').then(m => m.ContractsModule)
        },
        {
          path: CREDITS_KEYWORD_URL,
          loadChildren: () => import('../credits/credits.module').then(m => m.IFCreditsModule)
        },
        {
          path: `${EXPIRATIONS_KEYWORD_URL}`,
          component: ExpirationsComponent
        },
        {
          path: `${NOTIFICATIONS_KEYWORD}`,
          loadChildren: () => import('./pages/notifications/notifications-settings.module').then(m => m.NotificationsSettingsModule)
        }, {
          path: 'marketplace',
          loadChildren: '../marketplace/marketplace.module#MarketplaceModule'
        }
      ]
    }])
  ],
  exports: [
    RouterModule
  ]
})
export class DashboardRoutingModule { }
