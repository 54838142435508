/**
 * @license
 * Copyright SMART SRL ®. Tutti i diritti riservati
 *
 * @author
 * Leonardo Maria Miliacca
 *
 * L'uso di questo codice è soggetto a copyright
 */
import { Pipe, PipeTransform } from '@angular/core';
import { Client } from '../interfaces/client';
import { ClientContact } from '../interfaces/client_contact';

@Pipe({
    name: 'stringFilter',
    pure: false
})
export class StringFilterPipe implements PipeTransform {
  // column è per ora 'cellulare', 'pec', 'email'
    transform(items: Client[], filter: string, tiponotificaId: string): any {
        if (!items || !filter || !tiponotificaId) {
            return items;
        }
        const firstPick: Client[] = items.filter((obj: Client) => {
          const contact: ClientContact = obj.contatti.find((cl: ClientContact) => {
            return cl.tiponotificaId === tiponotificaId;
          });
          if (contact) {
            return contact.valore.principale.match(new RegExp(filter, 'i'));
          } else {
            return false;
          }
        });

        const denominazionePick: Client[] = items.filter((obj: Client) => {
          return obj.UINome.match(new RegExp(filter, 'i'));
        });

        const merged: Client[] = firstPick.concat(denominazionePick);
        const flags = new Set();
        const toReturn: Client[] = merged.filter((cl: Client) => {
          if (flags.has(cl.id)) {
            return false;
          }
          flags.add(cl.id);
          return true;
        });
        return toReturn;


    }
}
